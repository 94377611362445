import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Button } from "@/components/ui/button";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { Grid2, Tooltip } from "@mui/material";
import { ITaskDetail } from "../models/Task";
import {
  IWorkFlowParameterWithValues,
  IWorkFlowWithParameters,
} from "@/features/workflows/Case/models/workflow";
import DynamicForm from "./DaynamicForm";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { HelpCircleIcon, MoveRight, TriangleAlert } from "lucide-react";
import { AlertDialogComponent } from "./AlertDialogComponent";
import { CloseMoveToNextDialog } from "./CloseMoveToNextDialog";

function validateRequiredParameters(
  workFlowParameter: IWorkFlowParameterWithValues[]
) {
  // Iterate through each workflow parameter
  for (const param of workFlowParameter) {
    if (
      param.isRequired &&
      (param.value === null || param.value === undefined)
    ) {
      return false;
    }
  }
  return true;
}

type Props = {
  taskDetail: ITaskDetail;
};

export default function WorkflowStepperAndBody({ taskDetail }: Props) {
  const [selectedWf, setSelectedWf] =
    React.useState<IWorkFlowWithParameters | null>(null);
  const allWorkFlows = React.useMemo(
    () => [...taskDetail.workFlow, ...taskDetail.nextSteps],
    [taskDetail.workFlow, taskDetail.nextSteps]
  );
  React.useEffect(() => {
    setSelectedWf(
      allWorkFlows.find((wf) => wf.id === taskDetail.currentWorkFlow.id) ??
        allWorkFlows[0]
    );
  }, [allWorkFlows, taskDetail.currentWorkFlow.id]);

  const { session } = useAuth();
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const handleStep = (wf: IWorkFlowWithParameters) => () => {
    setSelectedWf(wf);
  };

  const [openMoveToNextStepDialog, setOpenMoveToNextStepDialog] =
    React.useState<boolean>(false);
  const canEdit = () => {
    if (!selectedWf) return false;
    const isClient = session?.userInfo.userType === UserType.Client;
    const statuses = ["Finished", "Cancelled", "Suspended"];
    const isUserPermittedToEdit = selectedWf.isCurrentUserEditPermitted;

    if (selectedWf.isEditByPermission && !isUserPermittedToEdit) {
      return false;
    }
    if (statuses.includes(taskDetail.task.taskStatus)) {
      return false;
    }
    if (isClient) {
      return false;
    }
    if (selectedWf.id === taskDetail.currentWorkFlow.id) {
      return true;
    }

    return selectedWf.canEditAtAnyStage;
  };

  return (
    <Box sx={{ width: "100%" }} p={3} bgcolor={"#fff"}>
      <Grid2 container spacing={1}>
        <Grid2 size={2}>
          <Stepper
            nonLinear
            activeStep={allWorkFlows.findIndex(
              (wf) => wf.id === selectedWf?.id
            )}
            orientation="vertical"
          >
            {allWorkFlows
              .slice()
              .sort((a, b) => a.workFlowStep - b.workFlowStep)
              .map((wf) => (
                <Step
                  key={wf.id}
                  completed={wf.isCompleted}
                  active={selectedWf?.id === wf.id}
                  sx={{
                    bgcolor: selectedWf?.id === wf.id ? "#f2f2f2" : undefined,
                    borderRadius: "5px",
                    mb: 2,
                  }}
                >
                  <StepButton color="inherit" onClick={handleStep(wf)}>
                    {wf.workFlowName}
                  </StepButton>
                </Step>
              ))}
          </Stepper>
        </Grid2>
        <Grid2 size={10}>
          {selectedWf && (
            <>
              <Box>
                <Typography>{selectedWf.workFlowName}</Typography>
                <DynamicForm
                  key={selectedWf.id}
                  taskId={taskDetail.task.id}
                  workFlowId={selectedWf.id}
                  parameters={selectedWf.workFlowParameter}
                  currentWorkFlow={taskDetail.currentWorkFlow}
                  taskStatus={taskDetail.task.taskStatus}
                  workflow={selectedWf}
                />

                {canEdit() && (
                  <>
                    <div className="flex mt-2 gap-2 justify-between">
                      <Button
                        size="sm"
                        variant="defaultPrimary"
                        className="flex gap-2"
                        onClick={() => setOpenAlertDialog(true)}
                      >
                        <TriangleAlert size={18} />
                        Add Alert
                      </Button>
                      <div className="flex gap-2">
                        <div className="flex justify-center items-center">
                          <Tooltip title="To proceed to the next stage or to close, all mandatory (*) fields must be filled.">
                            <HelpCircleIcon />
                          </Tooltip>
                        </div>

                        <Button
                          size={"sm"}
                          onClick={() => setOpenMoveToNextStepDialog(true)}
                          disabled={
                            !validateRequiredParameters(
                              selectedWf.workFlowParameter
                            )
                          }
                          className={
                            taskDetail.task.taskStatus === "BackToRevision"
                              ? "hidden"
                              : ""
                          }
                        >
                          {taskDetail.nextSteps.length === 0 ? (
                            "Close"
                          ) : (
                            <>
                              Move To Next
                              <MoveRight size={16} className="ml-2" />
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                    <CloseMoveToNextDialog
                      openDialog={openMoveToNextStepDialog}
                      onClose={() => setOpenMoveToNextStepDialog(false)}
                      taskId={taskDetail.task.id}
                      title={
                        taskDetail.nextSteps.length === 0
                          ? "Close Workflow"
                          : "Move to Next Step"
                      }
                    />
                    <AlertDialogComponent
                      taskId={taskDetail.task.id}
                      workflowId={selectedWf.id}
                      openAlertDialog={openAlertDialog}
                      setOpenAlertDialog={() => {
                        setOpenAlertDialog(false);
                      }}
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
}
