import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";

import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { useGetAlertTypeQuery } from "@/features/alert/api/alert-api";
import { MenuItem, Select } from "@mui/material";
import { useAddAlertFortTaskMutation } from "@/features/task/api/task-command";
import { AlertFrom } from "@/features/task/models/Task";
import DateInput from "@/features/task/components/form-components/DateInput";

type Props = {
  openAlertDialog: boolean;
  setOpenAlertDialog: (value: boolean) => void;
  taskId: string;
  workflowId: string;
};

export const AddGenericAlertDialog = ({
  openAlertDialog,
  setOpenAlertDialog,
  taskId,
  workflowId,
}: Props) => {
  const [addAlert, { isSuccess, isLoading }] = useAddAlertFortTaskMutation();
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [daysBeforeAlert, setDaysBeforeAlert] = useState<number | null>(null);
  const [message, setMessage] = useState<string>("");
  const [selectedAlertType, setSelectAlertType] = useState<string | undefined>(
    undefined
  );

  const { data: alertTypes } = useGetAlertTypeQuery();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (dueDate && daysBeforeAlert !== null && message) {
      await addAlert({
        data: {
          taskId,
          alertFrom: AlertFrom.GenericTask,
          workFlowId: workflowId,
          daysBeforeAlert,
          message,
          dueDate: dueDate.toDate(),
          alertTypeId: selectedAlertType,
        },
      });
      setOpenAlertDialog(false);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setOpenAlertDialog(false);
    }
  }, [isSuccess, setOpenAlertDialog]);
  return (
    <DefaultDialog
      title={"Add Alert"}
      open={openAlertDialog}
      onClose={() => setOpenAlertDialog(false)}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <Label htmlFor="alert_type">Type</Label>
        <Select
          value={selectedAlertType}
          onChange={(e) => setSelectAlertType(e.target.value)}
          required
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {alertTypes?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.alertName}
            </MenuItem>
          ))}
        </Select>

        <DateInput
          label="Due Date"
          onChange={(value) => setDueDate(value)}
          value={dueDate}
          disablePast
        />
        <Label htmlFor="due_date">Days Before Alert</Label>
        <Input
          type="number"
          placeholder="days before alert"
          value={daysBeforeAlert ?? ""}
          onChange={(e) => setDaysBeforeAlert(Number(e.target.value))}
        />

        <Label htmlFor="message">Your message</Label>
        <Textarea
          placeholder="Type your message here."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="flex gap-2 justify-end mt-2">
          <CustomButton
            variant="danger"
            size="sm"
            onClick={() => setOpenAlertDialog(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="primary"
            size="sm"
            type="submit"
            isLoading={isLoading}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </DefaultDialog>
  );
};
