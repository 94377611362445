import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosRequestConfig } from "axios";
import { getCurrentSession } from "../auth";

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = {
      baseUrl: import.meta.env.VITE_API_BASE_URL,
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      uploadProgress?: AxiosRequestConfig["onUploadProgress"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        headers: {
          // "Accept-Language": localStorage.getItem("local"), // Add the Accept-Language header here.
          ...(getCurrentSession().accessToken && {
            Authorization: `Bearer ${getCurrentSession().accessToken}`,
          }),
          // "Content-Type": "application/json", // explicitly set content type
        },
        url: baseUrl + url,
        method,
        data,
        params: { ...params },
      });
      return { data: result.data };
    } catch (axiosError) {
      return {
        error: axiosError,
      };
    }
  };
