import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Input } from "@/components/ui/input";

interface FormInputProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label: string;
  type?: string;
  placeholder?: string;
  rules?: RegisterOptions<T>;
  valueAsNumber?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const FormInput = <T extends FieldValues>({
  name,
  control,
  label,
  type = "text",
  placeholder,
  rules,
  valueAsNumber = false,
  disabled = false,
  required = false,
}: FormInputProps<T>) => {
  return (
    <div className="mt-4">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            if (valueAsNumber) {
              // Parse the input value to a number or set to undefined if empty
              const parsedValue =
                inputValue === "" ? undefined : Number(inputValue);
              field.onChange(parsedValue);
            } else {
              field.onChange(inputValue);
            }
          };
          return (
            <>
              <Input
                {...field}
                id={name}
                type={type}
                placeholder={placeholder}
                className="mt-1"
                onChange={handleChange}
                disabled={disabled}
                required={required}
              />
              {error && (
                <p className="text-red-500 text-sm mt-1">{error.message}</p>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default FormInput;
