import { DefaultDialog } from '@/components/shared/DefaultDailog/DefaultDialog';
import React, { SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { NumericFormat } from 'react-number-format';
import { Input } from '@/components/ui/input';
import { Select, SelectItem, SelectTrigger, SelectContent, SelectValue } from '@/components/ui/select';
import { Form, FormField, FormControl, FormLabel, FormItem, FormMessage } from '@/components/ui/form'; // Shadcn Form components
import CustomButton from '@/components/shared/Button/CustomButton';
import { useUpdateTaskPayementMutation } from '../api/task-query';
import { Notify } from '@/components/shared/Notification/notify';
import FormFileInput from '@/components/form/FormFileInput';
import { ITaskPayment } from '../models/Task';

// Define Zod schema for form validation
const paymentSchema = z.object({
  amount: z.number().positive("Payment amount must be a positive number"),
  paymentDue: z.number().positive("Payment due must be a positive number").optional(),
  paymentMode: z.string().nonempty("Payment mode is required"),
  description: z.string().nonempty("Description is required"),
  file: z
    .array(z.instanceof(File))
    .optional()
    .refine((files) => !files || files.every((file) => file.size > 0), {
      message: "All files must be valid and have a non-zero size",
    }),
});

type PaymentFormValues = z.infer<typeof paymentSchema>;

interface PayTaskPaymentProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  payment: ITaskPayment;
}

const PayTaskPayment = ({ openForm, setOpenForm, payment }: PayTaskPaymentProps) => {
  const [UpdateTaskPayment, { isLoading, isSuccess }] = useUpdateTaskPayementMutation();
  const form = useForm<PaymentFormValues>({
    resolver: zodResolver(paymentSchema), // Use Zod schema for validation
    defaultValues: {
      amount: 0,
      paymentDue: payment.isFixedAmount ? undefined : payment.paymentDue || 0,
      paymentMode: '',
      description: '',
    },
  });

  useEffect(() => {
    // Reset the form when the `payment` prop changes
    form.reset({
      paymentDue: payment.isFixedAmount ? undefined : payment.paymentDue || 0,
    });
  }, [payment, form]);

  const onSubmit = async (data: PaymentFormValues) => {
    const formData = new FormData();
    formData.append("id", payment.id);
    formData.append("paymentMode", data.paymentMode);
    formData.append("paidAmount", data.amount.toString()); // Convert number to string for FormData
    if (data.paymentDue !== undefined) {
      formData.append("paymentDue", data.paymentDue.toString()); // Convert number to string for FormData
    }
    if (data.description) formData.append("description", data.description);
    data.file?.forEach((file: string | Blob) => {
      formData.append(`file[]`, file);
    });

    await UpdateTaskPayment({ url: payment.id, data: formData });
  };
  

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      setOpenForm(false);
      Notify("success", "Task Payment paid successfully!");
    }
  }, [isSuccess]);

  return (
    <DefaultDialog
      title="Pay Payment"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            {/* Amount Field */}
            <FormField
              name="amount"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Amount</FormLabel>
                  <FormControl>
                    <NumericFormat
                      placeholder="Amount"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={(values) => field.onChange(Number(values.value))}
                      value={field.value}
                      customInput={Input}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Conditionally render Payment Due Field */}
            {!payment.isFixedAmount && (
              <FormField
                name="paymentDue"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Payment Due</FormLabel>
                    <FormControl>
                      <NumericFormat
                        placeholder="Payment Due"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        onValueChange={(values) => field.onChange(Number(values.value))}
                        value={field.value}
                        customInput={Input}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {/* Payment Mode Field using Shadcn Select */}
            <FormField
              name="paymentMode"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Mode</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder="Payment Mode" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        <SelectItem value="Cash">Cash</SelectItem>
                        <SelectItem value="Cheque">Cheque</SelectItem>
                        <SelectItem value="BankDeposit">Bank Deposit</SelectItem>
                        <SelectItem value="CPO">CPO</SelectItem>
                        <SelectItem value="Credit">Credit</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Description Field */}
            <FormField
              name="description"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Payment Description"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormFileInput
              control={form.control}
              name="file"
              label="Upload Attachments"
              multiple
              accept="*"
            />

            {/* Submit Button */}
            <CustomButton disabled={!form.formState.isValid || isLoading} isLoading={isLoading} type="submit" className="w-full">
              Submit Payment
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default PayTaskPayment;
