import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import React, { useEffect } from "react";
import { useMoveToNextWorkflowStepMutation } from "../api/task-command";
import { Textarea } from "@/components/ui/textarea";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useSalesMoveToNextStepMutation } from "@/features/sales/api/pipe-line-wf-command";

type Props = {
  openDialog: boolean;
  onClose: () => void;
  taskId: string;
  title: string;
  isSalesWf?: boolean;
};

export const CloseMoveToNextDialog = ({
  openDialog,
  onClose,
  taskId,
  title = "Move to Next Step",
  isSalesWf,
}: Props) => {
  const [
    moveToNextWorkflowStep,
    {
      isSuccess: isMovingToNextWorkflowSuccess,
      isLoading: isMovingToNextLoading,
    },
  ] = useMoveToNextWorkflowStepMutation();
  const [
    salesMoveToNextStep,
    {
      isSuccess: isMovingSalesToNextWorkflowSuccess,
      isLoading: isMovingSalesToNextLoading,
    },
  ] = useSalesMoveToNextStepMutation();

  const [movetoNextStepNote, setMovetoNextStepNote] =
    React.useState<string>("");

  const handleMoveToNextStep = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSalesWf) {
      await salesMoveToNextStep({
        data: {
          taskId: taskId,
          note: movetoNextStepNote,
        },
      });
      return;
    }
    await moveToNextWorkflowStep({
      data: {
        taskId: taskId,
        note: movetoNextStepNote,
      },
    });
  };
  useEffect(() => {
    if (isMovingToNextWorkflowSuccess || isMovingSalesToNextWorkflowSuccess) {
      onClose();
    }
  }, [
    isMovingToNextWorkflowSuccess,
    onClose,
    isMovingSalesToNextWorkflowSuccess,
  ]);

  return (
    <DefaultDialog title={title} open={openDialog} onClose={onClose}>
      <form onSubmit={handleMoveToNextStep}>
        <div className="mt-3">
          <span className="m-1">Note</span>
          <Textarea
            placeholder="Add Note"
            className="mt-2"
            value={movetoNextStepNote}
            onChange={(e) => setMovetoNextStepNote(e.target.value)}
          />
          <div className="flex justify-center mt-3">
            <CustomButton
              className="w-50 items-center"
              isLoading={isMovingToNextLoading || isMovingSalesToNextLoading}
            >
              Submit
            </CustomButton>
          </div>
        </div>
      </form>
    </DefaultDialog>
  );
};
