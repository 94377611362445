import { NavLink, useNavigate } from "react-router-dom";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import DashboarCard from "../components/DashboarCard";
import {
  ArrowRight,
  CalendarDays,
  CheckCheck,
  CircleDotDashed,
  StopCircle,
} from "lucide-react";
import { IClientTask } from "@/features/task/models/Task";
import {
  useGetTaskCountQuery,
  useGetTaskListQuery,
} from "../api/dashboard-api";
import { useAuth } from "@/lib/auth";

const taskListColumns: MRT_ColumnDef<IClientTask>[] = [
  {
    accessorKey: "taskReference",
    header: "Ref No.",
  },
  {
    accessorKey: "clientName",
    header: "Customer",
    // Cell: ({ row }) => {
    //   return row.original.client.clientName;
    // },
  },
  {
    accessorKey: "caseName",
    header: "Case",
    // Cell: ({ row }) => {
    //   return row.original.caseType.caseDescription;
    // },
  },
  {
    accessorKey: "taskStatus",
    header: "Status",
    // Cell: ({ row }) => {
    //   return row.original.caseType.caseDescription;
    // },
  },
  {
    accessorKey: "startDate",
    header: "StartDate",
    Cell: ({ row }) => {
      return new Date(row.original.startDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
  },
  // {
  //   id: "actions",
  //   header: "Action",
  //   enableHiding: false,
  //   Cell: ({row}) => {
  //     return (
  //       <>
  //         <NavLink
  //           to={`/task/task-list/${row.original.id}`}
  //           className={"underline text-blue-700"}
  //         >
  //           Detail
  //         </NavLink>
  //       </>
  //     );
  //   },
  // },
];

const ClientDashboard = () => {
  const {session} = useAuth()
  const navigate = useNavigate()
  const { data: taskList } = useGetTaskListQuery({
    data: {
      organizationId: session?.userInfo.organizationId,
    }
  });


  const { data: taskCount } = useGetTaskCountQuery({
    data: {
      organizationId: session?.userInfo.organizationId,
    }
  });

  return (
    <div className="py-4 px-2 md:px-6 2xl:px-11 flex flex-col gap-4">
      {/* Responsive grid for dashboard cards */}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <DashboarCard
          title="Today New Task"
          count={taskCount?.newTasks ?? 0}
          icon={<CalendarDays />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/operational-task"}
        />
        <DashboarCard
          title="Ongoing Task"
          count={taskCount?.ongoingTasks ?? 0}
          icon={<CircleDotDashed />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/operational-task"}
        />
        <DashboarCard
          title="Completed Task"
          count={taskCount?.completedTasks ?? 0}
          icon={<CheckCheck />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/operational-task"}
        />
        <DashboarCard
          title="Suspended Task"
          count={taskCount?.suspendedTasks ?? 0}
          icon={<StopCircle />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/operational-task"}
        />
      </div>

      {/* Recent tasks table */}
      <div className="flex flex-col bg-white rounded-lg">
        <div className="flex justify-between items-center p-4">
          <p className="text-lg font-medium">Recent Task</p>
          <NavLink
            to={"/task/operational-task"}
            className="text-md flex justify-center items-center gap-2 text-blue-600 hover:text-blue-800 text-base font-medium"
          >
            <p className="font-normal">View all</p>
            <ArrowRight className="w-5 h-5" />
          </NavLink>
        </div>
        {/* Responsive table container */}
        <div className="overflow-x-auto">
          <MaterialReactTable
            columns={taskListColumns}
            data={taskList ?? []}
            enablePagination
            muiTableBodyRowProps={(row) => ({
              onClick: () => {
                navigate(`/task/operational-task/${row.row.original.id}`)
              },
              sx: {
                cursor: 'pointer',
              }
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;