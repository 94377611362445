import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PageTitle from "@/components/ui/PageTitle";
import { DefaultLayout } from "@/components/ui/layout/DefaultLayout";
import { Case } from "@/features/workflows/Case/Case";
import { Customer } from "@/features/customers/pages/Customer";
import CustomerDetail from "@/features/customers/pages/CustomerDetail";
import Workflow from "@/features/workflows/workflow/Workflow";
import { Role } from "@/features/users/pages/Role";
import { User } from "@/features/users/pages/User";
import { useAuth } from "@/lib/auth";
import { useAppDispatch } from "@/store/app-store-hooks";
import { useEffect } from "react";
import { logOut } from "@/lib/auth/store/auth.slice";
import { LoginPage } from "@/lib/auth/pages/LoginPage";
import UserDetails from "@/features/users/pages/UserDetail";
import GlobalDashboard from "@/features/dashboard/GlobalDashboard";
import taskRouting from "@/features/task/task-routing";
import { NotFoundPage } from "@/pages/not-found-404-page";
import { Report } from "@/features/report/pages/Report";
import CompanyProfilePage from "@/features/company/pages/Company";
import SMSEmailPage from "@/features/SMS&Email/pages/SMSEmailPage";
import SignUpPage from "@/lib/auth/pages/SignUpPage";
import ConfirmationPage from "@/lib/auth/pages/ConfirmationPage";
import AlertsPage from "@/features/alert/pages/Alert";
import { AlertTypePage } from "@/features/alert/pages/AlertType";
import financeRouting from "@/features/finance/financeRouting";
import salesRouting from "@/features/sales/salesRouting";
import SalesWorkflow from "@/features/workflows/salesWorkflow/SalesWorkflow";
import { Source } from "@/features/sales/source/page/Source";
import { PaymentTypePage } from "@/features/payment-types/pages/PaymentTypePage";

export const Routing = () => {
  const { session } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const localSession = localStorage.getItem("session");

  useEffect(() => {
    if (location.pathname === "/signup") {
      navigate("/signup");
    } else if (location.pathname === "/confirmation") {
      navigate("/confirmation");
    } else if (!session || !localSession) {
      dispatch(logOut());
      navigate("/login");
    } else if (session && location.pathname === "/login") {
      navigate("/");
    }
  }, [session, location.pathname, navigate, dispatch, localSession]);

  return (
    <>
      {/* <ScrollRestoration /> */}
      <Routes>
        {session === null && <Route path="/signup" element={<SignUpPage />} />}
        {session === null && (
          <Route path="/confirmation" element={<ConfirmationPage />} />
        )}
        {session === null && <Route path="/login" element={<LoginPage />} />}

        {session !== null && (
          <Route path="/" element={<DefaultLayout />}>
            <Route
              index
              path="/"
              element={
                <>
                  <PageTitle title="Home" />
                  <GlobalDashboard />
                </>
              }
            />
            <Route path="settings">
              <Route
                index
                path="case"
                element={
                  <>
                    <PageTitle title="Case" />
                    <Case />
                  </>
                }
              />
              <Route
                index
                path="alert-type"
                element={
                  <>
                    <PageTitle title="Alert Type" />
                    <AlertTypePage />
                  </>
                }
              />
              <Route
                index
                path="source"
                element={
                  <>
                    <PageTitle title="Source" />
                    <Source />
                  </>
                }
              />

              <Route
                index
                path="workflow"
                element={
                  <>
                    <PageTitle title="Workflow" />
                    <Workflow />
                  </>
                }
              />
              <Route
                index
                path="sales-workflow"
                element={
                  <>
                    <PageTitle title="Sales Workflow" />
                    <SalesWorkflow />
                  </>
                }
              />
              <Route path="users">
                <Route
                  path="list"
                  element={
                    <>
                      <PageTitle title="User List" />
                      <User />
                    </>
                  }
                />

                <Route
                  path=":id"
                  element={
                    <>
                      <PageTitle title="User Detail" />
                      <UserDetails />
                    </>
                  }
                />
              </Route>
              <Route
                path="roles"
                element={
                  <>
                    <PageTitle title="Roles List" />
                    <Role />
                  </>
                }
              />
              <Route
                path="company-profile"
                element={
                  <>
                    <PageTitle title="Company Profile" />
                    <CompanyProfilePage />
                  </>
                }
              />
              {/* <Route
                index
                path="organizationss"
                element={
                  <>
                    <PageTitle title="Organizations" />
                    <Organization />
                  </>
                }
              /> */}
              <Route
                path="payment-types"
                element={
                  <>
                    <PageTitle title="Payment Type" />
                    <PaymentTypePage />
                  </>
                }
              />
            </Route>

            <Route index path="profile" element={<>Profile Page</>} />

            <Route
              path="reports"
              element={
                <>
                  <PageTitle title="Report & Analytics" />
                  <Report />
                </>
              }
            />

            <Route
              path="sms-email"
              element={
                <>
                  <PageTitle title="SMS & Email" />
                  <SMSEmailPage />
                </>
              }
            />

            <Route path="customer">
              <Route
                path="list"
                element={
                  <>
                    <PageTitle title="Customers" />
                    <Customer />
                  </>
                }
              />
              <Route
                path=":id"
                element={
                  <>
                    <PageTitle title="Customer Detail" />
                    <CustomerDetail />
                  </>
                }
              />
            </Route>

            <Route
              path="alert/alert-list"
              element={
                <>
                  <PageTitle title="Alert" />
                  <AlertsPage />
                </>
              }
            />
            {taskRouting}
            {/* {genericTaskRouting} */}
            {financeRouting}
            {salesRouting}
          </Route>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
