import {
  AlertCircle,
  AlertOctagon,
  BarChart,
  // ChartBar,
  Compass,
  DollarSign,
  DollarSignIcon,
  Euro,
  Grid2X2,
  Info,
  LayoutDashboard,
  ListTodo,
  Origami,
  PieChart,
  Send,
  Settings,
  UserCheck,
  Users,
  Users2,
  Workflow,
} from "lucide-react";
import { Menu, UserType } from "@/model/SideBarMenu";
export const menus: Menu[] = [
  {
    path: "/",
    name: "Dashboard",
    icon: LayoutDashboard,
    allowedUserType: [UserType.Client, UserType.Staff],
    permissions: [],
  },
  {
    path: "/alert/alert-list",
    name: "Alert",
    icon: AlertCircle,
    allowedUserType: [UserType.Staff],
    permissions: [],
  },
  {
    path: "/task",
    name: "Task",
    icon: ListTodo,
    allowedUserType: [UserType.Client, UserType.Staff],
    permissions: [],
    parentLinked: true,
    child: [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   icon: ChartBar,
      //   allowedUserType: [UserType.Staff],
      //   permissions: [],
      // },
      {
        path: "/operational-task",
        name: "Operational Task",
        icon: ListTodo,
        allowedUserType: [UserType.Client, UserType.Staff],
        permissions: [],
      },
      {
        path: "/generic-task",
        name: "Generic Task",
        icon: ListTodo,
        allowedUserType: [UserType.Client, UserType.Staff],
        permissions: [],
      },
    ],
  },
  // {
  //   path: "/generic/task-list",
  //   name: "Generic Task",
  //   icon: ListTodo,
  //   allowedUserType: [UserType.Client, UserType.Staff],
  //   permissions: [],
  // },
  {
    path: "/customer/list",
    name: "Customer",
    icon: Users2,
    allowedUserType: [UserType.Staff],
    permissions: [],
  },
  {
    path: "/sales",
    name: "Sales management",
    icon: BarChart,
    allowedUserType: [UserType.Staff],
    permissions: [],
    parentLinked: true,
    child: [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   icon: ChartBar,
      //   allowedUserType: [UserType.Staff],
      //   permissions: [],
      // },
      {
        path: "/leads",
        name: "Leads",
        icon: Compass,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "/pipeline-workflow",
        name: "Pipeline",
        icon: Workflow,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "/tender-workflow",
        name: "Tender Workflow",
        icon: DollarSignIcon,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
    ],
  },
  {
    path: "/finance",
    name: "Finance",
    icon: DollarSign,
    allowedUserType: [UserType.Staff],
    permissions: [],
    child: [
      {
        path: "/payment-collection",
        name: "Payment Collection",
        icon: Euro,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "/expense",
        name: "Expense",
        icon: Euro,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
    ],
  },
  {
    path: "/reports",
    name: "Report & Analytics",
    icon: PieChart,
    allowedUserType: [UserType.Staff],
    permissions: [],
  },
  {
    path: "/sms-email",
    name: "SMS & Email",
    icon: Send,
    allowedUserType: [UserType.Staff],
    permissions: [],
  },
  {
    name: "Settings",
    path: "settings",
    icon: Settings,
    child: [
      {
        name: "Case-Sector",
        path: "case",
        icon: Grid2X2,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        name: "Alert Type",
        path: "alert-type",
        icon: AlertOctagon,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        name: "Source",
        path: "source",
        icon: Origami,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        name: "Operational Workflow",
        path: "workflow",
        icon: Workflow,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        name: "Sales Workflow",
        path: "sales-workflow",
        icon: Workflow,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "users/list",
        name: "Users",
        icon: Users,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "roles",
        name: "Roles",
        icon: UserCheck,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "payment-types",
        name: "Payment Types",
        icon: DollarSign,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
      {
        path: "company-profile",
        name: "Company Profile",
        icon: Info,
        allowedUserType: [UserType.Staff],
        permissions: [],
      },
    ],
    allowedUserType: [UserType.Staff],
    permissions: [],
  },
];
