import { Button } from "@/components/ui/button";
import { CalendarIcon, Check, ChevronDown, Plus } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useLazyGetCustomersQuery } from "@/features/customers/api/customer-api";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import AddCustomer from "@/features/customers/pages/AddCustomer";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import {
  useAddGenericTaskMutation,
  useGetUsersQuery,
} from "../api/generic-task-api";
import { IUser } from "@/features/users/models/User";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";

type AddGenericTaskDialogProps = {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<SetStateAction<boolean>>;
};

const customerTypes = [
  {
    label: "Regular",
    value: "Regular",
  },
  {
    label: "Urgent",
    value: "Urgent",
  },
];

export const AddGenericTaskDialog = ({
  openDialog,
  setOpenDialog,
}: AddGenericTaskDialogProps) => {
  const { session } = useAuth();

  const genericTaskFormSchema = z
    .object({
      taskType: z.enum(["Regular", "Urgent"]),
      clientId: z.string().optional(),
      startDate: z.date({
        required_error: "A Start Date is required.",
      }),
      endDate: z
        .date({
          required_error: "A End Date is required.",
        })
        .optional(),
      description: z.string(),
      users: z
        .array(
          z.object({
            id: z.string(),
            fullName: z.string(),
            username: z.string(),
            email: z.string(),
            isActive: z.boolean(),
            passwordHash: z.string(),
            phoneNumber: z.string(),
          })
        )
        .nonempty("At least one user is required"),
        taskName: z.string().nonempty("Task Name Required!"),
        taskDescription: z.string().optional()
    })
    .refine(
      (data) => {
        // If endDate is provided, ensure startDate is before endDate
        if (data.endDate) {
          return data.startDate < data.endDate;
        }
        // If endDate is not provided, no need to validate the date relationship
        return true;
      },
      {
        message: "End Date must be after Start Date.",
        path: ["endDate"], // Assign the error to the endDate field
      }
    );

  type GenericTaskFormValues = z.infer<typeof genericTaskFormSchema>;

  const form = useForm<GenericTaskFormValues>({
    resolver: zodResolver(genericTaskFormSchema),
    mode: "onChange",
  });

  const [openSector, setOpenSector] = useState<boolean>(false);
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);

  const [
    AddGenericTask,
    { isLoading: isGenericTaskAddLoading, isSuccess: isGenericTaskAddSuccess },
  ] = useAddGenericTaskMutation();

  const [getCustomers, { data: customerList }] = useLazyGetCustomersQuery();
  const { data: usersList, isLoading: isUserFetchingLoading } =
    useGetUsersQuery({
      data: {
        organizationId: session?.userInfo.organizationId,
        userType: UserType.Staff,
      },
    });

  const onSubmit = async (data: GenericTaskFormValues) => {
    await AddGenericTask({
      data: {
        assignedUsersId: data.users.map((user) => user.id),
        clientId: data.clientId,
        description: data.description,
        startDate: data.startDate,
        endDate: data.endDate,
        alertMessage: data.description,
        taskType: data.taskType,
        taskName: data.taskName,
        // taskDescription: data.taskDescription
      },
    });
  };

  useEffect(() => {
    getCustomers({
      url: "/clients/SearchClients",
      method: "GET",
      data: {
        organizationId: session?.userInfo.organizationId,
        pageNumber: 1,
        pageSize: 100000,
        clientName: "",
      },
    });
  }, [getCustomers, session?.userInfo.organizationId]);

  useEffect(() => {
    if (isGenericTaskAddSuccess) {
      form.reset();
      setOpenDialog(false);
      Notify("success", "Generic Task Added Successfully!");
    }
  });

  const clientIdRef = useRef(null);
  const caseTypeIdRef = useRef<HTMLButtonElement>(null);
  const startDateRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <DefaultDialog
        open={openDialog}
        title={"Add Generic Task"}
        onClose={() => setOpenDialog(false)}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-4"
          >
            <FormField
              control={form.control}
              name="taskType"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Task Type</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Task type" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {customerTypes.map((type, index) => (
                          <SelectItem key={index} value={type.value}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid gap-2 grid-cols-3">
              <div className="col-span-2">
                <Controller
                  name="clientId"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <Popover open={openSector} onOpenChange={setOpenSector}>
                        <PopoverTrigger asChild className="w-full">
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-muted-foreground"
                            )}
                            ref={clientIdRef}
                          >
                            {field.value
                              ? customerList?.list?.find(
                                  (customer) => customer.id === field.value
                                )?.clientName
                              : "Select Customer"}
                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0 z-9999">
                          <Command>
                            <CommandInput
                              placeholder="Search Customer"
                              onInput={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const query = event.target.value;
                                getCustomers({
                                  url: "/clients/SearchClients",
                                  method: "GET",
                                  data: {
                                    organizationId:
                                      session?.userInfo.organizationId,
                                    pageNumber: 1,
                                    pageSize: 100000,
                                    clientName: query,
                                  },
                                });
                              }}
                            />
                            <CommandList>
                              <CommandEmpty>No Customer found.</CommandEmpty>
                              <CommandGroup>
                                {customerList?.list?.map((customer) => (
                                  <CommandItem
                                    value={customer.id}
                                    key={customer.id}
                                    onSelect={() => {
                                      form.setValue("clientId", customer.id);
                                      setOpenSector(false);
                                      caseTypeIdRef.current?.focus();
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        customer.id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {customer.clientName}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      {error && (
                        <p className="text-red-600 text-sm mt-1">
                          {error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <Button className="" onClick={() => setOpenAddCustomer(true)}>
                <Plus size={18} />
                New Customer
              </Button>
            </div>
            {/* <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <textarea
                      {...field}
                      className="w-full p-2 border rounded-md"
                      rows={4}
                      placeholder="Enter task description"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}

            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Start date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                          ref={startDateRef}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 z-9999" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={(value) => {
                          field.onChange(value);
                          endDateRef.current?.focus();
                        }}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>End Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                          ref={endDateRef}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0 z-9999" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={(value) => {
                          field.onChange(value);
                          submitButtonRef.current?.focus();
                        }}
                        disabled={(date) => date < form.getValues("startDate")}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
            control={form.control}
            name="taskName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Task Name</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter Task Name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
            />
          <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} placeholder="Enter Description" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          />

            <FormField
              control={form.control}
              name="users"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Assign User</FormLabel>
                  <FormControl>
                    <Autocomplete
                      multiple
                      options={usersList || []}
                      getOptionLabel={(option: IUser) => option.fullName}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={field.value} // Set current value for selected permissions
                      onChange={(_, newValue) => {
                        field.onChange(newValue); // Update form value with selected permissions
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Users"
                          placeholder="Users"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isUserFetchingLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <CustomButton
              type="submit"
              className="w-full"
              isLoading={isGenericTaskAddLoading}
            >
              Submit
            </CustomButton>
          </form>
        </Form>
      </DefaultDialog>
      {openAddCustomer && (
        <AddCustomer
          openForm={openAddCustomer}
          setOpenForm={setOpenAddCustomer}
        />
      )}
    </>
  );
};
