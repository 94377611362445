import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Switch } from "@/components/ui/switch";
import { useCreateWorkFlowParameterMutation } from "../../api/work-flow-command";
import { useEffect, useRef } from "react";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { IWorkFlow } from "../../Case/models/workflow";
import { X } from "lucide-react";
import { NotificationOptions } from "@/model/enums";
import { Notify } from "@/components/shared/Notification/notify";
import CustomButton from "@/components/shared/Button/CustomButton";

const parameterFormSchema = z
  .object({
    parameterName: z.string({ required_error: "Please enter a label." }).min(3),
    parameterType: z.enum([
      "text",
      "number",
      "date",
      "boolean",
      "select",
      "multiselect",
      "attachment",
    ]),
    isRequired: z.boolean().default(true).optional(),
    isMultiple: z.boolean().default(false).optional(),
    paramaterValues: z
      .array(
        z.object({
          value: z.string(),
        })
      )
      .optional(),
    workFlowId: z.string(),
    isMultipleUserParticipated: z.boolean().default(false).optional(),
    notifyToClient: z.boolean().default(false),
    notificationOption: z.nativeEnum(NotificationOptions),
    isActive: z.boolean().default(true),
    isFixed: z.boolean().default(true),
  })

export type ParameterFormValues = z.infer<typeof parameterFormSchema>;

const inputDataTypes = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Boolean",
    value: "boolean",
  },

  {
    label: "Multi-Select",
    value: "select",
  },
  {
    label: "Attachment",
    value: "attachment",
  },
];

type AddCaseDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  workflow: IWorkFlow;
  currentNumberOfWorkFlows: number;
};

export const AddParameterDialog = ({
  openDialog,
  setOpenDialog,
  workflow,
}: AddCaseDialogProps) => {
  const form = useForm<ParameterFormValues>({
    resolver: zodResolver(parameterFormSchema),
    mode: "onChange",
    defaultValues: {
      parameterName: undefined,
      paramaterValues: [{ value: "" }],
      parameterType: "text",
      isMultipleUserParticipated: false,
      workFlowId: workflow.id,
      isActive: true,
      notificationOption: NotificationOptions.None,
      notifyToClient: false,
      isFixed: false,
    },
  });

  const [createParameter, { isLoading: isCreatingLoadingWorkFlow, isSuccess: isCreatingWorkFlowSuccess }] = useCreateWorkFlowParameterMutation();

  const onSubmit = async () => {
    const parameterValues = form.getValues("paramaterValues") ?? [];
    const values = parameterValues.map((item) => item.value);
    let parameterType = form.getValues("parameterType");
    if (parameterType === "select" && form.getValues("isMultiple")) {
      parameterType = "multiselect";
    }

    await createParameter({
      data: {
        workFlowId: workflow.id,
        parameterName: form.getValues("parameterName"),
        parameterType,
        isRequired: form.getValues("isRequired"),
        isMultipleUserParticipated: form.getValues("isMultiple"),
        paramaterValues: values.length ? JSON.stringify(values) : "",
        isActive: form.getValues("isActive"),
        notifyToClient: form.getValues("notifyToClient"),
        notificationOption: form.getValues("notifyToClient") ? form.getValues("notificationOption") : NotificationOptions.None,
        isFixed: form.getValues("isFixed"),
      },
    });
  };

  useEffect(() => {
    if (isCreatingWorkFlowSuccess) {
      setOpenDialog(false);
      form.reset();
      Notify("success", "WorkFlow Parameter added successfully!")
    }
  }, [isCreatingWorkFlowSuccess]);

  const { fields, append, remove } = useFieldArray({
    name: "paramaterValues",
    control: form.control,
  });

  const dataTypeWatch = useWatch({
    control: form.control,
    name: "parameterType",
  });

  const parameterTypeRef = useRef<HTMLButtonElement>(null);
  const requiredRef = useRef<HTMLButtonElement>(null);

  return (
    <DefaultDialog
      title="Add Parameter"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-4">
          <FormField
            control={form.control}
            name="parameterName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Label</FormLabel>
                <FormControl>
                  <Input
                    placeholder="parameter label"
                    {...field}
                    value={field.value ?? ""}
                  />
                </FormControl>
                <FormDescription className="px-3">
                  Form label for the parameter to be displayed in the form
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="parameterType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      requiredRef.current?.focus();
                    }}
                    defaultValue={field.value ?? "text"}
                    value={field.value ?? "text"}
                  >
                    <SelectTrigger className="" ref={parameterTypeRef}>
                      <SelectValue placeholder="Select data type" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {inputDataTypes.map((item) => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {dataTypeWatch === "select" && (
            <div>
              {fields.map((field, index) => (
                <FormField
                  control={form.control}
                  key={field.id}
                  name={`paramaterValues.${index}.value`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className={cn(index !== 0 && "sr-only")}>
                        Option
                      </FormLabel>
                      <div className="flex gap-2">
                        <div className="w-full">
                          <FormControl>
                            <Input
                              {...field}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  append({ value: "" });
                                  setTimeout(() => {
                                    const newInput = document.querySelector(
                                      `input[name="paramaterValues.${fields.length}.value"]`
                                    );
                                    (newInput as HTMLInputElement)?.focus();
                                  }, 100);
                                }
                              }}
                              required
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                        <Button
                          onClick={() => remove(index)}
                          variant={"outline"}
                          size={"icon"}
                          className="rounded-full text-red-500 hover:border-1 border-0 hover:ring-red-500 hover:text-red-600"
                        >
                          <X size={14} />
                        </Button>
                      </div>
                    </FormItem>
                  )}
                />
              ))}
              <div className="px-3">
                <Label className="text-red-500">
                  {form.getFieldState("paramaterValues").error?.root?.message || ""}
                </Label>
              </div>
              <Button
                type="button"
                variant="secondary"
                size="sm"
                className="mt-2"
                onClick={() => append({ value: "" })}
              >
                Add Option
              </Button>

              <FormField
                control={form.control}
                name="isMultiple"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Multiple Choice
                      </FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          )}

          <FormField
            control={form.control}
            name="isRequired"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Required</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    ref={requiredRef}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notifyToClient"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 my-2">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Notify User</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {form.getValues("notifyToClient") && (
            <FormField
              control={form.control}
              name="notificationOption"
              render={({ field }) => (
                <>
                  <span className="mt-3">Selection Type</span>
                  <Select
                    value={field.value}
                    onValueChange={(value) => field.onChange(value)}
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={"Select an option"}
                        className="mt-2"
                      />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {Object.values(NotificationOptions)
                        .filter((option) => option !== NotificationOptions.None)
                        .map((option) => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </>
              )}
            />
          )}
          
          <div className="flex items-center">
            <CustomButton isLoading={isCreatingLoadingWorkFlow} type="submit" className="w-50 mx-auto">
              Add
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
