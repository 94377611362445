import { IAlert } from "../models/alert";
import { useNavigate } from "react-router-dom";
import { useCompleteAlertMutation, useDeleteAlertMutation, useGetAlertsQuery } from "../api/alert-api";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {MaterialReactTable,  MRT_ColumnDef } from "material-react-table";
import { Button } from "@/components/ui/button";
import { Check, DeleteIcon, Edit2 } from "lucide-react";
import { EditGenericAlertDialog } from "./EditGenericAlertDialog";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Notify } from "@/components/shared/Notification/notify";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import Chip from "@/components/shared/customShadCN/Chip";
import Caption from "@/components/shared/customShadCN/Caption";
import { IUser } from "@/features/users/models/User";

const dateOptions = [
{ label: "This Week", value: "This Week" },
{ label: "Last Week", value: "Last Week" },
{ label: "Last 30 Days", value: "Last 30 Days" },
{ label: "Last 6 Months", value: "Last 6 Months" },
{ label: "All", value: "All" },
];

const alertFromOptions = [
{ label: "Task", value: "Task" },
{ label: "Generic Task", value: "GenericTask" },
{ label: "Other", value: "Other" },
];

const AlertList = (): JSX.Element => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<string>("This Week");
  const [selectedAlertFrom, setSelectAlertFrom] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const [openEditAlert, setOpenEditAlert] = useState<boolean>(false)
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false)
  const [openCompleteAlert, setOpenCompleteAlert] = useState<boolean>(false)
  const [selectedAlert, setSelectedAlert] = useState<IAlert | null>(null)

  const {
    data: alertList,
    isLoading: isAlertsFetchLoading,
    refetch,
  } = useGetAlertsQuery({
    data: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      alertFromFilter: selectedAlertFrom,
    },
  });

  const [DeleteAlert, { isLoading: isDeleteAlertLoading, isSuccess: isDeleteAlertSuccess }] = useDeleteAlertMutation()

  const [CompleteAlert, { isLoading: isCompleteAlertLoading, isSuccess: isCompleteAlertSuccess }] = useCompleteAlertMutation()

  const handleSelectEvent = (event: IAlert) => {
    if(event.taskId) {
      if (event.alertFrom === "GenericTask") {
        navigate(`task/generic-task/${event.taskId}`);
      } else {
        navigate(`/task/operational-task/${event.taskId}`);
      }
    }
  };

  const handleDeleteAlert = async () => {
    await DeleteAlert({ data: { id: selectedAlert?.id}})
  }

  const handleCompleteAlert = async () => {
    await CompleteAlert({ data: { id: selectedAlert?.id}})
  }

  useEffect(() => {
    let start = null;
    let end = null;

    const today = dayjs();

    switch (selectedDate) {
      case "This Week":
        start = today.startOf("week").add(1, "day").format("YYYY-MM-DD");
        end = today.endOf("week").add(1, "day").format("YYYY-MM-DD");
        break;
      case "Last Week":
        start = today.subtract(7, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 30 Days":
        start = today.subtract(30, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 6 Months":
        start = today.subtract(6, "months").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "All":
        start = null;
        end = null;
        break;
      default:
        break;
    }
    setDateRange({ startDate: start, endDate: end });
  }, [selectedDate]);

  useEffect(() => {
    refetch();
  }, [dateRange, selectedAlertFrom]);

  useEffect(() => {
    if(isDeleteAlertSuccess) {
        Notify("success", "Alert deleted successfully!")
    }
  }, [isDeleteAlertSuccess])

  useEffect(() => {
    if(isCompleteAlertSuccess) {
        Notify("success", "Alert completed successfully!")
    }
  }, [isCompleteAlertSuccess])

  const alertListColumns: MRT_ColumnDef<IAlert>[] = [
    {
        accessorKey: "taskReference",
        header: "Task Reference",
        accessorFn(originalRow) {
          if(originalRow.taskReference) return `${originalRow.taskReference} -- ${originalRow.message}`
          else return `${originalRow.message}`
            
        },
    },
    {
        accessorKey: "time",
        header: "Start Date",
        accessorFn(originalRow) {
            return new Date(originalRow.time).toLocaleDateString()
        },
    },
    {
        accessorKey: "dueDate",
        header: "Due Date",
        accessorFn(originalRow) {
            return new Date(originalRow.time).toLocaleDateString()
        },
    },
    {
      accessorKey: "users",
      header: "Assigned users",
      Cell({
        row: {
          original: { users },
        },
      }) {
        if (users && users?.length > 1) {
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex flex-row gap-1 items-center flex-nowrap">
                    <Chip label={users?.[0]?.fullName ?? ""} />
                    <Caption className="w-8" text={`+ ${users?.length - 1}`} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {users?.map((user: IUser) => (
                    <div key={user.id}>
                      {user.fullName}
                    </div>
                  ))}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        } else if (users && users?.length !== 0) {
          return (
            <div className="flex gap-1 items-center">
              {users?.map((user: IUser) => (
                <Chip
                  key={user?.id}
                  label={users[0].fullName}
                />
              ))}
            </div>
          );
        }
      },
    },
    {
        id: "actions",
        header: "Action",
        enableHiding: false,
        Cell: ({ row }) => {
            return (
                <div className="flex gap-4">
                <Button
                    variant={"outline"}
                    disabled={row.original.isComplted}
                    className="text-primary"
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    setSelectedAlert(row.original);
                    setOpenEditAlert(true);
                    }}
                >
                    <Edit2 className="w-4 mr-2" />
                    Edit
                </Button>
                <Button
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    setSelectedAlert(row.original);
                    setOpenDeleteAlert(true);
                    }}
                    variant={"outline"}
                    disabled={row.original.isComplted}
                    className="text-[#B00020]"
                >
                    <DeleteIcon className="w-8 mr-2" />
                    Delete
                </Button>
                <Button
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    setSelectedAlert(row.original);
                    setOpenCompleteAlert(true);
                    }}
                    variant={"outline"}
                    disabled={row.original.isComplted}
                    className="text-[#00b140] border-[#00b140] hover:text-[#00b140]"
                >
                    <Check className="w-8 mr-2" />
                    Complete
                </Button>
                </div>
            );
        },
    },
]

  return (
    <>
      <div className="justify-self-end flex gap-4 my-2">
        <Select onValueChange={setSelectedDate} value={selectedDate}>
          <SelectTrigger>
            <SelectValue placeholder="Select Date" />
          </SelectTrigger>
          <SelectContent className="z-9999">
            {dateOptions.map((type, index) => (
              <SelectItem key={index} value={type.value}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select onValueChange={setSelectAlertFrom} value={selectedAlertFrom ?? ""}>
          <SelectTrigger>
            <SelectValue placeholder="Select Alert From" />
          </SelectTrigger>
          <SelectContent className="z-9999 flex-1">
            {alertFromOptions.map((from, index) => (
              <SelectItem key={index} value={from.value}>
                {from.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <MaterialReactTable
            columns={alertListColumns}
            data={alertList ?? []}
            state={{
                isLoading: isAlertsFetchLoading,
            }}
            muiTableBodyRowProps={(row) => ({
                onClick: () => {
                    handleSelectEvent(row.row.original)
                },
                sx: {
                    cursor: 'pointer',
                }
            })}
            enableRowSelection={false}
        />
      </div>
      {
        selectedAlert && openEditAlert && <EditGenericAlertDialog openAlertDialog={openEditAlert} setOpenAlertDialog={(value) => setOpenEditAlert(value)} alert={selectedAlert}/>
      }
      {
        selectedAlert && openDeleteAlert && <ConfirmModal
        title="Delete Alert"
        message="Are you sure you want to delete this alert?"
        modalOpen={openDeleteAlert}
        setModalOpen={() => setOpenDeleteAlert(false)}
        type="error"
        onCancel={() => setOpenDeleteAlert(false)}
        onOk={async() => { await handleDeleteAlert() }}
        loading={isDeleteAlertLoading}
        />
      }
      {
        selectedAlert && openCompleteAlert && <ConfirmModal
        title="Complete Alert"
        message="Are you sure you want to complete this alert?"
        modalOpen={openCompleteAlert}
        setModalOpen={() => setOpenCompleteAlert(false)}
        type="success"
        onCancel={() => setOpenCompleteAlert(false)}
        onOk={async() => { await handleCompleteAlert() }}
        loading={isCompleteAlertLoading}
        />
      }
    </>
  );
};

export default AlertList;