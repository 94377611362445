import { PaymentCollection, PaymentFrom } from '@/features/finance/paymentCollection/model/PaymentCollection';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useGetClientFinanceQuery } from '../api/customer-api';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useGetCollectionPaymentTypeQuery } from '@/features/finance/paymentCollection/api/payment-collection-api';

const paymentListColumns: MRT_ColumnDef<PaymentCollection>[] = [
    {
      accessorKey: "paidAmount",
      header: "Amount",
      Cell: ({ row }) => {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "ETB",
        }).format(row.original.paidAmount);
      },
    },
    {
      accessorKey: "paymentMode",
      header: "Payment Mode",
    },      
    {
      accessorKey: "paymentDeadline",
      header: "Payment Due Date",
      Cell: ({ row }) => {
        return new Date(row.original.paymentDeadline).toLocaleDateString();
      },
    },
    {
      accessorKey: "paymentDate",
      header: "Payment Date",
      Cell: ({ row }) => {
        return new Date(row.original.paymentDate).toLocaleDateString();
      },
    },
    {
      accessorKey: "taskReference",
      header: "Task Reference"
    },
    {
      accessorKey: "status",
      header: "Status"
    },
  ];

interface ClientPaymentProps {
    clientId: string
}

const dateOptions = [
  { label: "This Week", value: "This Week" },
  { label: "Last Week", value: "Last Week" },
  { label: "Last 30 Days", value: "Last 30 Days" },
  { label: "Last 6 Months", value: "Last 6 Months" },
  { label: "All", value: "All" },
];

const ClientPayment = ({clientId}: ClientPaymentProps) => {
  const [selectedDate, setSelectedDate] = useState<string>("All");
  const [selectedPaymentFrom, setSelectPaymentFrom] = useState<string | null>(
    null
  );
  const [selectedPaymentType, setSelectPaymentType] = useState<string | null>(
    null
  );

  const [dateRange, setDateRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const theme = useTheme(); // Initialize MUI theme
  
  const { data: paymentTypes } = useGetCollectionPaymentTypeQuery();
  const { 
      data: paymentList,
      isFetching: isPaymentsFetching,
      refetch
      } = useGetClientFinanceQuery({
      data: {
        clientId: clientId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate, 
        paymentFrom: selectedPaymentFrom,
        paymentType: selectedPaymentType,
      },
      })
  
  useEffect(() => {
    let start = null;
    let end = null;

    const today = dayjs();

    switch (selectedDate) {
      case "This Week":
        start = today.startOf("week").add(1, "day").format("YYYY-MM-DD");
        end = today.endOf("week").add(1, "day").format("YYYY-MM-DD");
        break;
      case "Last Week":
        start = today.subtract(7, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 30 Days":
        start = today.subtract(30, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 6 Months":
        start = today.subtract(6, "months").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "All":
        start = null;
        end = null;
        break;
      default:
        break;
    }
    setDateRange({ startDate: start, endDate: end });
  }, [selectedDate]);

  useEffect(() => {
    refetch();
  }, [dateRange, refetch, selectedPaymentFrom, selectedPaymentType]);


  return (
    <>
    <div className="flex flex-col gap-2">
      <div className="flex items-center w-full justify-between">
        <div className="justify-self-end flex gap-4 w-[50%]">
          <Select onValueChange={setSelectedDate} value={selectedDate}>
            <SelectTrigger>
              <SelectValue placeholder="Select Date" />
            </SelectTrigger>
            <SelectContent className="z-9999">
              {dateOptions.map((type, index) => (
                <SelectItem key={index} value={type.value}>
                  {type.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            onValueChange={setSelectPaymentFrom}
            value={selectedPaymentFrom ?? ""}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Payment From" />
            </SelectTrigger>
            <SelectContent className="z-9999 flex-1">
              {Object.values(PaymentFrom).map((mode) => (
                <SelectItem key={mode} value={mode}>
                  {mode}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            onValueChange={setSelectPaymentType}
            value={selectedPaymentType ?? ""}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Payment Type" />
            </SelectTrigger>
            <SelectContent className="z-9999 flex-1">
              {paymentTypes?.map((type) => (
                <SelectItem key={type.id} value={type.id}>
                  {type.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <MaterialReactTable
          columns={paymentListColumns}
          data={paymentList ?? []}
          state={{isLoading: isPaymentsFetching}}
          enablePagination
          muiTableBodyRowProps={({ row }) => ({
          sx: {
              backgroundColor: row.original.status === "Void" 
              ? theme.palette.error.light // Light red background
              : 'inherit', // Default background
              '&:hover': {
              backgroundColor: row.original.status === "Void" 
                  ? theme.palette.error.light 
                  : theme.palette.action.hover,
              },
              cursor: "pointer"
          },
          })}
      />
    </div>
    </>
)
}

export default ClientPayment
