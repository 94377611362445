import { appApi } from "@/store/app-api";
import { IPaymentType } from "../models/payments";

export const paymentTypeApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentTypes: builder.query<IPaymentType[], void>({
      query: () => ({
        url: "/paymentTypes",
        method: "GET",
      }),
      providesTags: ["Task", "TaskDetail", "Payment"],
    }),
  }),
});

export const { useGetPaymentTypesQuery } = paymentTypeApi;
