import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../lib/axios/axios-base-query";

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Users",
    "User",
    "Roles",
    "Customers",
    "Customer",
    "Cases",
    "Sectors",
    "Task",
    "TaskDetail",
    "Notification",
    "Organization",
    "TaskNote",
    "SMS",
    "Email",
    "TaskPayment",
    "GenericTask",
    "GenericTaskDetail",
    "Alerts",
    "AlertType",
    "GenericTaskPayment",
    "PaymentCollection",
    "Leads",
    "Source",
    "SalesReport",
    "Payment",
  ],
  endpoints: () => ({}),
});
