import { DefaultPage } from "@/components/shared/DefaultPage";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { UserPerformanceReport } from "../model/UserPerfomanceReport";
import { useGetUserPerformanceReportByIdQuery } from "../api/report-api";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "@radix-ui/react-icons"
import { format } from "date-fns"
 
import { cn } from "@/lib/utils"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

// Define columns for MaterialReactTable
const usePerfomanceColumns: MRT_ColumnDef<UserPerformanceReport>[] = [
  {
    accessorKey: "userName",
    header: "User Name",
  },
  {
    accessorKey: "totalTasksAssigned",
    header: "Total Task Assigned",
  },
  {
    accessorKey: "completedTasks",
    header: "Completed Task",
  },
  {
    accessorKey: "inProgressTasks",
    header: "In Progress Task",
  },
  {
    accessorKey: "averageCompletionTime",
    header: "Average Completion Time",
    accessorFn(originalRow) {
      return originalRow.averageCompletionTime;
    },
  },
  {
    accessorKey: "tasksOnTime",
    header: "Task On Time",
  },
  {
    accessorKey: "overdueTasks",
    header: "Over Due Tasks",
  },
];

export function Report() {
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()
  const { data: usePerfomanceList, isLoading, isError, refetch } = useGetUserPerformanceReportByIdQuery({
    data: {
      from: fromDate,
      to: toDate
    }
  });

  useEffect(() => {
    if(toDate || fromDate) refetch()
  }, [fromDate, toDate])

  return (
    <>
      <DefaultPage headerTitle="User Performance Report">
        <div className="flex justify-end gap-4 p-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !fromDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {fromDate ? format(fromDate, "PPP") : <span>From date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={fromDate}
                onSelect={setFromDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !toDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {toDate ? format(toDate, "PPP") : <span>To date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={toDate}
                onSelect={setToDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
        {isLoading && <p>Loading...</p>}
        {isError && <p>Error loading users</p>}
        {usePerfomanceList && (
          <MaterialReactTable
            columns={usePerfomanceColumns}
            data={usePerfomanceList ?? []}
            enablePagination
            // initialState={{ pagination: { pageSize: 10 } }}
          />
        )}
      </DefaultPage>
    </>
  );
}
