import React, { SetStateAction } from 'react'
import { PaymentCollection } from '../model/PaymentCollection'
import { DefaultDialog } from '@/components/shared/DefaultDailog/DefaultDialog'

interface PaymentDetailProps {
    openForm: boolean,
    setOpenForm: React.Dispatch<SetStateAction<boolean>>,
    payment: PaymentCollection
}
const PaymentDetail = ({openForm, setOpenForm, payment}: PaymentDetailProps) => {
  return (
    <DefaultDialog
    title='Payment Detail'
    open={openForm}
    onClose={() => setOpenForm(false)}
    >
        <div>
            <p>{payment.description}</p>
        </div>
    </DefaultDialog>
  )
}

export default PaymentDetail
