import { IRole } from "./Role";

// Define the UserType Enum
export enum UserType {
  Root = "Root",
  Staff = "Staff",
  Client = "Client",
}

// Define the SubscriptionPlan interface
export interface SubscriptionPlan {
  planId: string;
  planName: string;
  price: number;
  durationInMonths: number;
}

// Define the UserRole interface

// Define the TaskAssignee interface
export interface TaskAssignee {
  taskId: string;
  assignedAt: Date;
}

// Define the TaskHistory interface
export interface TaskHistory {
  taskId: string;
  status: string;
  updatedAt: Date;
}

// Define the main User interface
export interface IUser {
  id: string;
  fullName: string;
  username: string;
  email: string;
  isActive: boolean;
  passwordHash: string;
  phoneNumber: string;
  subscriptionExpiry?: Date | null;
  subscriptionPlanId?: string | null;
  userType?: UserType;
  referenceId?: string;
  organizationId?: string;
  subscriptionPlan?: SubscriptionPlan;
  roles?: IRole[];
  clients?: UserClient[];
  taskAssignees?: TaskAssignee[];
  taskHistories?: TaskHistory[];
}

interface UserClient {
  clientId: string;
  clientName: string;
}
