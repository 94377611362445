export enum UserType {
  Client = "Client",
  Staff = "Staff",
  Root = "Root",
}

// Update the Menu type to accept an array of UserType values
export type Menu = {
  name: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  path: string;
  permissions: string[];
  allowedUserType: UserType[]; // Now accepts multiple user types
  parentLinked?: boolean;
  child?: Menu[];
};

export function filterMenusByUserTypeAndPermissions(
  menus: Menu[],
  permissionKeys: string[],
  userType: string
): Menu[] {
  return menus
    .filter((menu) => {
      if (userType === UserType.Root) {
        return true;
      }
      // Check if the userType is allowed to access the menu
      const isUserTypeAllowed = menu.allowedUserType.includes(
        userType as UserType
      );

      // If the menu has permissions, check if the user has ALL of them
      const hasRequiredPermissions = menu.permissions.every((permission) =>
        permissionKeys.includes(permission)
      );

      // Include the menu if the user has permissions and userType is allowed
      return hasRequiredPermissions && isUserTypeAllowed;
    })
    .map((menu) => {
      // If the menu has child menus, filter them recursively
      if (menu.child) {
        const filteredChildMenus = filterMenusByUserTypeAndPermissions(
          menu.child,
          permissionKeys,
          userType
        );
        // If all child menus are hidden, hide the parent menu as well
        if (filteredChildMenus.length === 0) {
          return null; // Hide the parent menu by returning null
        }
        return { ...menu, child: filteredChildMenus };
      }
      // If the menu doesn't have child menus, return it as is
      return menu;
    })
    .filter((menu) => menu !== null) as Menu[]; // Filter out the hidden parent menus
}
