import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useGetTaskHistoryByTaskIdQuery } from "../api/task-query";
import { ITaskHistory } from "../models/Task";
import { Badge } from "@/components/ui/badge";
import { titleCase } from "@/lib/utils";

type TaskHistoryProps = {
  taskId: string;
};
const taskHistoryColumns: MRT_ColumnDef<ITaskHistory>[] = [
  {
    accessorKey: "userName",
    header: "User",
  },
  {
    accessorKey: "oldStatus",
    header: "Old Status",
    Cell: ({ row }) => {
      return titleCase(row.original.oldStatus);
    },
  },
  {
    accessorKey: "newStatus",
    header: "New Status",
  },
  {
    accessorKey: "note",
    header: "Note",
  },
  {
    accessorKey: "taskDate",
    header: "Date",
    Cell: ({ row }) => {
      return new Date(row.original.taskDate).toLocaleDateString();
    },
  },

  {
    accessorKey: "workFlowStatus",
    header: "Workflow Status",
    Cell: ({ row }) => {
      return row.original.workFlowStatus === "Canceled" ? (
        <Badge variant="destructive">
          {titleCase(row.original.workFlowStatus)}
        </Badge>
      ) : (
        titleCase(row.original.workFlowStatus)
      );
    },
  },
];
export const TaskHistory = ({ taskId }: TaskHistoryProps) => {
  const { data: taskHistory = [] } = useGetTaskHistoryByTaskIdQuery(taskId);

  return (
    <div>
      <MaterialReactTable
        columns={taskHistoryColumns}
        data={taskHistory}
        enablePagination
      />
    </div>
  );
};
