import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";
export type DefaultPageHeaderProps = {
  headerTitle: string;
  headerActionButton?: React.ReactNode;
  breadcrumbsLinks?: { name: string; url: string }[];
};

export const DefaultPageHeader: React.FC<DefaultPageHeaderProps> = ({
  headerTitle,
  headerActionButton,
  breadcrumbsLinks,
}) => {
  return (
    <div>
      {breadcrumbsLinks && (
        <Breadcrumb>
          <BreadcrumbList>
            {breadcrumbsLinks.map((link, index) => (
              <React.Fragment key={index}>
                <BreadcrumbItem key={index}>
                  <BreadcrumbLink asChild>
                    <Link to={link.url}>{link.name}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {index < breadcrumbsLinks.length - 1 && <BreadcrumbSeparator />}
              </React.Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      )}
      <div className="flex items-center justify-between py-4">
        <h1 className="text-2xl font-medium">{headerTitle}</h1>
        {headerActionButton && <div>{headerActionButton}</div>}
      </div>
    </div>
  );
};
