import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import AddSectorForm from "./AddSectorForm";

type AddSectorDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
};
export const AddSectorDialog = ({
  openDialog,
  setOpenDialog,
}: AddSectorDialogProps) => {

  return (
    <DefaultDialog
      title="Add Sector"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <div className="mt-4">
        <AddSectorForm setOpenDialog={() => setOpenDialog(false)} />
      </div>
    </DefaultDialog>
  );
};
