import React, { SetStateAction, useEffect } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import CustomButton from '@/components/shared/Button/CustomButton';
import { useGetRolesQuery, useUpdateUserRoleMutation } from '../api/user-api'; // Update to get roles
import { Notify } from '@/components/shared/Notification/notify';
import { IUser } from '../models/User';
import { IRole } from '../models/Role'; // Role model
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

// Zod schema expecting an array of role objects with id and roleName
const userFormSchema = z.object({
  roles: z.array(z.object({
    id: z.string(),
    roleName: z.string(),
    description:z.string()
  })).refine((roles) => roles.length > 0, {
    message: "At least one role is required",
  }),
});


type RoleFormValues = z.infer<typeof userFormSchema>;

type EditUserRoleProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  user: IUser;
};

const EditUserRole = ({ openForm, setOpenForm, user }: EditUserRoleProps) => {
    
  const [UpdateUserRole, { isLoading: isSubmitting, isSuccess }] = useUpdateUserRoleMutation();
  const { data: rolesList, isLoading: isRolesLoading } = useGetRolesQuery({
    url: "/roles",
    method: "GET",
  });
  // Default selected roles from the user (assuming user.userRoles is an array of roles)
  const defaultValues: RoleFormValues = {
    roles: user.userRoles?.map(role => ({
      id: role.id,
      roleName: role.roleName,
      description: role.description
    })) ?? [], // Populate selected roles as objects with id and roleName
  };

  const form = useForm<RoleFormValues>({
    resolver: zodResolver(userFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: RoleFormValues) => {
    await UpdateUserRole({
      url: `/users/UpdateUserRoles`,
      method: "PUT",
      data: {
        userId: user.id,
        roleIds: data.roles.map(role => role.id), // Send only role IDs to the server
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "Role(s) Edited Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Edit Role" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="z-50 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="roles"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Select Roles</FormLabel>
                <FormControl>
                  <Autocomplete
                    multiple
                    options={rolesList || []} // Roles fetched from the API
                    getOptionLabel={(option: IRole) => option.roleName} // Display role name
                    value={field.value || []} // Match selected roles as objects
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, selectedRoles: IRole[]) => {
                      field.onChange(selectedRoles); // Update form field with selected role objects
                    }}
                    sx={{
                        zIndex:10000
                    }}
                    loading={isRolesLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select roles"
                        error={!!form.formState.errors.roles}
                        helperText={form.formState.errors.roles?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isRolesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <CustomButton isLoading={isSubmitting} className="lg:self-center" type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditUserRole;
