import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useCancelWorkflowMutation } from "../api/task-command";
import { Textarea } from "@/components/ui/textarea";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useCancelSalesTaskMutation } from "@/features/sales/api/pipe-line-wf-command";

type Props = {
  taskId: string;
  isSalesWf?: boolean;
};

export const CancelTaskDialog = ({ taskId, isSalesWf }: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [note, setNote] = useState("");

  const [cancelWorkflow, { isSuccess: isCancelWorkflowSuccess, isLoading }] =
    useCancelWorkflowMutation();
  const [
    cancelSalesWorkflow,
    {
      isSuccess: isCancelSalesWorkflowSuccess,
      isLoading: isCancelSalesWfLoading,
    },
  ] = useCancelSalesTaskMutation();

  const handleCancelWorkflow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSalesWf) {
      await cancelSalesWorkflow({
        data: {
          taskId: taskId,
          note: note,
        },
      });
      return;
    }
    await cancelWorkflow({
      data: {
        taskId: taskId,
        note: note,
      },
    });
  };
  useEffect(() => {
    if (isCancelWorkflowSuccess || isCancelSalesWorkflowSuccess) {
      setOpenDialog(false);
    }
  }, [isCancelSalesWorkflowSuccess, isCancelWorkflowSuccess]);

  return (
    <div className="flex  mt-3">
      <Button
        variant={"outline"}
        onClick={() => setOpenDialog(true)}
        className="border-red-400 text-red-500 hover:bg-red-400 hover:text-white"
      >
        Cancel
      </Button>
      <DefaultDialog
        title="Cancel Workflow"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <form onSubmit={handleCancelWorkflow}>
          <div className="mt-3">
            <span className="m-1">Note</span>
            <Textarea
              placeholder="Add Note"
              className="mt-2"
              required
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <div className="flex justify-center mt-3">
              <CustomButton
                className="w-50 items-center"
                isLoading={isLoading || isCancelSalesWfLoading}
              >
                Submit
              </CustomButton>
            </div>
          </div>
        </form>
      </DefaultDialog>
    </div>
  );
};
