import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import AddGenericTaskPayment from "./AddGenericTaskPayment";
import { Payment } from "@mui/icons-material";
import PayGenericTaskPayment from "./PayGenericTaskPayment";
import { IGenericTaskDetail, IGenericTaskPayment } from "../model/GenericTask";
import { useGetGenericTaskPayementByTaskIdQuery } from "../api/generic-task-api";

type GenericTaskPaymentProps = {
  taskDetail: IGenericTaskDetail;
};


export const GenericTaskPayment = ({ taskDetail }: GenericTaskPaymentProps) => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [openPayForm, setOpenPayForm] = useState<boolean>(false)
    const [selectedPayment, setSelectedPayment] = useState<string | null>(null)
    const { data } = useGetGenericTaskPayementByTaskIdQuery(taskDetail.id)

    
    const taskHistoryColumns: MRT_ColumnDef<IGenericTaskPayment>[] = [
      {
        accessorKey: "paymentDue",
        header: "Payment Due",
        Cell: ({ row }) => {
          return new Intl.NumberFormat('en-ET', { style: 'currency', currency: 'ETB' }).format(row.original.paymentDue);
        },
      },
      {
        accessorKey: "paidAmount",
        header: "Paid Amount",
        Cell: ({ row }) => {
          return new Intl.NumberFormat('en-ET', { style: 'currency', currency: 'ETB' }).format(row.original.paidAmount);
        },
      },
      {
        accessorKey: "paymentMode",
        header: "Payment Mode",
      },
      {
        accessorKey: "paymentDate",
        header: "Payment Date",
        Cell: ({ row }) => {
          return new Date(row.original.paymentDate).toLocaleDateString();
        },
      },
      {
        accessorKey: "paymentDeadline",
        header: "Payment Due Date",
        Cell: ({ row }) => {
          return new Date(row.original.paymentDeadline).toLocaleDateString();
        },
      },
      {
        header: "Action",
        Cell: ({ row }) => {
          return (
            <Button
              disabled={row.original.paidAmount === row.original.paymentDue}
              variant="outline"
              className="flex gap-2 text-primary"
              onClick={() => {
                setSelectedPayment(row.original.id);
                setOpenPayForm(true);
              }}
            >
              <Payment /> Pay
            </Button>
          );
        },
      }
    ];
    

    return (
        <>
            <div className="flex flex-col gap-2">
                <Button 
                className="self-end"
                onClick={() => setOpenForm(true)}
                >
                    Add Payment
                </Button>
                <MaterialReactTable
                    columns={taskHistoryColumns}
                    data={data ?? []}
                    enablePagination
                />
            </div>
            {
                openForm && <AddGenericTaskPayment openForm={openForm} setOpenForm={setOpenForm} taskDetail={taskDetail}/>
            }
            {
                selectedPayment && openPayForm && <PayGenericTaskPayment openForm={openPayForm} setOpenForm={setOpenPayForm} paymentId={selectedPayment}/>
            }
        </>
    );
};