import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect } from "react";
import { useCopySectorMutation, useGetSectorsQuery } from "../api/case-api";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { ISector } from "../models/Case";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const copySectorFormSchema = z.object({
    sourceSector: z.string({
    required_error: "Please select source sector!",
  }),
});

type CopySectorFormValues = z.infer<typeof copySectorFormSchema>;

type CopySectorProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  targetSector: ISector
}

export const CopySectorDialog = ({targetSector, setOpenDialog, openDialog}: CopySectorProps) => {
  const form = useForm<CopySectorFormValues>({
    resolver: zodResolver(copySectorFormSchema),
    mode: "onChange",
    // defaultValues,
  });

  const [copyCase, { isLoading: isLoadingCopyCase, isSuccess: isSuccessCopyCase }] = useCopySectorMutation()

  const { data: sectorsHasCase = [] } = useGetSectorsQuery({});

  const onSubmit = async () => {
    await copyCase({
      data: { 
        targetSectorId: targetSector.id,
        sourceSectorId: form.getValues().sourceSector
      }
    }
    )
  };

  useEffect(() => {
    if (isSuccessCopyCase) {
      setOpenDialog(false);
      form.reset();
      Notify("success", "Sector Copied Successfully!")
    }
  }, [isSuccessCopyCase]);

  return (
    <DefaultDialog
      open={openDialog}
      title="Copy Sector"
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="sourceSector"
          render={({ field }) => (
          <FormItem className='flex-1'>
              <FormLabel>Source Sector</FormLabel>
              <FormControl>
              <Select
                  onValueChange={(value) => {
                  field.onChange(value);
                  }}
                  defaultValue={field.value ?? ""}
                  value={field.value ?? ""}
              >
                  <SelectTrigger className="">
                  <SelectValue placeholder="Select Source Sector" />
                  </SelectTrigger>
                  <SelectContent className="z-[1400]">
                  {sectorsHasCase.map((item) => (
                      <SelectItem key={item.id} value={item.id}>
                      {item.sectorName}
                      </SelectItem>
                  ))}
                  </SelectContent>
              </Select>
              </FormControl>
              <FormMessage />
          </FormItem>
          )}
        />

          <div className="flex items-center">
            <CustomButton disabled={!form.formState.isValid} isLoading={isLoadingCopyCase} type="submit" className="w-full mx-auto">
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
