import React, { SetStateAction, useEffect } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from '@/components/shared/Button/CustomButton';
import { Notify } from '@/components/shared/Notification/notify';
import { useAddAlertTypeMutation } from '../api/alert-api';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { NotificationOptions } from '@/model/enums';

const alertTypeFormSchema = z.object({
  alertName: z.string().nonempty("Alert Name is required"),
  remark: z.string().nonempty("Remark is required"),
  notificationOption: z.enum(["None", "All", "SMS", "Email", "PushNotification"]),
});

type AlertTypeFormValues = z.infer<typeof alertTypeFormSchema>

const defaultValues: AlertTypeFormValues = {
  alertName: "",
  remark: "",
  notificationOption: "All"
};

type AddAlertTypeProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
};


const AddAlertType = ({ openForm, setOpenForm }: AddAlertTypeProps) => {
  const [addAlrtType, { isLoading, isSuccess }] = useAddAlertTypeMutation();
  
  const form = useForm<AlertTypeFormValues>({
    resolver: zodResolver(alertTypeFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: AlertTypeFormValues) => {
    await addAlrtType({
      data: {
        alertName: data.alertName,
        remark: data.remark,
        notificationOption: data.notificationOption
        }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpenForm(false);
      Notify("success", "Alert Type Added Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Add Alert Type" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="p-2 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="alertName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Alert Name</FormLabel>
                <FormControl>
                  <Input placeholder="Alert Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="remark"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Remark</FormLabel>
                <FormControl>
                  <Input placeholder="Remark" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notificationOption"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Notification Option</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Notification Option" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {Object.values(NotificationOptions).map((type, index) => (
                        <SelectItem key={index} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <CustomButton disabled={!form.formState.isValid} isLoading={isLoading} type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default AddAlertType;
