// components/form/FormField.tsx
import React from "react";
import { Dayjs } from "dayjs";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import MultiSelectInput from "./MultiSelectInput";
import DateInput from "./DateInput";
import { Input } from "@/components/ui/input";

interface FormFieldProps {
  id: string;
  label: string;
  type: string;
  value: string | Dayjs | string[] | null;
  options?: string[];
  onChange: (id: string, value: string | Dayjs | string[] | null) => void;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  id,
  label,
  type,
  value,
  options = [],
  onChange,
  disabled,
  required = false,
}) => {
  switch (type.toLowerCase()) {
    case "string":
    case "text":
      return (
        <TextInput
          label={label}
          value={value as string}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    case "list":
    case "select":
      return (
        <SelectInput
          label={label}
          value={value as string}
          options={options}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    case "multiselect":
    case "multi-select":
      return (
        <MultiSelectInput
          label={label}
          value={value as string[]}
          options={options instanceof Array ? options : []}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
          required={required}
        />
      );

    case "date":
      return (
        <DateInput
          label={label}
          value={value ? (value as Dayjs) : null}
          onChange={(val) => onChange(id, val)}
          disabled={disabled}
        />
        // <TextInput
        //   label={label}
        //   value={value as string}
        //   onChange={(val) => onChange(id, val)}
        // />
      );
    case "attachment":
      return (
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Input
            id="picture"
            type="file"
            // onChange={(e) => {
            //   console.log(e.target.value);
            // }}
          />
        </div>
      );

    default:
      return null;
  }
};

export default FormField;
