import { IClientTask } from "@/features/task/models/Task";
import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IDashboard } from "../models/Dashboard";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskList: builder.query<IClientTask[], QueryRequest>({
      query: (params) => ({
        url: "/otasks/RecentTasks",
        method: "GET",
        params: {...params.data}
      }),
      providesTags: ["Task"], // Provides a tag for caching
    }),
    getTaskCount: builder.query<IDashboard, QueryRequest>({
        query: (params) => ({
          url: "/otasks/TaskCounts",
          method: "GET",
          params: {...params.data}
        }),
        providesTags: ["Task"], // Provides a tag for caching
    })
  }),
});

export const {
  useGetTaskListQuery,
  useGetTaskCountQuery
} = customerApi;
