import { ISalesWorkFlow } from "@/features/workflows/models/sales-wf-case";
import { appApi } from "@/store/app-api";
import { PagedResponse } from "@/model/pagination-response";
import { IClientSales } from "@/features/customers/models/Customer";
import { QueryRequest } from "@/model/query-request";
import {
  IAlertNotification,
  ITaskDetail,
  ITaskHistory,
} from "@/features/task/models/Task";

export const pipelineWfApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getPipelineWorkFlows: builder.query<ISalesWorkFlow[], void>({
      query: () => ({
        url: "/salesWorkFlows/GetPipelineWorkFlows",
        method: "GET",
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getTenderWorkFlows: builder.query<ISalesWorkFlow[], void>({
      query: () => ({
        url: "/salesWorkFlows/GetTenderWorkFlows",
        method: "GET",
      }),
      providesTags: ["Task", "TaskDetail"],
    }),

    searchPotentialClientsByWorkFlowId: builder.query<
      PagedResponse<IClientSales>,
      string
    >({
      query: (id) => ({
        url: `/clients/searchPotentialClientsByWorkFlowId`,
        method: "GET",
        params: { workFlowId: id },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),

    getSalesTaskDetailsByTaskId: builder.query<ITaskDetail, QueryRequest>({
      query: (params) => ({
        url: `/salesTasks/GetSalesTaskDetailsByTaskId`,
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getAllAlertsBySalesTask: builder.query<IAlertNotification[], string>({
      query: (taskId) => ({
        url: `/alert-notifications/GetAllAlertsBySalesTask`,
        method: "GET",
        params: { taskId: taskId },
      }),
      providesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    getSalesTaskHistoryByTaskId: builder.query<ITaskHistory[], string>({
      query: (id) => ({
        url: `/salesTasks/GetSalesTaskHistoryByTaskId`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
  }),
});

export const {
  useGetPipelineWorkFlowsQuery,
  useGetTenderWorkFlowsQuery,
  useSearchPotentialClientsByWorkFlowIdQuery,
  useGetSalesTaskDetailsByTaskIdQuery,
  useGetAllAlertsBySalesTaskQuery,
  useGetSalesTaskHistoryByTaskIdQuery,
} = pipelineWfApi;
