import { PropsWithChildren } from "react";
import {
  DialogProps,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export type DefaultDialogProps = Required<
  Pick<DialogProps, "open" | "onClose">
> & {
  title: string;
  maxWidth?: DialogProps["maxWidth"];
};
type reason = "backdropClick" | "escapeKeyDown";

export const DefaultDialog = (
  props: PropsWithChildren<DefaultDialogProps>
): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the device is mobile

  const handleClose = (event: React.SyntheticEvent, reason: reason): void => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    props.onClose(event, reason);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth={isMobile} // Dialog will take full width on mobile
      maxWidth={props.maxWidth ? props.maxWidth : isMobile ? "xs" : "sm"}
      PaperProps={{
        style: {
          // minWidth: isMobile ? "90%" : "430px",
          // maxWidth: isMobile ? "100%" : "450px",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "none",
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <Typography
            variant="h6"
            fontWeight="bold"
            component="span"
            sx={{ display: "block" }}
          >
            {props.title}
          </Typography>
        </div>
        <Button
          startIcon={<CloseOutlinedIcon />}
          color="error"
          onClick={() => props.onClose({}, "escapeKeyDown")}
        >
          Close
        </Button>
      </DialogTitle>

      <DialogContent sx={{ padding: isMobile ? 1 : 2 }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};
