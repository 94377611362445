import { DefaultPage } from "@/components/shared/DefaultPage";
// import { useFetchData } from "@/hooks/useFetchData";
import { useState } from "react";
import { CaseList } from "./components/CaseList";
import { AddCaseDialog } from "./components/AddCaseDialog";
import { useGetCasesQuery, useGetSectorsQuery } from "./api/case-api";
import TableLoadingSkeleton from "@/components/ui/TableLoadingSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AddSectorDialog } from "./components/AddSectorDialog";
import { SectorList } from "./components/SectorList";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

export function Case() {
  // const { data, error } = useFetchData<ICase[]>(getCases);
  // const [data, setData] = useState<ICase[]>(fakeCases);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSectorDialog, setOpenSectorDialog] = useState<boolean>(false);

  // const updateCase = (newCase: ICase) => {
  //   setData((prevData) => [...prevData, newCase]);
  //   setOpenDialog(false);
  // };
  const { data: caseList = [], isLoading } = useGetCasesQuery();

  const { data: sectors = [] } = useGetSectorsQuery({
    url: "/sectors",
    method: "GET",
  });

  const [selectedTab, setSelectedTab] = useState("sectors");

  return (
    <DefaultPage
      headerTitle={selectedTab == "cases" ? "List of Cases" : "List of Sectors"}
      headerActionButton={
        selectedTab == "cases" ? (
          <Button onClick={() => setOpenDialog(true)}>
            <Plus size={18} className="mr-2" />
            Add Case
          </Button>
        ) : (
          <Button onClick={() => setOpenSectorDialog(true)}>
            <Plus size={18} className="mr-2" />
            Add Sector
          </Button>
        )
      }
    >
      {openDialog && (
        <AddCaseDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      {openSectorDialog && (
        <AddSectorDialog
          openDialog={openSectorDialog}
          setOpenDialog={setOpenSectorDialog}
        />
      )}

      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="grid w-50 grid-cols-2">
          <TabsTrigger value="sectors">Sectors</TabsTrigger>
          <TabsTrigger value="cases">Cases</TabsTrigger>
        </TabsList>
        <TabsContent value="sectors">
          {isLoading ? (
            <TableLoadingSkeleton />
          ) : (
            <SectorList sectors={sectors} />
          )}
        </TabsContent>
        <TabsContent value="cases">
          {isLoading ? <TableLoadingSkeleton /> : <CaseList cases={caseList} />}
        </TabsContent>
      </Tabs>
    </DefaultPage>
  );
}
