import FormSelect from "@/components/form/FormSelect";
import FormTextarea from "@/components/form/FormTextarea";
import CustomButton from "@/components/shared/Button/CustomButton";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
// import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import {
  useAddGenericTaskActivityMutation,
  useUpdateGenericTaskActivityMutation,
} from "../api/generic-task-command";
import { ITaskDetail, ITaskDetailCreate } from "../model/GenericTask";
import { useEffect } from "react";

const parameterFormSchema = z
  .object({
    parameterType: z.enum([
      "text",
      "number",
      "date",
      "boolean",
      "select",
      "multiselect",
    ]),
    activity: z.string(),
    id: z.string().optional(),
    value: z.string().optional(),
    paramaterValues: z
      .array(
        z.object({
          value: z.string(),
        })
      )
      .optional(),
    isMultiple: z.boolean().optional().default(false),
  })
  .refine(
    (data) => {
      if (data.parameterType === "select") {
        return data.paramaterValues && data.paramaterValues.length > 1;
      }
      return true;
    },
    {
      message: "There Should be at least 2 options",
      path: ["paramaterValues"],
    }
  );

export type ParameterFormValues = z.infer<typeof parameterFormSchema>;

const inputDataTypes = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Number",
    value: "number",
  },
  // {
  //   label: "Date",
  //   value: "date",
  // },
  {
    label: "Boolean",
    value: "boolean",
  },

  {
    label: "Multi-Select",
    value: "select",
  },
];

type Props = {
  openDialog: boolean;
  onClose: (open: boolean) => void;
  taskId: string;
  isEditing?: boolean;
  taskDetail?: ITaskDetail | null;
};

export const AddGenericActivityDialog = ({
  openDialog,
  onClose,
  taskId,
  isEditing,
  taskDetail,
}: Props) => {
  const form = useForm<ParameterFormValues>({
    resolver: zodResolver(parameterFormSchema),
    mode: "onChange",
    defaultValues: {
      paramaterValues: taskDetail?.paramaterValues
        ? JSON.parse(taskDetail.paramaterValues).map((item: string) => ({
            value: item,
          }))
        : [{ value: "" }],
      parameterType: taskDetail?.parameterType ?? "text",
      activity: taskDetail?.activity ?? "",
      isMultiple: taskDetail?.parameterType === "multiselect",
      value: "",
      id: taskDetail?.id ?? "",
    },
  });
  const dataTypeWatch = useWatch({
    control: form.control,
    name: "parameterType",
  });

  const { fields, append, remove } = useFieldArray({
    name: "paramaterValues",
    control: form.control,
  });

  const [
    addActivity,
    { isSuccess: isAddingActivitySuccess, isLoading: isAddingActivityLoading },
  ] = useAddGenericTaskActivityMutation();
  const [updateActivity] = useUpdateGenericTaskActivityMutation();

  const onSubmit = async () => {
    const parameterValues = form.getValues("paramaterValues") ?? [];
    const values =
      parameterValues?.length > 1
        ? parameterValues?.map((item) => item.value)
        : [];
    if (values.length === 1 && values[0] === "") {
      values.pop();
    }
    let parameterType = form.getValues("parameterType");
    if (parameterType === "select" && form.getValues("isMultiple") === true) {
      parameterType = "multiselect";
    }
    const payload: ITaskDetailCreate = {
      taskId: taskId,
      parameterType: parameterType,
      paramaterValues: values.length > 0 ? JSON.stringify(values) : "",
      activity: form.getValues("activity"),
      value: form.getValues("value") ?? "",
    };
    if (isEditing && taskDetail) {
      await updateActivity(
        // taskId: taskId,
        // id: taskDetail.id,
        { ...taskDetail, ...payload }
      );
    } else {
      await addActivity(payload);
    }
  };
  useEffect(() => {
    if (isAddingActivitySuccess) {
      onClose(false);
      form.reset();
    }
  }, [form, isAddingActivitySuccess, onClose]);

  return (
    <DefaultDialog
      open={openDialog}
      onClose={() => onClose(false)}
      title="Add Activity"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-4">
          <FormTextarea
            control={form.control}
            name="activity"
            label="Activity"
          />
          <FormSelect
            control={form.control}
            name="parameterType"
            options={inputDataTypes.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            label="Value Type"
          />

          {dataTypeWatch == "select" && (
            <div>
              <div>
                {fields.map((field, index) => (
                  <FormField
                    control={form.control}
                    key={field.id}
                    name={`paramaterValues.${index}.value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={cn(index !== 0 && "sr-only")}>
                          Option
                        </FormLabel>
                        <div className="flex gap-2">
                          <div className="w-full">
                            <FormControl>
                              <Input
                                {...field}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault(); // Prevent form submission
                                    append({ value: "" }); // Add new field
                                    setTimeout(() => {
                                      // Focus on the new input field
                                      const newInput = document.querySelector(
                                        `input[name="paramaterValues.${fields.length}.value"]`
                                      );
                                      (newInput as HTMLInputElement)?.focus();
                                    }, 100);
                                  }
                                }}
                                required
                              />
                            </FormControl>
                            <FormMessage />
                          </div>
                          <Button
                            onClick={() => remove(index)}
                            variant={"outline"}
                            size={"icon"}
                            className="rounded-full text-red-500 hover:border-1 border-0 hover:ring-red-500 hover:text-red-600"
                          >
                            <X size={14} />
                          </Button>
                        </div>
                      </FormItem>
                    )}
                  />
                ))}
                {/* <div className="px-3">
              <Label className="text-red-500">
                {form?.getFieldState("paramaterValues").error?.root
                  ? form?.getFieldState("paramaterValues").error?.root?.message
                  : ""}
              </Label>
            </div> */}
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="mt-2"
                  onClick={() => append({ value: "" })}
                >
                  Add Option
                </Button>

                <FormField
                  control={form.control}
                  name="isMultiple"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">
                          Multiple Choice
                        </FormLabel>
                      </div>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          )}
          <div className="flex gap-3 justify-end mt-3">
            <CustomButton
              variant="danger"
              onClick={() => {
                onClose(false);
                form.reset();
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="default"
              //   isLoading={isAddingActivityLoading}
              type="submit"
              isLoading={isAddingActivityLoading}
            >
              Save
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
