import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api"
import { UserPerformanceReport } from "../model/UserPerfomanceReport";

export const reportAPi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserPerformanceReportById: builder.query<UserPerformanceReport[], QueryRequest>({
      query: (params) => ({
        url: `/otasks/GetUserPerformanceReport`,
        method: "GET",
        params: {...params.data}
      })
    })
  }),
});

export const {
    useGetUserPerformanceReportByIdQuery
} = reportAPi;
