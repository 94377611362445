import { useAuth } from '@/lib/auth'
import { UserType } from '../users/models/User'
import ClientDashboard from './client/pages/Dashboard'

const GlobalDashboard = () => {    
  const {session} = useAuth()
  return (
    session?.userInfo.userType === UserType.Staff ? <></> : <ClientDashboard/>
  )
}

export default GlobalDashboard
