import React, { SetStateAction, useEffect } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from '@/components/shared/Button/CustomButton';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useCreateRoleMutation, useGetPermissionsQuery } from '../api/user-api';
import { IPermission } from '../models/Role';
import { Notify } from '@/components/shared/Notification/notify';

const roleFormSchema = z.object({
  roleName: z.string().nonempty("Role Name is required"),
  description: z.string().nonempty("Description is required"),
  permissions: z.array(z.object({
    id: z.string(),
    permissionName: z.string(),
  })).nonempty("At least one permission is required"),
});

type RoleFormValues = {
  roleName: string;
  description: string;
  permissions: IPermission[];
};

const defaultValues: RoleFormValues = {
  roleName: "",
  description: "",
  permissions: [],
};

type AddRoleProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
};

const AddRole = ({ openForm, setOpenForm }: AddRoleProps) => {
  const [addRole, { isLoading, isSuccess }] = useCreateRoleMutation();
  const { data: permissionsList, isLoading: isPermissionFetchLoading } = useGetPermissionsQuery({
    url: "/permissions",
    method: "GET",
  });
  
  const form = useForm<RoleFormValues>({
    resolver: zodResolver(roleFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: RoleFormValues) => {
    await addRole({
      url: "/roles",
      method: "POST",
      data: {
        role: {
          roleName: data.roleName,
          description: data.description,
        },
        permissionIds: data.permissions.map((p) => p.id), // Extract permission IDs
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpenForm(false);
      Notify("success", "Role Added Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Add Role" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="p-2 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="roleName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Role Name</FormLabel>
                <FormControl>
                  <Input placeholder="Role Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input placeholder="Description" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="permissions"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Permissions</FormLabel>
                <FormControl>
                  <Autocomplete
                    multiple
                    options={permissionsList || []}
                    getOptionLabel={(option: IPermission) => option.permissionName}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={field.value} // Set current value for selected permissions
                    onChange={(_, newValue) => {
                      field.onChange(newValue); // Update form value with selected permissions
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Permissions"
                        placeholder="Permissions"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isPermissionFetchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <CustomButton disabled={!form.formState.isValid} isLoading={isLoading} className="lg:self-center" type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default AddRole;
