import { Label } from "@/components/ui/label";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { Check, ChevronDown } from "lucide-react";

interface Option {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
  labelOnTop?: boolean;
}

const AutoCompleteSelect = ({
  label,
  options,
  onChange,
  value,
  labelOnTop,
}: Props) => {
  const [openSector, setOpenSector] = useState<boolean>(false);
  return (
    <div className="w-full">
      {labelOnTop && (
        <Label className="text-left block text-sm font-medium text-gray-700">
          {label}
        </Label>
      )}
      <>
        <div className="">
          <Popover open={openSector} onOpenChange={setOpenSector}>
            <PopoverTrigger asChild className="">
              <Button
                variant="outline"
                role="combobox"
                className={cn(
                  "justify-between w-full",
                  !value && "text-muted-foreground"
                )}
              >
                {value
                  ? options?.find((option) => option.value === value)?.label
                  : `Select ${label ?? ""}`}
                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 z-9999 w-100">
              <Command className="w-full">
                <CommandInput placeholder={`Search ${label ?? ""}`} />
                <CommandList>
                  <CommandEmpty>No result found.</CommandEmpty>
                  <CommandGroup>
                    {options.map((option) => (
                      <CommandItem
                        value={option.label}
                        key={option.label}
                        onSelect={() => {
                          onChange(option.value);
                          setOpenSector(false);
                          // caseTypeIdRef.current?.focus();
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            option.value === value ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      </>
    </div>
  );
};

export default AutoCompleteSelect;
