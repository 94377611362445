import { AssignmentOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
// import AssignedToChips from "./AssignedToChips";
import { IAlert } from "../models/alert";
import { useNavigate } from "react-router-dom";

/* Component props */
type TaskCardProps = {
  task: IAlert;
  chipColor?: string; 
};



const borderColor = "#f0f0f0";
export const TaskCard = ({ task }: TaskCardProps): JSX.Element => {
  const navigate = useNavigate();

  // Handle event click to navigate to task detail page
  const handleSelectEvent = () => {
    if(task.taskId) {
      // Navigate to task detail page, using the taskId for navigation
      if(task.alertFrom === "GenericTask") {
        navigate(`/task/generic-task/${task.taskId}`);
      }
      else {
        navigate(`/task/operational-task/${task.taskId}`);
      }
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        cursor: "pointer",
        height: "100%",
        width: "auto",
        maxWidth: "600px",
        borderRadius: "20px",
        border: `1px solid ${borderColor}`,
        "&:hover": {
          boxShadow: "10px 7px 10px rgba(0, 0, 100, 0.2)",
          transform: "translate(5px, 5px)",
        },
      }}
      onClick={() => handleSelectEvent()}
    >
      <Box
        py={1.5}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${borderColor}`}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <AssignmentOutlined sx={{ fontSize: 20 }} />
          <Typography variant="body1" fontWeight={600}>
            {task.taskReference}
          </Typography>
        </Stack>

        {/* <Chip
          label={task.TaskTypeKey.toUpperCase()}
          sx={{
            borderRadius: "12px",
            fontWeight: 500,
            px: 1,
            backgroundColor: chipColor ?? "#f0f0f0",
          }}
        /> */}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <CardContent sx={{ p: 1, flex: 1, px: 3 }}>
          {/* <Divider /> */}
          <div className="flex gap-4">
            <div>
              <Typography variant="caption" color={"gray"}>
                Started Date
              </Typography>
              <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
                {new Date(task.time).toLocaleDateString()}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color={"gray"}>
                Due Date
              </Typography>
              <Typography variant="body1" fontSize={"14px"} fontWeight={500}>
                {new Date(task.dueDate).toLocaleDateString()}
              </Typography>
            </div>
          </div>{" "}
          <Divider sx={{ my: 1 }} />
          <Box>
            {/* <Typography variant="caption" color={"gray"}>
              Assignees
            </Typography> */}
            {/* <AssignedToChips assignedTo={task.AssignedTo ?? []} /> */}
          </Box>
          {task.message && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption" color={"gray"}>
                Note
              </Typography>
              <Typography variant="body1">{task.message}</Typography>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};