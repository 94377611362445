import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useAppDispatch } from "@/store/app-store-hooks";
import { setError } from "../store/error-slice";

export default function ErrorDialog({ error }: { error: any }) {
  const [toggleError, setToggleError] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setError(null));
  };

  console.log(error);

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      {/* Header Section with Icon and Main Message */}
      <DialogHeader className="bg-gradient-to-r from-red-500 to-pink-500 text-white rounded-t-lg p-6">
        <div className="flex flex-col justify-center items-center text-center gap-2">
          {/* <ErrorOutline size={64} className="text-white" /> */}
          <h4 className="text-white font-semibold">
            Oops! Something went wrong
          </h4>
        </div>
      </DialogHeader>

      {/* Main Content Section with Error Details */}
      <DialogContent className="p-6 text-center z-999999 overflow-scroll">
        <div className="mb-4">
          <p className="text-lg text-gray-800">{error?.message}</p>
        </div>

        {/* <div className="mt-4 bg-gray-50 p-4 rounded-lg shadow-sm">
          <p className="text-sm text-gray-600">
            {error?.code}
          </p>
        </div> */}

        {/* Toggle Error Details Button */}
        <div className="text-center mt-4">
          <button
            className="text-blue-600 underline font-medium hover:text-blue-800 transition-colors duration-200"
            onClick={() => setToggleError(!toggleError)}
          >
            {toggleError ? "Hide Error Details" : "Show Error Details"}
          </button>
        </div>

        {/* Collapsible Error Details Section */}
        {toggleError && (
          <div className="mt-4 p-4 bg-gray-100 rounded-lg overflow-auto max-h-40">
            <pre className="text-xs text-gray-600">
              {JSON.stringify(error, null, 2)}
            </pre>
          </div>
        )}
      </DialogContent>

      {/* Footer with Dismiss Button */}
      <DialogFooter className="border-t border-gray-200 mt-4 p-4 bg-gray-50 rounded-b-lg">
        <Button
          className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out"
          onClick={handleClose}
        >
          Dismiss
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
