import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IOrganization } from "@/features/organizations/model/Organization";

export const companyAPi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyProfile: builder.query<IOrganization, QueryRequest>({
      query: (params) => ({
        url: `/Companies/${params.data.id}`,
        method: "GET",
      }),
      providesTags: ["Organization"], // Provides a tag for caching
    }),
    editCompanyProfile: builder.mutation<string, QueryRequest>({
        query: (params) => ({
          url: `/Companies/${params.data.id}`,
          method: "PUT",
          data: params.data
        }),
        invalidatesTags: ["Organization"],
      }),
    SignupCompany: builder.mutation<string, QueryRequest>({
        query: (params) => ({
          url: `/Companies`,
          method: "POST",
          data: params.data
        }),
        invalidatesTags: ["Organization"],
      }),
    getCompanyLogo: builder.query<Blob, QueryRequest>({
        query: (params) => ({
            url: `/Companies/Logo/${params.data.id}`,
            method: "GET",
            // responseType: 'blob',
            responseHandler: async (response: any) => window.location.assign(window.URL.createObjectURL(await response.blob())),
            cache: "no-cache",
        }),
        // responseHandler: (response) => response.blob(),
    }),
  }),
});

export const {
  useGetCompanyProfileQuery,
  useGetCompanyLogoQuery,
  useEditCompanyProfileMutation,
  useSignupCompanyMutation
  
} = companyAPi;
