import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { useState } from "react";
import { Loader2 } from "lucide-react"; // For loading spinner

/* Component props */
type ConfirmModalProps = {
  setModalOpen: (value: boolean) => void;
  modalOpen: boolean;
  title: string;
  message: string | React.ReactNode;
  type: "error" | "success" | "info" | "warning";
  customOkText?: string;
  customCancelText?: string;
  onOk?: () => Promise<void> | void;
  onCancel?: () => void;
  loading?: boolean;
  hideCancelButton?: boolean;
};

export const ConfirmModal = (props: ConfirmModalProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOk = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      if (props.onOk) {
        await props.onOk();
        props.setModalOpen(false);
      }
    } catch (error) {
      console.error("Error handling OK:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = (): void => {
    if (props?.onCancel) {
      props.onCancel();
    }
    props.setModalOpen(false);
  };

  return (
    <Dialog open={props.modalOpen} onOpenChange={props.setModalOpen}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{props.message}</DialogDescription>
        <DialogFooter className="flex justify-end gap-4">
          {!props.hideCancelButton && (
            <Button
              variant="outline"
              onClick={handleCancel}
              disabled={isSubmitting || props.loading}
            >
              {props.customCancelText ? props.customCancelText : "Cancel"}
            </Button>
          )}

          <Button onClick={handleOk} disabled={isSubmitting || props.loading}>
            {isSubmitting || props.loading ? (
              <Loader2 className="animate-spin w-4 h-4 mr-2" />
            ) : (
              props.customOkText ? props.customOkText : "OK"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
