import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { Expense } from "../model/expense";

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    GetAllExpense: builder.query<Expense[], QueryRequest>({
      query: (params) => ({
        url: `/expenses`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["PaymentCollection"],
    }),
    addExpense: builder.mutation<string, FormData>({
      query: (params) => ({
        url: `/expenses`,
        method: "POST",
        data: params,
      }),
      invalidatesTags: ["PaymentCollection"],
    }),
    updateTaskPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    voidPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/VoidPayment`,
        method: "PUT",
        params: {...params.data},
      }),
      invalidatesTags: ["PaymentCollection"],
    }),
  }),
});

export const {
  useGetAllExpenseQuery,
  useAddExpenseMutation
} = taskApi;
