import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { IAlertNotification } from "@/features/task/models/Task";
import { useGetAllAlertsByTaskQuery } from "@/features/task/api/task-query";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useCompleteAlertMutation } from "@/features/alert/api/alert-api";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import Caption from "@/components/shared/customShadCN/Caption";
import Chip from "@/components/shared/customShadCN/Chip";

type Props = {
  taskId: string;
};

export const GenericTaskAlertNotificationList = ({ taskId }: Props) => {
  const taskListColumns: MRT_ColumnDef<IAlertNotification>[] = [
    {
      accessorKey: "message",
      header: "Message",
    },

    {
      accessorKey: "dueDate",
      header: "Due Date",
      Cell: ({ row }) => {
        return new Date(row.original.dueDate).toLocaleDateString();
      },
    },
    {
      accessorKey: "users",
      header: "Assigned users",
      Cell({
        row: {
          original: { users },
        },
      }) {
        if (users && users?.length > 1) {
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex flex-row gap-1 items-center flex-nowrap">
                    <Chip label={users?.[0] ?? ""} />
                    <Caption className="w-8" text={`+ ${users?.length - 1}`} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {users?.map((user: string, index: number) => (
                    <div key={index}>
                      {user}
                    </div>
                  ))}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        } else if (users && users?.length !== 0) {
          return (
            <div className="flex gap-1 items-center">
              {users?.map((_, index: number) => (
                <Chip
                  key={index}
                  label={users[0]}
                />
              ))}
            </div>
          );
        }
      },
    },
    {
      accessorKey: "createdBy",
      header: "Created by",
    },
    {
      accessorKey: "alertTypeName",
      header: "Alert Type",
    },
  ];
  const { data: alerts = [] } = useGetAllAlertsByTaskQuery(taskId);

  const [openCompleteAlert, setOpenCompleteAlert] = useState<boolean>(false);

  const [selectedAlert, setSelectedAlert] = useState<IAlertNotification | null>(
    null
  );

  const [
    completeAlert,
    { isLoading: isCompleteAlertLoading, isSuccess: isCompleteAlertSuccess },
  ] = useCompleteAlertMutation();
  const handleCompleteAlert = async () => {
    if (selectedAlert) await completeAlert({ data: { id: selectedAlert?.id } });
  };
  useEffect(() => {
    if (isCompleteAlertSuccess) {
      setOpenCompleteAlert(false);
    }
  }, [isCompleteAlertSuccess]);

  return (
    <>
      <MaterialReactTable
        columns={taskListColumns}
        data={alerts}
        enablePagination
        manualPagination
        rowCount={alerts?.length} // Total number of records (if server returns it)
        state={{ density: "compact" }}
        manualFiltering
        enableColumnActions={false}
        enableRowActions={true}
        positionActionsColumn="last"
        renderRowActions={({ row }) => {
          if (row.original.isCompleted) return null;
          return (
            <div>
              <Button
                onClick={() => {
                  setSelectedAlert(row.original);
                  setOpenCompleteAlert(true);
                }}
                size={"sm"}
                variant={"outline"}
                className="text-[#00b140] border-[#00b140] hover:text-[#00b140] my-0.5"
              >
                <Check className="w-8 mr-2" />
                Complete
              </Button>
            </div>
          );
        }}
      />
      {selectedAlert && openCompleteAlert && (
        <ConfirmModal
          title="Complete Alert"
          message="Are you sure you want to complete this alert?"
          modalOpen={openCompleteAlert}
          setModalOpen={() => setOpenCompleteAlert(false)}
          type="success"
          onCancel={() => setOpenCompleteAlert(false)}
          onOk={handleCompleteAlert}
          loading={isCompleteAlertLoading}
        />
      )}
    </>
  );
};
