import { TaskClientCard } from "@/features/task/components/TaskClientCard";
import WorkflowCancelStepper from "@/features/task/components/WorkflowCancelStepper";
import { UserType } from "@/model/SideBarMenu";
// import { ReactivateTaskDialog } from "@/features/task/components/ReactivateTaskDialog";
import { SuspendTaskDialog } from "@/features/task/components/SuspendTaskDialog";
import { ReviseTaskDialog } from "@/features/task/components/ReviseTaskDialog";
// import { BackTaskDialog } from "@/features/task/components/BackTaskDialog";
import { CancelTaskDialog } from "@/features/task/components/CancelTaskDialog";
import { SalesWorkflowStepper } from "./SalesWorkflowStepper";
import { ITaskDetail } from "@/features/task/models/Task";
import { useAuth } from "@/lib/auth";
import { ReactivateTaskDialog } from "@/features/task/components/ReactivateTaskDialog";

type Props = {
  //   workflowId: string;
  //   clientId: string;
  taskDetail: ITaskDetail;
};

export const PipelineWorkflowDetailTab = ({ taskDetail }: Props) => {
  //   const [
  //     getWorkFlowByCase,
  //     { data: workflows, isLoading: isFetchingWorkFlowLoading },
  //   ] = useLazyGetSalesWorkFlowsByCaseTypeIdQuery();

  const { session } = useAuth();
  //   useEffect(() => {
  //     if (taskDetail.caseType.id) {
  //       getWorkFlowByCase({ data: { caseTypeId: taskDetail.caseType.id } });
  //     }
  //   }, [getWorkFlowByCase, taskDetail.caseType.id]);

  //   if (isFetchingWorkFlowLoading) return <LoadingPage />;

  return (
    <div>
      <div className="rounded-xl bg-red-400">
        {taskDetail?.client && (
          <TaskClientCard
            client={taskDetail?.client}
            caseType={taskDetail.caseType}
          />
        )}
      </div>
      <div className="">
        {taskDetail.task.taskStatus === "Canceled" ? (
          <WorkflowCancelStepper />
        ) : (
          <SalesWorkflowStepper taskDetail={taskDetail} />
        )}
      </div>
      <>
        {taskDetail.task.taskStatus == "Suspended" && (
          <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
            <div className="flex flex-col p-8">
              <div className="text-2xl font-bold   text-[#374151] pb-6">
                Suspended
              </div>
              <div className=" text-lg   text-black">
                Current Workflow is suspended please reactivate or cancel to
                continue
              </div>
              <div className="flex justify-end pt-6"></div>
            </div>
          </div>
        )}
        {taskDetail.task.taskStatus == "BackToRevision" && (
          <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
            <div className="flex flex-col p-8">
              <div className="text-2xl font-bold   text-[#374151] pb-6">
                Back To Revision
              </div>
              <div className=" text-lg   text-black">
                Current Workflow is Back To Revision please revise
              </div>
              <div className="flex justify-end pt-6"></div>
            </div>
          </div>
        )}
        {session?.userInfo.userType !== UserType.Client &&
          taskDetail.task.taskStatus !== "Finished" && (
            <div className="flex gap-3">
              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" &&
                taskDetail.task.taskStatus !== "BackToRevision" &&
                (taskDetail.task.taskStatus === "Suspended" ? (
                  <ReactivateTaskDialog taskId={taskDetail.task.id} isSalesWf />
                ) : (
                  <SuspendTaskDialog taskId={taskDetail.task.id} isSalesWf />
                ))}

              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" &&
                taskDetail.task.taskStatus !== "Suspended" &&
                (taskDetail.task.taskStatus === "BackToRevision" ? (
                  <ReviseTaskDialog taskId={taskDetail.task.id} isSalesWf />
                ) : (
                  //   <BackTaskDialog taskId={taskDetail.task.id} isSalesWf />
                  <></>
                ))}

              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" && (
                  <CancelTaskDialog taskId={taskDetail.task.id} isSalesWf />
                )}
            </div>
          )}
      </>
    </div>
  );
};

// 2c89f39e-f01f-4199-91a2-61e070d3cb5d"
