import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link } from "react-router-dom";
import background from "./loginbg.jpg";
import logo from "./Shape.png";
import { useAuth } from "../hooks";
import CustomButton from "@/components/shared/Button/CustomButton";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PasswordInput } from "@/components/ui/passwordInput";

// Define your Zod schema for validation
const loginSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  password: z.string().min(1, { message: "Password is required" }),
});

type LoginFormInputs = z.infer<typeof loginSchema>;

export function LoginPage() {
  const { submitLoginRequest, selectIsLoading } = useAuth();

  // Use react-hook-form with zod validation
  const form = useForm<LoginFormInputs>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (data: LoginFormInputs) => {
    await submitLoginRequest({
      Username: data.username,
      Password: data.password,
    });
    // Add navigation logic if needed, e.g., navigate to a different page after successful login
  };

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="flex justify-center h-screen items-center bg-login bg-cover bg-center"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card className="w-full max-w-md"> {/* Added max-w-md for better responsiveness */}
            <div className="flex items-center justify-center mt-8 gap-4">
              <img src={logo} alt="Logo" className="w-12 h-12" /> {/* Added alt and size */}
              <p className="text-5xl font-bold text-primary">UCM</p>
            </div>
            <CardHeader>
              <CardTitle className="text-center text-3xl font-medium mb-2">
                Login
              </CardTitle>
              <CardDescription>
                Enter your credentials below to login to your account.
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input
                        id="username"
                        type="text"
                        placeholder="username"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        id="password"
                        {...field}
                        placeholder="password"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="mt-4">
              <CustomButton
                isLoading={selectIsLoading}
                className="w-full"
                type="submit"
              >
                Sign in
              </CustomButton>
            </CardFooter>
            {/* Sign-Up Option */}
            <div className="mb-2 text-center">
              <span className="text-sm text-gray-600">Don't have an account? </span>
              <Link to={"/signup"}>
                <button
                  type="button"
                  className="text-sm text-blue-500 hover:underline"
                >
                  Sign Up
                </button>
              </Link>
            </div>
          </Card>
        </form>
      </Form>
    </div>
  );
}

export default LoginPage;
