import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { PhoneInput } from '@/components/shared/PhoneInput/phone-input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'
import { PasswordInput } from '@/components/ui/passwordInput'
import { useCreateUserMutation } from '../api/user-api'
import CustomButton from '@/components/shared/Button/CustomButton'
import { SetStateAction, useEffect } from 'react'
import { Notify } from '@/components/shared/Notification/notify'
import { FullScreenDialog } from '@/components/shared/DefaultDailog/FullPageDialog'

const userFormSchema = z.object({
    fullName: z.string().nonempty("Full Name is required"),
    userName: z.string().nonempty("User Name is required"),
    email: z
        .string()
        .email("Invalid email address")
        .nonempty("Email is required"),
    phoneNumber: z
        .string()
        .nonempty("Phone number is required")
        .refine(isValidPhoneNumber, {
            message: "Invalid phone number",
        }),
    password: z
        .string()
        .nonempty("Password is required")
        .min(8, "Password must be at least 8 characters long"),
    confirmPassword: z
        .string()
        .nonempty("Confirm Password is required")
        .min(8, "Confirm Password must be at least 8 characters long"),
})
.refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
});

type UserFormValues = z.infer<typeof userFormSchema>;

const defaultValues: Partial<UserFormValues> = {
    fullName:"",
    userName:"",
    email:"",
    phoneNumber:"",
    password:"",
    confirmPassword:"",
};

type CreateUserProps = {
    openForm: boolean;
    setOpenForm: React.Dispatch<SetStateAction<boolean>>;
}

const CreateUser = ({openForm, setOpenForm}: CreateUserProps) => {
    const [createUser, { isLoading, isSuccess }] = useCreateUserMutation()

    const onSubmit = async (data: UserFormValues) => {
        await createUser({
            url: "/users/Register",
            method: "POST",
            data: data
        })
    }

    const form = useForm<UserFormValues>({
        resolver: zodResolver(userFormSchema),
        mode: "onChange",
        defaultValues,
    });

    useEffect(() => {
        if(isSuccess) {
            form.reset()
            setOpenForm(false)
            Notify('success', "User Created Successfully!")
        }
    }, [isSuccess])

    return (
        <FullScreenDialog title='Add User' open={openForm} onClose={() => setOpenForm(false)}>
            <Form {...form}>
                <form className='space-y-8 flex flex-col gap-4 px-2 lg:px-20 py-4' onSubmit={form.handleSubmit((data) => onSubmit(data))}>
                    <div className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4'>
                        <FormField
                            control={form.control}
                            name="fullName"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>Full Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Full Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="userName"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>User Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="User Name"/>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input type='email' {...field} placeholder="Email"/>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="phoneNumber"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>Phone Number</FormLabel>
                                    <FormControl>
                                        <PhoneInput {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput {...field} placeholder='Enter Password'/>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <FormItem className='flex-1'>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput {...field} placeholder='Enter Password'/>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <CustomButton disabled={isLoading || !form.formState.isValid} isLoading={isLoading} className='lg:self-center' type='submit'>SUBMIT</CustomButton>
                </form>
            </Form>
        </FullScreenDialog>
    )
}

export default CreateUser
