import { Textarea } from "@/components/ui/textarea";
import {
  useActivateGenericTaskMutation,
  useCancelGenericTaskMutation,
  useCloseGenericTaskMutation,
  useSuspendGenericTaskMutation,
} from "../api/generic-task-command";
import { useEffect, useState } from "react";
import CustomButton from "@/components/shared/Button/CustomButton";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";

type Props = {
  taskId: string;
  type: "Cancel" | "Close" | "Suspend" | "Activate";
  open: boolean;
  onClose: () => void;
};

export const ChangeStatusOfTaskDialog = ({
  taskId,
  type,
  open,
  onClose,
}: Props) => {
  const [
    cancelGenericTask,
    {
      isLoading: cancelGenericTaskIsLoading,
      isSuccess: cancelGenericTaskIsSuccess,
    },
  ] = useCancelGenericTaskMutation();

  const [
    closeGenericTask,
    {
      isLoading: closeGenericTaskIsLoading,
      isSuccess: closeGenericTaskIsSuccess,
    },
  ] = useCloseGenericTaskMutation();

  const [
    suspendGenericTask,
    {
      isLoading: suspendGenericTaskIsLoading,
      isSuccess: suspendGenericTaskIsSuccess,
    },
  ] = useSuspendGenericTaskMutation();

  const [
    activateGenericTask,
    {
      isLoading: activateGenericTaskIsLoading,
      isSuccess: activateGenericTaskIsSuccess,
    },
  ] = useActivateGenericTaskMutation();
  const [note, setNote] = useState("");
  const handleCancelWorkflow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      data: {
        taskId: taskId,
        note: note,
      },
    };
    if (type == "Cancel") {
      await cancelGenericTask(payload);
    } else if (type == "Close") {
      await closeGenericTask(payload);
    } else if (type == "Suspend") {
      await suspendGenericTask(payload);
    } else if (type == "Activate") {
      await activateGenericTask(payload);
    }
  };

  useEffect(() => {
    const success =
      cancelGenericTaskIsSuccess ||
      closeGenericTaskIsSuccess ||
      suspendGenericTaskIsSuccess ||
      activateGenericTaskIsSuccess;

    if (success) {
      onClose();
    }
  }, [
    cancelGenericTaskIsSuccess,
    closeGenericTaskIsSuccess,
    onClose,
    suspendGenericTaskIsSuccess,
    activateGenericTaskIsSuccess,
  ]);

  return (
    <DefaultDialog title={`${type} Workflow`} open={open} onClose={onClose}>
      <form onSubmit={handleCancelWorkflow}>
        <div className="mt-3">
          <span className="m-1">Note</span>
          <Textarea
            placeholder="Add Note"
            className="mt-2"
            required
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div className="flex justify-center mt-3">
            <CustomButton
              className="w-50 items-center"
              isLoading={
                cancelGenericTaskIsLoading ||
                closeGenericTaskIsLoading ||
                suspendGenericTaskIsLoading ||
                activateGenericTaskIsLoading
              }
            >
              Submit
            </CustomButton>
          </div>
        </div>
      </form>
    </DefaultDialog>
  );
};
