import * as React from "react";
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  TableOptions,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { DataTableViewOptions } from "@/components/ui/data-table/DataTableViewOptions";
import { DataTableColumnHeader } from "@/components/ui/data-table/DataTableColumnHeader";
import { DataTablePagination } from "@/components/ui/data-table/DataTablePagination";
// import { CircularProgress } from "@mui/material"; // MUI spinner

interface DataTableProps<TData> {
  columns: TableOptions<TData>["columns"];
  data: TData[];
  pageCount?: number; // Total number of pages (optional for pagination)
  enableSorting?: boolean;
  enableColumnFilters?: boolean;
  enablePagination?: boolean;
  enableRowSelection?: boolean;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  onPaginationChange?: (pagination: PaginationState) => void; // Triggered when pagination changes
  globalFilterFn?: TableOptions<TData>["globalFilterFn"];
}

export function DataTable<TData>({
  columns,
  data,
  pageCount = 0, // Default to 0 pages
  enableSorting = false, // Sorting is disabled by default
  enableColumnFilters = false, // Column filters are disabled by default
  enablePagination = false, // Pagination is disabled by default
  enableRowSelection = false, // Row selection is disabled by default
  loading = false,
  success = true,
  error = false,
  onPaginationChange,
  globalFilterFn = "auto",
}: DataTableProps<TData>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10, // Default page size
  });

  const table = useReactTable({
    data,
    columns,
    pageCount, // Needed for server-side pagination
    manualPagination: enablePagination, // Enables server-side pagination if allowed
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    getFilteredRowModel: enableColumnFilters ? getFilteredRowModel() : undefined,
    onSortingChange: enableSorting ? setSorting : undefined,
    onColumnFiltersChange: enableColumnFilters ? setColumnFilters : undefined,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: enableRowSelection ? setRowSelection : undefined,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
      pagination, // Pagination state
    },
    onPaginationChange: (updater) => {
      const newPagination = updater instanceof Function ? updater(pagination) : updater;
      setPagination(newPagination);
      if (onPaginationChange) {
        onPaginationChange(newPagination); // Notify parent about pagination change
      }
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn,
  });

  return (
    <div className="">
      {/* Toolbar */}
      <div className="flex items-center justify-between pb-4">
        <Button variant="outline" size="sm">
          Filter
        </Button>
        <DataTableViewOptions table={table} />
      </div>

      {/* Table */}
      <div className="rounded-md border">
        {loading ? (
          <div className="p-4 flex justify-center items-center">
            {/* Spinner during loading */}
            Loading
          </div>
        ) : error ? (
          <div className="p-4 text-center text-red-600">
            {/* Error message */}
            Error loading data
          </div>
        ) : success && data.length === 0 ? (
          <div className="p-4 text-center">
            {/* No results */}
            No results found
          </div>
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : (
                        <DataTableColumnHeader
                          column={header.column}
                          title={header.column.columnDef.header as string}
                        />
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {/* Pagination */}
      {enablePagination && <DataTablePagination table={table} />}
    </div>
  );
}
