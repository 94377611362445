// import { caseListColumns } from "./CaseListColumns";
import { ICaseWithSector } from "../models/Case";
import { DataTable } from "@/components/shared/DataTable/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { UpdateCaseDialog } from "./UpdateCaseDialog";
import { IconButton } from "@mui/material";
import { useDeleteCaseMutation } from "../../api/work-flow-command";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { CopyCaseDialog } from "./CopyCase";

type Props = {
  cases: ICaseWithSector[];
};

export function CaseList({ cases }: Props) {
  const [selectedCase, setSelectedCase] = useState<ICaseWithSector | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);

  const caseListColumns: ColumnDef<ICaseWithSector>[] = [
    {
      accessorKey: "type",
      header: "Case",
    },
    {
      header: "Total Tasks",
      accessorKey: "totalTaskCount",
    },
    {
      header: "Sector",
      accessorFn: (row) => row?.sector?.sectorName,
    },
    {
      header: "Standard Days",
      accessorKey: "standardDays",
    },
    {
      accessorKey: "isActive",
      header: "Status",
      cell: ({ row }) => {
        return (
          <Badge variant={row.original.isActive ? "default" : "destructive"}>
            {row.original.isActive ? "Active" : "In Active"}
          </Badge>
        );
      },
    },

    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div className="flex gap-4 items-center">
            <Button
              variant={"outline"}
              onClick={() => {
                setOpenCopyDialog(true);
                setSelectedCase(row.original);
              }}
            >
              <Copy size={14} className="mr-2" />
              Copy
            </Button>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setOpenDialog(true);
                setSelectedCase(row.original);
              }}
            >
              <Pencil size={14} className="mr-2" />
              Edit
            </Button>
            <IconButton
              onClick={() => {
                setSelectedCase(row.original);
                setOpenConfirmModal(true);
              }}
            >
              <Trash2 color="red" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [
    deleteCase,
    { isLoading: isCaseDeletingLoading, isSuccess: isCaseDeletingSuccess },
  ] = useDeleteCaseMutation();

  const handleDeleteCase = async () => {
    if (selectedCase) {
      try {
        await deleteCase(selectedCase.id);
        setOpenConfirmModal(false);
      } catch (error) {
        console.error("Failed to delete parameter", error);
      }
    }
  };
  useEffect(() => {
    if (isCaseDeletingSuccess) {
      setOpenConfirmModal(false);
      setSelectedCase(null);
    }
  }, [isCaseDeletingSuccess]);

  return (
    <div className="rounded-md border">
      <DataTable<ICaseWithSector> columns={caseListColumns} data={cases} />
      {selectedCase && (
        <UpdateCaseDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSelectedCase(null);
          }}
          selectedCase={selectedCase}
        />
      )}

      {openCopyDialog && selectedCase && (
        <CopyCaseDialog
          openDialog={openCopyDialog}
          setOpenDialog={(value: boolean) => setOpenCopyDialog(value)}
          targetCase={selectedCase}
        />
      )}
      {openConfirmModal && (
        <ConfirmModal
          message="Are you Sure you want to delete?"
          title="Delete Case"
          modalOpen={openConfirmModal}
          setModalOpen={() => {}}
          type="error"
          customOkText="Delete"
          onCancel={() => {
            setOpenConfirmModal(false);
            setSelectedCase(null);
          }}
          onOk={() => handleDeleteCase()}
          loading={isCaseDeletingLoading}
        />
      )}
    </div>
  );
}
