import { Button } from "@/components/ui/button";
import { Check, ChevronDown } from "lucide-react";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect, useRef, useState } from "react";
import { useCreateCaseMutation, useGetSectorsQuery } from "../api/case-api";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { useGetCaseTypesHasWorkFlowQuery } from "../api/case-api"; // Import case list for copying
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const caseFormSchema = z
  .object({
    caseName: z.string().nonempty("Case name is required"), // Ensure Case Name is required

    caseDescription: z.string().optional(), // Optional

    sectorId: z.string().optional(), // Ensure Sector is required

    standardDays: z
      .string()
      .nonempty("Standard days are required")
      .transform((v) => Number(v) || 1)
      .refine((val) => val > 0, {
        message: "Standard days must be greater than 0",
      }), // Ensure standard days are greater than 0

    copyFromAnotherCase: z.boolean().optional(), // Optional checkbox field

    sourceCaseType: z.string().optional(), // This field will be conditionally required
  })
  .refine(
    (data) => !data.copyFromAnotherCase || !!data.sourceCaseType, // Conditional validation: sourceCaseType required if copyFromAnotherCase is true
    {
      path: ["sourceCaseType"],
      message: "Source case is required when copying from another case.",
    }
  );

type CaseFormValues = z.infer<typeof caseFormSchema>;

type AddCaseDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
};

export const AddCaseDialog = ({
  openDialog,
  setOpenDialog,
}: AddCaseDialogProps) => {
  const form = useForm<CaseFormValues>({
    resolver: zodResolver(caseFormSchema),
    mode: "onChange",
  });

  const [
    createCase,
    { isLoading: isLoadingSectorSuccess, isSuccess: isCreatingSectorSuccess },
  ] = useCreateCaseMutation();

  const onSubmit = async () => {
    const body =
      form.getValues().copyFromAnotherCase && form.getValues().sourceCaseType
        ? {
            caseDescription: form.getValues().caseDescription,
            type: form.getValues().caseName,
            sectorId: form.getValues().sectorId,
            standardDays: form.getValues().standardDays,
            sourceCaseTypeId: form.getValues().sourceCaseType,
          }
        : {
            caseDescription: form.getValues().caseDescription,
            type: form.getValues().caseName,
            sectorId: form.getValues().sectorId,
            standardDays: form.getValues().standardDays,
          };

    await createCase({
      url: "/caseTypes",
      method: "POST",
      data: { ...body },
    });
  };

  useEffect(() => {
    if (isCreatingSectorSuccess) {
      setOpenDialog(false);
      form.reset();
      Notify("success", "Case Added Successfully!");
    }
  }, [isCreatingSectorSuccess]);

  const [openSector, setOpenSector] = useState<boolean>(false);

  const { data: sectors = [] } = useGetSectorsQuery({
    url: "/sectors",
    method: "GET",
  });
  const sectorRef = useRef<HTMLButtonElement>(null);
  const caseNameRef = useRef<HTMLInputElement>(null);

  // Get cases for copying
  const { data: caseTypesHasWorkflow = [] } = useGetCaseTypesHasWorkFlowQuery();

  return (
    <DefaultDialog
      open={openDialog}
      title="Add Case"
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          {/* Sector selection */}
          <FormField
            control={form.control}
            name="sectorId"
            render={({ field }) => (
              <FormItem className="flex flex-col w-full">
                <FormLabel>Sector</FormLabel>
                <Popover open={openSector} onOpenChange={setOpenSector}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                          "justify-between",
                          !field.value && "text-muted-foreground"
                        )}
                        ref={sectorRef}
                      >
                        {field.value
                          ? sectors.find((sector) => sector.id === field.value)
                              ?.sectorName
                          : "Select Sector"}
                        <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0 z-9999">
                    <Command>
                      <CommandInput placeholder="Search Sector..." />
                      <CommandList>
                        <CommandEmpty>No Sector found.</CommandEmpty>
                        <CommandGroup>
                          {sectors.map((sector) => (
                            <CommandItem
                              value={sector.id}
                              key={sector.id}
                              onSelect={() => {
                                form.setValue("sectorId", sector.id);
                                setOpenSector(false);
                                caseNameRef.current?.focus();
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  sector.id === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {sector.sectorName}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Case Name */}
          <FormField
            control={form.control}
            name="caseName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Case Name</FormLabel>
                <FormControl>
                  <Input placeholder="Case Name" {...field} ref={caseNameRef} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Standard Days */}
          <FormField
            control={form.control}
            name="standardDays"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Standard Days</FormLabel>
                <FormControl>
                  <Input placeholder="Standard Days" type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Case Description */}
          <FormField
            control={form.control}
            name="caseDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Copy from another case */}
          {/* <FormField
          control={form.control}
          name="copyFromAnotherCase"
          render={({ field }) => (
            <FormItem className="flex items-center">
              <FormControl>
                  <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                  />
              </FormControl>
              <FormLabel className="ml-2">Copy from another case</FormLabel>
              <FormMessage />
            </FormItem>
          )}
          /> */}
          <FormField
            control={form.control}
            name="copyFromAnotherCase"
            render={({ field }) => (
              <FormItem className="">
                <div className="flex items-center space-x-2">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Copy from another case</FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Show source case dropdown if the checkbox is checked */}
          {form.watch("copyFromAnotherCase") && (
            <FormField
              control={form.control}
              name="sourceCaseType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source Case</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        form.setValue("sourceCaseType", value);
                      }}
                      value={field.value ?? ""}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Source Case" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {caseTypesHasWorkflow.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <div className="flex items-center">
            <CustomButton
              disabled={!form.formState.isValid}
              isLoading={isLoadingSectorSuccess}
              type="submit"
              className="w-full mx-auto"
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
