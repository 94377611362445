import React, { SetStateAction, useEffect, useState } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import CustomButton from '@/components/shared/Button/CustomButton';
import { useUpdateUserClientMutation } from '../api/user-api'; // Update to get roles
import { Notify } from '@/components/shared/Notification/notify';
import { IUser } from '../models/User';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useSearchCustomersQuery } from '@/features/customers/api/customer-api';
import { ICustomer } from '@/features/customers/models/Customer';

// Zod schema expecting an array of client objects with id and clientName
const userFormSchema = z.object({
  clients: z.array(z.object({
    id: z.string(),
    clientName: z.string(),
  })).refine((clients) => clients.length > 0, {
    message: "At least one client is required",
  }),
});

type UserClientFormValues = z.infer<typeof userFormSchema>;

type EditUserClientProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  user: IUser;
};

const EditUserClient = ({ openForm, setOpenForm, user }: EditUserClientProps) => {

    const [inputValue, setInputValue] = useState<string>(''); // State to control the input value
  const [UpdateUserClient, {isLoading: isSubmitting, isSuccess }] = useUpdateUserClientMutation();
  const { data: clientList, isLoading: isRolesLoading, refetch } = useSearchCustomersQuery({data: {search:inputValue}}); // Default to an empty array if undefined

  // Default selected clients from the user
  const defaultValues: UserClientFormValues = {
    clients: user.clients?.map(client => ({
      id: client.clientId,
      clientName: client.clientName,
    })) ?? [],
  };

  const form = useForm<UserClientFormValues>({
    resolver: zodResolver(userFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: UserClientFormValues) => {
    await UpdateUserClient({
      data: {
        userId: user.id,
        clients: data.clients.map(client => client.id), // Send only client IDs to the server
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "Client(s) Assigned Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Assign Client" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="z-50 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="clients"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Select Client</FormLabel>
                <FormControl>
                  <Autocomplete
                    multiple
                    options={clientList?.clients || []} // Ensure it's an array
                    getOptionLabel={(option: ICustomer) => option.clientName} // Display client name
                    inputValue={inputValue} // Controlled input value
                    onInputChange={(_, newInputValue) => {
                      setInputValue(newInputValue); // Update the input value in the state
                      refetch() // Fetch clients dynamically as user types
                    }}
                    value={
                      clientList?.clients.length && field.value.length
                        ? clientList.clients.filter((client: ICustomer) => field.value.some(selectedClient => selectedClient.id === client.id))
                        : []
                    } // Ensure the value is always an array of ICustomer[]
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Compare based on id
                    onChange={(_, selectedClients: ICustomer[]) => {
                      field.onChange(selectedClients); // Update with full ICustomer objects
                    }}
                    loading={isRolesLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search and select clients"
                        error={!!form.formState.errors.clients}
                        helperText={form.formState.errors.clients?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isRolesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <CustomButton isLoading={isSubmitting} className="lg:self-center" type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditUserClient;
