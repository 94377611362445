import { store } from "../../store/app-store";
import axios from "axios";
import { useEffect } from "react";
import React from "react";
import { setError } from "../alert/store/error-slice";

/* component props */
type AxiosWrapperProps = {
  children: React.ReactNode;
};
export const AxiosWrapper = (props: AxiosWrapperProps) => {
  /* useEffect hooks */
  useEffect(() => {
    if (store) {
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        async function (error) {
          store.dispatch(
            setError({
              message: error.response.data.response,
              code: error.response.data.status,
              response: error.response,
            }),
          );
          return await Promise.reject(error);
        },
      );
    }
  }, [{ store }]);

  return <>{props?.children}</>;
};
