import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IWorkFlow, IWorkFlowParameter } from "../Case/models/workflow";
import { ISalesWorkFlow } from "../models/sales-wf-case";

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkFlowByCaseTypeId: builder.query<IWorkFlow[], QueryRequest>({
      query: (params) => ({
        url: "/WorkFlows/GetWorkFlowsByCaseTypeId",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
    getSalesWorkFlowsByCaseTypeId: builder.query<
      ISalesWorkFlow[],
      QueryRequest
    >({
      query: (params) => ({
        url: "/salesWorkFlows/GetSalesWorkFlowsByCaseTypeId",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
    getWorkFlowParametersByWorkFlowId: builder.query<
      IWorkFlowParameter[],
      QueryRequest
    >({
      query: (params) => ({
        url: "/workFlowParameters/workFlowId",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
    getSalesWorkFlowParametersByWorkFlowId: builder.query<
      IWorkFlowParameter[],
      QueryRequest
    >({
      query: (params) => ({
        url: "/salesWorkFlowParameters/workflowId",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
    getActiveSalesWorkFlow: builder.query<ISalesWorkFlow[], QueryRequest>({
      query: (params) => ({
        url: "/salesWorkFlows/GetActiveSalesWorkFlows",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
    getActiveWorkFlow: builder.query<IWorkFlow[], QueryRequest>({
      query: (params) => ({
        url: "/workFlows/GetActiveWorkFlows",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Cases"],
    }),
  }),
});

export const {
  useGetWorkFlowByCaseTypeIdQuery,
  useLazyGetWorkFlowByCaseTypeIdQuery,
  useLazyGetWorkFlowParametersByWorkFlowIdQuery,
  useGetSalesWorkFlowsByCaseTypeIdQuery,
  useLazyGetSalesWorkFlowsByCaseTypeIdQuery,
  useLazyGetSalesWorkFlowParametersByWorkFlowIdQuery,
  useGetActiveSalesWorkFlowQuery,
  useGetActiveWorkFlowQuery,
} = userApi;
