import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <h2 className="text-2xl font-bold text-gray-800">Page Not Found</h2>
      <div className="mt-5">
        <NavLink to="/" className="text-blue-500 underline">
          <Button>
            <ArrowLeft size={18} className="m-3" />
            Go back to Home
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
