import { appApi } from "@/store/app-api";
import { IEmail, ISMS } from "../models/sms";
import { QueryRequest } from "@/model/query-request";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSentSMS: builder.query<ISMS[], void>({
      query: () => ({
        url: "/Notification/SentSms",
        method: "GET",
      }),
      providesTags: ["SMS"], // Provides a tag for caching
    }),
    getSentEmail: builder.query<IEmail[], void>({
      query: () => ({
        url: "/Notification/SentEmail",
        method: "GET",
      }),
      providesTags: ["Email"], // Provides a tag for caching
    }),
    sendBulkSMS: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/Notification/SendBulkSms",
        method: "POST",
        data: params.data
      }),
      invalidatesTags: ["SMS"]
    })
  }),
});

export const {
  useGetSentSMSQuery,
  useSendBulkSMSMutation,
  useGetSentEmailQuery
} = customerApi;
