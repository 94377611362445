import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { ITaskWithClientCase } from "../models/Task";
import { AddTaskDialog } from "../components/AddTaskDialog";
import { useEffect, useState } from "react";
import { useLazySearchTasksQuery } from "../api/task-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/lib/auth";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";

const taskListColumns: MRT_ColumnDef<ITaskWithClientCase>[] = [
  {
    accessorKey: "taskReference",
    header: "Reference Number",
  },
  {
    accessorKey: "client.clientName",
    header: "Customer Name",
  },
  {
    accessorKey: "caseType.caseDescription",
    header: "Case",
  },
  {
    accessorKey: "startDate",
    header: "StartDate",
    Cell: ({ row }) => {
      return new Date(row.original.startDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
  },
  // {
  //   accessorKey: "taskStatus",
  //   header: "Task Status",
  // },
  {
    accessorKey: "currentStatus",
    header: "Status",
  },
  {
    accessorKey: "completionRate",
    header: "Progress",
    Cell: ({ row }) => {
      return (
        <>
          <CircularPercentageIndicator value={row.original.completionRate} />
        </>
      );
    },
  },

  // {
  //   id: "actions",
  //   header: "Action",
  //   enableHiding: false,
  //   Cell: ({ row }) => {
  //     return (
  //       <>
  //         <NavLink
  //           to={`/task/task-list/${row.original.id}`}
  //           className={"underline text-blue-700"}
  //         >
  //           Detail
  //         </NavLink>
  //       </>
  //     );
  //   },
  // },
];

// const statusOption: string[] = [
//   "None",
//   "Started",
//   "Ongoing",
//   "NearingCompletion",
//   "Finished",
//   "Canceled",
//   "Suspended",
// ];

type Props = {
  status:
    | "ongoing"
    | "Started"
    | "Finished"
    | "Submitted"
    | "Canceled"
    | "Suspended";
};

export const TaskListByStatus = ({ status }: Props) => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState("");

  const [searchTask, { data: tasks, isLoading }] = useLazySearchTasksQuery();

  useEffect(() => {
    searchTask({
      data: {
        orderDirection: "desc",
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        organizationId: session?.userInfo.organizationId,
        taskStatus: status,
      },
    });
  }, [pagination.pageIndex, pagination.pageSize, status, searchTask, session]);

  return (
    <div>
      {openDialog && (
        <AddTaskDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      <MaterialReactTable
        columns={taskListColumns}
        data={tasks?.list ?? []}
        enablePagination
        manualPagination
        rowCount={tasks?.count} // Total number of records (if server returns it)
        state={{ pagination, density: "compact", globalFilter, isLoading }}
        onPaginationChange={setPagination}
        initialState={{
          pagination: { pageIndex: 1, pageSize: 10 },
        }}
        // enableColumnFilters={false}
        manualFiltering
        onGlobalFilterChange={setGlobalFilter} //hoist internal global state to your state
        muiTableBodyRowProps={(row) => ({
          onClick: () => {
            navigate(`/task/operational-task/${row.row.original.id}`);
          },
          sx: {
            cursor: "pointer",
          },
        })}
      />
    </div>
  );
};
