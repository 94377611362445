import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Switch } from "@/components/ui/switch";
import { useCreateWorkFlowMutation } from "../../api/work-flow-command";
import { useEffect } from "react";
import { IWorkFlow } from "../../Case/models/workflow";

const workFlowFormSchema = z.object({
  workFlowName: z.string(),
  caseTypeId: z.string(),
  finalStatusName: z.string(),
  notifyToClient: z.boolean(),
  workFlowStep: z.number(),
  parentWorkFlowId: z.string(),
});
type WorkFlowFormValues = z.infer<typeof workFlowFormSchema>;

type AddCaseDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  caseTypeId: string;
  currentNumberOfWorkFlows: number;
  parentWorkFlow: IWorkFlow;
};

export const AddSubWorkFlowDialog = ({
  openDialog,
  setOpenDialog,
  caseTypeId,
  currentNumberOfWorkFlows,
  parentWorkFlow,
}: AddCaseDialogProps) => {
  const form = useForm<WorkFlowFormValues>({
    resolver: zodResolver(workFlowFormSchema),
    mode: "onChange",
    defaultValues: {
      caseTypeId,
      workFlowStep: currentNumberOfWorkFlows + 1,
      notifyToClient: false,
      parentWorkFlowId: parentWorkFlow.id,
    },
  });

  const [createWorkflow, { isSuccess: isCreatingWorkFlowSuccess }] =
    useCreateWorkFlowMutation();

  const onSubmit = async () => {
    console.log(form.getValues());

    await createWorkflow({
      url: "/WorkFlows",
      method: "POST",
      data: { ...form.getValues() },
    });
  };
  useEffect(() => {
    if (isCreatingWorkFlowSuccess) {
      setOpenDialog(false);
      form.reset();
    }
  }, [form, isCreatingWorkFlowSuccess, setOpenDialog]);
  useEffect(() => {
    form.reset();
  }, [form, openDialog]);
  return (
    <DefaultDialog
      title="Add Sub Workflow"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 p-4">
          <FormField
            control={form.control}
            name="workFlowName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Workflow Name</FormLabel>
                <FormControl>
                  <Input placeholder="Workflow Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="finalStatusName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Final Status Name</FormLabel>
                <FormControl>
                  <Input placeholder="Final Status Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notifyToClient"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-2">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Notify User</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex items-center">
            <Button type="submit" className="w-50 mx-auto">
              Submit
            </Button>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
