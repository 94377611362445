import { DefaultPage } from "@/components/shared/DefaultPage";
import { AddTaskDialog } from "../components/AddTaskDialog";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TaskListByStatus } from "../components/TaskListByStatus";

export const TaskList = () => {
  const { session } = useAuth();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState("ongoing");

  return (
    <DefaultPage
      headerTitle="Task List"
      headerActionButton={
        <div className="flex gap-3">
          <Button onClick={() => setOpenDialog(true)}>
            {session?.userInfo.userType === UserType.Client
              ? "Request Task"
              : "Add Task"}
          </Button>
        </div>
      }
    >
      {openDialog && (
        <AddTaskDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="flex justify-start w-full gap-2">
          <TabsTrigger value="ongoing">On Going</TabsTrigger>
          <TabsTrigger value="non-started">Non Started</TabsTrigger>
          <TabsTrigger value="completed" className="text-green-700">
            Completed
          </TabsTrigger>
          <TabsTrigger value="Cancelled" className="text-red-300">
            Cancelled
          </TabsTrigger>
          <TabsTrigger value="Suspended" className="text-yellow-500">
            Suspended
          </TabsTrigger>
        </TabsList>
        <TabsContent value="ongoing">
          <TaskListByStatus status="ongoing" />
        </TabsContent>
        <TabsContent value="non-started">
          <TaskListByStatus status="Submitted" />
        </TabsContent>
        <TabsContent value="completed">
          <TaskListByStatus status="Finished" />
        </TabsContent>
        <TabsContent value="Cancelled">
          <TaskListByStatus status="Canceled" />
        </TabsContent>
        <TabsContent value="Suspended">
          <TaskListByStatus status="Suspended" />
        </TabsContent>
      </Tabs>
    </DefaultPage>
  );
};
