import { Link } from "react-router-dom";
// import { ThemeToggle } from "@/components/theme/ThemToggle";
import { DropdownNotification } from "./DropDownNotification";
import { DropDownUser } from "./DropDownUser";
import { Menu, X } from "lucide-react";
import { Button } from "../../button";
import { IOrganization } from "@/features/organizations/model/Organization";
import LogoImage from "@/lib/LogoImage/LogoImage";

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  organization?: IOrganization;
}) => {
  return (
    // <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none shadow-md">
    <header className="sticky top-0 z-40 flex w-full drop-shadow-1 dark:drop-shadow-none border-b-2  bg-background/95  background backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-2 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <Button
            variant={"outline"}
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="z-99999 block rounded-sm border border-stroke p-1.5 shadow-sm lg:hidden bg-inherit"
          >
            {!props.sidebarOpen && <Menu />}
            {props.sidebarOpen && <X />}
          </Button>

          <Link className="lg:hidden dark:bg-inherit dark:text-white" to="/">
            <Button
              variant={"outline"}
              className="p-0 text-xl font--semibold text-primary border-0 bg-inherit hover:bg-inherit hover:text-inherit"
            >
              UCM
            </Button>
          </Link>
        </div>
        <div className="">
            <div className="relative">
              <div className="relative ml-auto flex-1 md:grow-0">
                {props.organization && (
                  <div className="flex gap-2 items-center relative flex-1 md:grow-0">
                    <LogoImage
                      id={props.organization.id}
                      alt=""
                      fallbackSrc=""
                      className="h-8 w-8 lg:mr-2 lg:h-12 lg:w-16 rounded"
                      allowEdit={false}
                    />
                    <span className="text-lg font-semibold">
                      {props.organization.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
        </div>
        <div className="flex items-center gap-3 2xsm:gap-7 ">
          <ul className="flex items-center gap-2 2xsm:gap-4">
            {/* <!-- Dark Mode Toggler --> */}
            {/* <DarkModeSwitcher /> */}
            {/* <ThemeToggle /> */}
            {/* <!-- Dark Mode Toggler --> */}

            {/* <!-- Notification Menu Area --> */}
            <DropdownNotification />
            {/* <!-- Notification Menu Area --> */}
          </ul>

          <DropDownUser />
        </div>
      </div>
    </header>
  );
};

export default Header;
