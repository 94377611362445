// Chip.tsx
import React from 'react';
import { cn } from "@/lib/utils";  // Import the className utility from ShadCN (or create a similar utility)

interface ChipProps {
    label: string;
    color?: string;  // Optional: for different color variants
    onClick?: () => void;
    className?: string;  // To allow external styling
}

const Chip: React.FC<ChipProps> = ({ label, color = 'bg-gray-200', onClick, className }) => {
    return (
        <span
            className={cn(
                "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium cursor-pointer",
                color,
                className
            )}
            onClick={onClick}
        >
            {label}
        </span>
    );
};

export default Chip;
