import { ChangeProps } from "@/model/change-props";

// Enums for Payment Mode and Payment From
export enum PaymentMode {
  Cash = "Cash",
  Cheque = "Cheque",
  BankDeposit = "BankDeposit",
  CPO = "CPO",
  Credit = "Credit",
}

export enum PaymentFrom {
  Task = "Task",
  GenericTask = "GenericTask",
  Direct = "Direct",
}

export interface PaymentCollection extends ChangeProps {
  id: string;
  taskId: string;
  clientId: string;
  payerName: string;
  paymentDue: number;
  paymentMode: PaymentMode; // Use PaymentMode enum
  paymentFrom: PaymentFrom; // Use PaymentFrom enum
  paidAmount: number;
  paymentDeadline: string; // Use Date if you want to work with Date objects instead of strings
  paymentDate: string; // Use Date if you want to work with Date objects instead of strings
  description: string; // Use Date if you want to work with Date objects instead of strings
  taskReference: string;
  status: string;
  invoiceAttachments: PaymentInvoiceAttachment[];
  paymentType: string;
}

interface PaymentInvoiceAttachment {
  id: string;
  clientFinanceId: string;
  clientFinance: string;
  filePath: string;
  uploadedAt: string;
}

export interface IPaymentType {
  id: string;
  name: string;
  remark: string;
  createdById: string;
  date: Date;
  incomeExepense: string;
  organizationId: string;
  isActive: boolean;
}
