import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useGetGenericTaskHistoryByIdQuery } from "../api/generic-task-api";
import { IGenericTaskHistory } from "../model/GenericTask";

type TaskHistoryProps = {
  taskId: string;
};
const taskHistoryColumns: MRT_ColumnDef<IGenericTaskHistory>[] = [
  {
    accessorKey: "user.username",
    header: "User",
  },
  {
    accessorKey: "oldStatus",
    header: "Old Status",
  },
  {
    accessorKey: "newStatus",
    header: "New Status",
  },
  {
    accessorKey: "note",
    header: "Note",
  },
  {
    accessorKey: "taskDate",
    header: "Date",
    Cell: ({ row }) => {
      return new Date(row.original.taskDate).toLocaleDateString();
    },
  },

  //   {
  //     accessorKey: "workFlowStatus",
  //     header: "Workflow Status",
  //     Cell: ({ row }) => {
  //       return row.original.workFlowStatus === "Canceled" ? (
  //         <Badge variant="destructive">{row.original.workFlowStatus}</Badge>
  //       ) : (
  //         row.original.workFlowStatus
  //       );
  //     },
  //   },
];
export const GenericTaskHistory = ({ taskId }: TaskHistoryProps) => {
  const { data: taskHistory = [] } = useGetGenericTaskHistoryByIdQuery({
    data: {
      id: taskId,
    },
  });

  return (
    <div>
      <MaterialReactTable
        columns={taskHistoryColumns}
        data={taskHistory}
        enablePagination
      />
    </div>
  );
};
