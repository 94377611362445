import PageTitle from "@/components/ui/PageTitle";
import { Route } from "react-router-dom";
import Lead from "./leads/pages/Lead";
import { PipeLineWorkflowPage } from "./pipeline-workflow/PipeLineWorkflowPage";
import { PipeLineWorkflowDetailPage } from "./pipeline-workflow/pages/PipeLineWorkflowDetailPage";
import SalesDashboard from "./dashboard/pages/SalesDashboard";
import { TenderWorkflowPage } from "./tender-workflow/TenderWorkflowPage";
export default (
  <Route path="sales">
    <Route
      index
      element={
        <>
          <PageTitle title="Dashboard" />
          <SalesDashboard />
        </>
      }
    />
    <Route path="dashboard">
      <Route
        index
        element={
          <>
            <PageTitle title="Dashboard" />
            <SalesDashboard />
          </>
        }
      />
    </Route>
    <Route path="leads">
      <Route
        index
        element={
          <>
            <PageTitle title="Leads" />
            <Lead />
          </>
        }
      />
    </Route>
    <Route path="deals">
      <Route
        index
        element={
          <>
            <PageTitle title="Deals" />
            <></>
          </>
        }
      />
    </Route>
    <Route path="pipeline-workflow">
      <Route
        index
        element={
          <>
            <PageTitle title="Pipeline workflow" />
            <PipeLineWorkflowPage />
          </>
        }
      />
      <Route
        path=":salesTaskId"
        element={
          <>
            <PageTitle title="pipeline detail" />
            <PipeLineWorkflowDetailPage />
          </>
        }
      />
    </Route>
    <Route path="tender-workflow">
      <Route
        index
        element={
          <>
            <PageTitle title="Tender Workflow" />
            <TenderWorkflowPage />
          </>
        }
      />
      <Route
        path=":salesTaskId"
        element={
          <>
            <PageTitle title="Workflow detail" />
            <PipeLineWorkflowDetailPage />
          </>
        }
      />
    </Route>
  </Route>
);
