import { useParams } from "react-router-dom";
import { useGetTaskDetailsByTaskIdQuery } from "../api/task-query";
import { useEffect, useState } from "react";
import { useLazyGetWorkFlowByCaseTypeIdQuery } from "@/features/workflows/api/work-flow-api";
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TaskHistory } from "../components/TaskHistory";
import TaskDetail from "../components/TaskDetail";
import { TaskMessaging } from "../components/TaskMessaging";
import { CircularPercentageIndicator } from "@/components/shared/Indicators/CircularPercentageIndicator";
import { TaskPayment } from "../components/TaskPayment";
import { TaskAlertNotificationList } from "../components/TaskAlertNotificationList";

export const TaskDetailPage = () => {
  const { id } = useParams();

  const {
    data: taskDetail,
    isLoading: isFetchingTaskLoading,
    isError: isFetchingTaskError,
  } = useGetTaskDetailsByTaskIdQuery(id ?? "");
  const [
    getWorkFlowByCase,
    { data: workflows = [], isLoading: isFetchingWorkFlowLoading },
  ] = useLazyGetWorkFlowByCaseTypeIdQuery();

  useEffect(() => {
    if (taskDetail?.task.caseTypeId) {
      getWorkFlowByCase({ data: { caseTypeId: taskDetail.task.caseTypeId } });
    }
  }, [getWorkFlowByCase, taskDetail]);

  const [selectedTab, setSelectedTab] = useState("detail");

  if (isFetchingTaskError) {
    return <div>error...</div>;
  }
  if (isFetchingWorkFlowLoading || isFetchingTaskLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DefaultPageHeader
        headerTitle={`Task Detail: ${taskDetail?.task.taskReference}`}
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: "Task List",
            url: "/task/operational-task",
          },
          {
            name: "Task Detail",
            url: "#",
          },
        ]}
        headerActionButton={
          <CircularPercentageIndicator
            value={taskDetail?.task.completionRate ?? 0}
            size={60}
          />
        }
      />

      <Tabs
        defaultValue={selectedTab}
        className=""
        onValueChange={setSelectedTab}
      >
        <TabsList className="grid w-100 grid-cols-5">
          <TabsTrigger value="detail">Detail</TabsTrigger>
          <TabsTrigger value="message">Messages</TabsTrigger>
          <TabsTrigger value="payment">Payment</TabsTrigger>
          <TabsTrigger value="alerts" className="text-red-500">
            Alerts
          </TabsTrigger>
          <TabsTrigger value="history">History</TabsTrigger>
        </TabsList>
        <TabsContent value="detail">
          {!taskDetail && <div>Loading...</div>}
          {taskDetail && (
            <TaskDetail taskDetail={taskDetail} workflows={workflows} />
          )}
        </TabsContent>
        <TabsContent value="history">
          {taskDetail?.task.id && <TaskHistory taskId={taskDetail.task.id} />}
        </TabsContent>
        <TabsContent value="message">
          {taskDetail?.task.id && (
            <TaskMessaging taskId={taskDetail?.task.id} />
          )}
        </TabsContent>
        <TabsContent value="payment">
          {taskDetail?.task.id && <TaskPayment taskDetail={taskDetail} />}
        </TabsContent>
        <TabsContent value="alerts">
          {taskDetail?.task.id && (
            <TaskAlertNotificationList taskId={taskDetail.task.id} />
          )}
        </TabsContent>
      </Tabs>
    </>
  );
};
