import { useEffect, useMemo, useState } from "react";
import { IWorkFlow, IWorkFlowParameter } from "../../Case/models/workflow";
import { AddSubWorkFlowDialog } from "./AddSubWorkFlowDialog";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useLazyGetWorkFlowParametersByWorkFlowIdQuery } from "../../api/work-flow-api";
import { AddParameterDialog } from "./AddParameterDialog";
import { EditIcon, Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditParameterDialog, { ParameterFormValues } from "./EditParamterDialog";
import { Badge } from "@/components/ui/badge";
import { useUpdateParameterOrderNoMutation } from "../../Case/api/case-api";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { useDeleteWorkFlowParameterMutation } from "../../api/work-flow-command";
import { NotificationOptions } from "@/model/enums";
import { Notify } from "@/components/shared/Notification/notify";

type Props = {
  workflow: IWorkFlow;
};

const WorkflowBody = ({ workflow }: Props) => {
  const [dialogs, setDialogs] = useState({
    openDialog: false,
    openSubWorkflowDialog: false,
    isEditDialogOpen: false,
    openConfirmModal: false,
  });
  const [selectedParameter, setSelectedParameter] =
    useState<IWorkFlowParameter | null>(null);
  const [editingParameter, setEditingParameter] =
    useState<ParameterFormValues | null>(null);
  const [data, setData] = useState<IWorkFlowParameter[]>([]);
  const [initialData, setInitialData] = useState<IWorkFlowParameter[]>([]);

  const [fetchParameter, { data: ParameterList = [] }] =
    useLazyGetWorkFlowParametersByWorkFlowIdQuery();
  const [
    deleteParameter,
    {
      isLoading: isParameterDeletingLoading,
      isSuccess: isParameterDeletingSuccess,
    },
  ] = useDeleteWorkFlowParameterMutation();
  const [updateParameterOrderNo] = useUpdateParameterOrderNoMutation();

  useEffect(() => {
    fetchParameter({ data: { workFlowId: workflow.id } });
  }, [workflow.id, fetchParameter]);

  useEffect(() => {
    if (ParameterList) {
      setData(ParameterList);
      setInitialData(ParameterList);
    }
  }, [ParameterList]);

  useEffect(() => {
    if (isParameterDeletingSuccess) {
      setDialogs((prev) => ({ ...prev, openConfirmModal: false }));
      setSelectedParameter(null);
      Notify("success", "Parameter deleted successfully");
    }
  }, [isParameterDeletingSuccess]);

  const columns = useMemo<MRT_ColumnDef<IWorkFlowParameter>[]>(
    () => [
      {
        accessorKey: "parameterName",
        header: "Name",
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "parameterType",
        header: "Type",
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "paramaterValues",
        header: "Values",
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "isMultipleUserParticipated",
        header: "Multiple User Participated",
        Cell: ({ row }) => (
          <>{row.original.isMultipleUserParticipated ? "Yes" : "No"}</>
        ),
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "isRequired",
        header: "Is Required",
        Cell: ({ row }) => <>{row.original.isRequired ? "Yes" : "No"}</>,
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "notificationOption",
        header: "Notification Option",
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ row }) => (
          <Badge variant={row.original.isActive ? "default" : "destructive"}>
            {row.original.isActive ? "Active" : "In Active"}
          </Badge>
        ),
        muiTableHeadCellProps: { sx: { fontWeight: "normal" } },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    getRowId: (row) => row.id,
    enableRowOrdering: true,
    enableSorting: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        const draggingIndex = draggingRow?.index;
        const hoveredIndex = hoveredRow?.index;

        if (
          typeof draggingIndex === "number" &&
          typeof hoveredIndex === "number"
        ) {
          setData((prevData) => {
            const newData = [...prevData];
            const [movedItem] = newData.splice(draggingIndex, 1);
            newData.splice(hoveredIndex, 0, movedItem);
            return newData;
          });
        }
      },
    }),
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              const paramaterValues = row.original.paramaterValues
                ? JSON.parse(row.original.paramaterValues)
                : [];
              setEditingParameter({
                parameterName: row.original.parameterName,
                parameterType: row.original.parameterType as
                  | "number"
                  | "boolean"
                  | "select"
                  | "text"
                  | "date"
                  | "multiselect",
                paramaterValues: paramaterValues.map((value: string) => ({
                  value,
                })),
                workFlowId: row.original.workFlowId,
                isMultipleUserParticipated:
                  row.original.isMultipleUserParticipated || false,
                isActive: row.original.isActive,
                id: row.original.id,
                orderNo: row.original.orderNo,
                notificationOption: row.original
                  .notificationOption as unknown as NotificationOptions,
                notifyToClient: row.original.notifyToClient,
                isFixed: row.original.isFixed,
              });
              setDialogs((prev) => ({ ...prev, isEditDialogOpen: true }));
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setSelectedParameter(row.original);
              setDialogs((prev) => ({ ...prev, openConfirmModal: true }));
            }}
          >
            <Trash2 color="red" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  const handleDeleteParameter = async (id: string) => {
    await deleteParameter(id);
  };

  const hasOrderChanged = useMemo(() => {
    if (initialData.length !== data.length) return false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== initialData[i].id) {
        return true;
      }
    }
    return false;
  }, [data, initialData]);

  const handleSaveOrder = async () => {
    const orders = data.map((item, index) => ({ id: item.id, orderNo: index }));
    await updateParameterOrderNo({ data: orders });
    setInitialData([...data]);
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          onClick={() =>
            setDialogs((prev) => ({ ...prev, openSubWorkflowDialog: true }))
          }
          className="hidden"
        >
          <Plus size={18} className="mr-2" />
          Add Sub Workflow
        </Button>
        {dialogs.openSubWorkflowDialog && (
          <AddSubWorkFlowDialog
            openDialog={dialogs.openSubWorkflowDialog}
            setOpenDialog={(open) =>
              setDialogs((prev) => ({ ...prev, openSubWorkflowDialog: open }))
            }
            caseTypeId={workflow.caseTypeId}
            currentNumberOfWorkFlows={1}
            parentWorkFlow={workflow}
          />
        )}
      </div>
      <div className="p-2">
        <div className="m-2">
          <Button
            onClick={() =>
              setDialogs((prev) => ({ ...prev, openDialog: true }))
            }
          >
            <Plus size={18} className="mr-2" />
            Add Parameter
          </Button>
          <AddParameterDialog
            openDialog={dialogs.openDialog}
            setOpenDialog={(open) =>
              setDialogs((prev) => ({ ...prev, openDialog: open }))
            }
            workflow={workflow}
            currentNumberOfWorkFlows={ParameterList.length}
          />
        </div>
        <MaterialReactTable table={table} />

        {hasOrderChanged && (
          <Button
            variant="outline"
            size="sm"
            className="mt-3"
            onClick={handleSaveOrder}
          >
            Save Order
          </Button>
        )}
        <EditParameterDialog
          open={dialogs.isEditDialogOpen}
          onClose={() =>
            setDialogs((prev) => ({ ...prev, isEditDialogOpen: false }))
          }
          parameter={editingParameter}
        />

        {dialogs.openConfirmModal && selectedParameter && (
          <ConfirmModal
            message="Are you Sure you want to delete this parameter"
            title="Delete Parameter"
            modalOpen
            setModalOpen={() => {}}
            type="error"
            customOkText="Delete"
            onCancel={() => {
              setDialogs((prev) => ({ ...prev, openConfirmModal: false }));
              setSelectedParameter(null);
            }}
            onOk={() => handleDeleteParameter(selectedParameter.id)}
            loading={isParameterDeletingLoading}
          />
        )}
      </div>
    </>
  );
};

export default WorkflowBody;
