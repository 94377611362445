import { appApi } from "@/store/app-api";
import { CommandRequest } from "@/model/command-request";
import {
  IAlertNotification,
  ITaskCreate,
  ITaskNoteCreate,
} from "../models/Task";

export interface ITaskDetail {
  workFlowId: string;
  workFlowParameterId: string;
  value: string;
}

export interface ITaskUpdateDetailsAndStatus {
  taskId: string;
  taskDetails: ITaskDetail[];
}

export interface ITaskMoveToNextWorkflowStep {
  taskId: string;
  note: string;
}
export interface CancelSuspendData {
  taskId: string;
  note: string;
}
interface BackWorkflowData {
  taskId: string;
  note: string;
}
interface ReviseWorkflowData {
  taskId: string;
  note: string;
}

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createTask: builder.mutation<string, CommandRequest<ITaskCreate>>({
      query: (params) => ({
        method: "POST",
        url: "/otasks",
        data: { ...params.data, TaskReference: "-" },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    updateTaskDetailsAndStatus: builder.mutation<
      string,
      CommandRequest<ITaskUpdateDetailsAndStatus>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/taskDetails/UpdateTaskDetailsAndStatus`,
        data: params.data,
        params: {
          taskId: params.data.taskId,
        },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    moveToNextWorkflowStep: builder.mutation<
      string,
      CommandRequest<ITaskMoveToNextWorkflowStep>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/otasks/moveToNextWorkflowStep`,
        params: { taskId: params.data.taskId },
        data: { note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),

    cancelWorkflow: builder.mutation<string, CommandRequest<CancelSuspendData>>(
      {
        query: (params) => ({
          method: "PUT",
          url: "/otasks/CancelTask",
          params: { taskId: params.data.taskId },
          data: { TaskId: params.data.taskId, Note: params.data.note },
        }),
        invalidatesTags: ["Task", "TaskDetail"],
      }
    ),
    BackWorkflow: builder.mutation<string, CommandRequest<BackWorkflowData>>({
      query: (params) => ({
        method: "PUT",
        url: "/otasks/BackTask",
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    ReviseWorkflow: builder.mutation<
      string,
      CommandRequest<ReviseWorkflowData>
    >({
      query: (params) => ({
        method: "PUT",
        url: "/otasks/ReviseTask",
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    suspendWorkflow: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/otasks/SuspendTask`,
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),

    ActivateTask: builder.mutation<string, CommandRequest<CancelSuspendData>>({
      query: (params) => ({
        method: "PUT",
        url: `/otasks/ActivateTask`,
        params: { taskId: params.data.taskId },
        data: { TaskId: params.data.taskId, Note: params.data.note },
      }),
      invalidatesTags: ["Task", "TaskDetail"],
    }),
    saveTaskNote: builder.mutation<string, CommandRequest<ITaskNoteCreate>>({
      query: (params) => ({
        method: "POST",
        url: `/notes`,
        data: { ...params.data },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    addAlertFortTask: builder.mutation<
      string,
      CommandRequest<Partial<IAlertNotification>>
    >({
      query: (params) => ({
        method: "POST",
        url: `/alert-notifications`,
        data: { ...params.data },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "Alerts"],
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useUpdateTaskDetailsAndStatusMutation,
  useMoveToNextWorkflowStepMutation,
  useCancelWorkflowMutation,
  useBackWorkflowMutation,
  useReviseWorkflowMutation,
  useSuspendWorkflowMutation,
  useActivateTaskMutation,
  useSaveTaskNoteMutation,
  useAddAlertFortTaskMutation,
} = taskApi;
