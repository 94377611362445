import React from 'react'
import { ArrowRight } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { Button } from '@/components/ui/button';

type DashboardCardProps = {
  title: string;
  count: number | string;
  icon: React.ReactNode; // Icon component passed as a prop
  link: string;
  linkText: string;
  bgColor?: string; // Optional background color for customization
  hideAction?: boolean
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, count, icon, link, linkText, bgColor, hideAction=false }) => {
  return (
    <div className={`flex flex-col gap-4 p-6  rounded-lg shadow transition transform hover:scale-1 hover:shadow-md ${bgColor ?? 'bg-white'}`}>
      {/* Icon */}
      <div className='flex text-primary text-2xl bg-[#F8F9FD]'>
        {icon}
      </div>

      {/* Title & Count */}
      <div className="">
        <p className="text font-normal text-sm">{title}</p>
        <p className="text-lg font-medium">{count}</p>
      </div>
      {
        !hideAction && 
        <NavLink
          to={link}
        >
        <Button
          variant={"outline"}
          className="lg:flex lg:justify-center lg:items-center lg:gap-2 text-blue-600 hover:text-blue-800 font-medium"
        >
          <p className='font-normal'>{linkText}</p>
          <ArrowRight className="hidden w-5 h-5 lg:block" />
        </Button>
        </NavLink>
      }
      {/* Link */}
    </div>
  )
}

export default DashboardCard
