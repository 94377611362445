// components/form/TextInput.tsx
import React from "react";
import TextField from "@mui/material/TextField";

interface TextInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  onChange,
  defaultValue,
  disabled,
  required = false,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      margin="normal"
      variant="outlined"
      placeholder={`Enter ${label}`}
      size="small"
      defaultValue={defaultValue}
      disabled={disabled}
      required={required}
    />
  );
};

export default TextInput;
