import { RootState } from "@/store/app-store";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface GlobalError {
  error: any;
}

const initialState: GlobalError = { error: "" };

const GlobalErrorSlice = createSlice({
  name: "globalError",
  initialState,
  reducers: {
    setError: (state: GlobalError, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const selectError = (state: RootState) => state.globalError.error;
export const { setError } = GlobalErrorSlice.actions;
export const GlobalErrorReducer = GlobalErrorSlice.reducer;
