import { Textarea } from "@/components/ui/textarea";
import { useCreateSectorMutation } from "../api/case-api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";

type AddSectorFormProps = {
  setOpenDialog: () => void;
};

// Define Zod schema
const schema = z.object({
  sectorName: z.string().min(1, { message: "Sector name is required" }),
  description: z.string().min(1, { message: "Description is required" }),
});

type SectorFormData = z.infer<typeof schema>;

const AddSectorForm = ({ setOpenDialog }: AddSectorFormProps) => {
  const form = useForm<SectorFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      sectorName: "",
      description: "",
    },
  });

  const [createSector, { isLoading: isLoadingCaseSuccess, isSuccess: isCreatingCaseSuccess }] = useCreateSectorMutation();

  const onSubmit = async (data: SectorFormData) => {
    await createSector({
      url: "/sectors",
      method: "POST",
      data,
    });
  };

  useEffect(() => {
    if (isCreatingCaseSuccess) {
      form.reset();
      Notify("success", "Sector Added Successfully!")
      setOpenDialog()
    }
  }, [isCreatingCaseSuccess]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="sectorName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Sector Name</FormLabel>
              <FormControl>
                <Input placeholder="Sector name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea placeholder="Description" className="resize-none" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-center">
          <CustomButton isLoading={isLoadingCaseSuccess} disabled={!form.formState.isValid} type="submit" className="w-full mx-auto">
            Submit
          </CustomButton>
        </div>
      </form>
    </Form>
  );
};

export default AddSectorForm;
