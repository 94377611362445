import { useSearchCustomersQuery } from "@/features/customers/api/customer-api";
import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { MessageType } from "../models/sms";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import CustomButton from "@/components/shared/Button/CustomButton";
import { FullScreenDialog } from "@/components/shared/DefaultDailog/FullPageDialog";
import { Label } from "@/components/ui/label";
import { X } from "lucide-react";
import { useSendBulkSMSMutation } from "../api/sms-email-api";
import { Notify } from "@/components/shared/Notification/notify";
import { isValidPhoneNumber } from "react-phone-number-input";
import SendSmsFilters, { SMSFilterKeys } from "./SendSmsFilters";
import { CustomerStatus } from "@/features/customers/models/Customer";

interface SendSMSProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
}

const SendMessageSchema = z
  .object({
    messageType: z.enum(["sms", "email"], {
      required_error: "Message type is required",
    }),
    selectedCustomerIds: z.array(z.string()).default([]), // Customers selection
    manualRecipients: z.array(z.string()).optional(), // Manual recipients
    messageContent: z
      .string()
      .min(1, { message: "Message content is required" }),
  })
  .refine(
    (data) => {
      return (
        (data.selectedCustomerIds && data.selectedCustomerIds.length > 0) ||
        (data.manualRecipients && data.manualRecipients.length > 0)
      );
    },
    {
      message:
        "Please select at least one customer or enter manual recipients.",
      path: ["manualRecipients"],
    }
  );

// Define the form's data type
type SendMessageFormData = z.infer<typeof SendMessageSchema>;

// Mock customer data (Replace this with actual data fetching)
const SendSMS = ({ openForm, setOpenForm }: SendSMSProps) => {
  const { data: customersList } = useSearchCustomersQuery({});
  const [
    SendBulkSMS,
    { isLoading: isSendBulkSentLoading, isSuccess: isSendBulkSentSuccess },
  ] = useSendBulkSMSMutation();
  const [messageType, setMessageType] = useState<MessageType>("sms");
  const [filter, setFilter] = useState<SMSFilterKeys>({
    searchTerm: "",
    country: "",
    sector: "",
    customerStatus: CustomerStatus.Both,
  });

  const form = useForm<SendMessageFormData>({
    resolver: zodResolver(SendMessageSchema),
    defaultValues: {
      messageType: "sms",
      selectedCustomerIds: [],
      manualRecipients: [],
      messageContent: "",
    },
  });

  // Handle Select All toggle
  const handleSelectAll = (checked: boolean) => {
    const selected = form.getValues("selectedCustomerIds");
    if (checked) {
      const newSelected = Array.from(
        new Set([...selected, ...(filteredCustomers?.map((c) => c.id) ?? [])])
      );
      form.setValue("selectedCustomerIds", newSelected);
    } else {
      // const newSelected = selected.filter(id => !filteredCustomers?.map(c => c.id).includes(id));
      form.setValue("selectedCustomerIds", []);
    }
  };

  const onSubmit = async (data: SendMessageFormData) => {
    // Get the selected customers' phone numbers from the customer list
    const selectedCustomersPhoneNumbers = customersList?.clients
      .filter((customer) => data.selectedCustomerIds.includes(customer.id))
      .flatMap((customer) => customer.phoneNumbers || []);

    // Merge the manually entered recipients and selected customers' phone numbers
    const phoneNumbers = [
      ...(data.manualRecipients || []),
      ...(selectedCustomersPhoneNumbers || []),
    ];

    // Submit the bulk SMS with the merged phone numbers and message content
    await SendBulkSMS({
      data: {
        phoneNumbers: phoneNumbers, // merged phone numbers
        message: data.messageContent,
      },
    });
  };

  // Function to add a manual recipient
  const addManualRecipient = (recipient: string) => {
    if (recipient && !recipient.trim()) return;

    // Validate recipient format based on messageType
    const isValid =
      messageType === "sms"
        ? isValidPhoneNumber(recipient) // E.164 format for phone numbers
        : /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(recipient); // Simple email regex

    if (isValid) {
      const currentRecipients = form.getValues("manualRecipients") ?? [];
      if (!currentRecipients.includes(recipient)) {
        form.setValue("manualRecipients", [...currentRecipients, recipient]);
      }
    } else {
      alert(
        `Invalid ${
          messageType === "sms" ? "phone number" : "email address"
        }: ${recipient}`
      );
    }
  };

  // Function to remove a manual recipient
  const removeManualRecipient = (index: number) => {
    const currentRecipients = form.getValues("manualRecipients") || [];
    currentRecipients.splice(index, 1);
    form.setValue("manualRecipients", [...currentRecipients]);
  };

  useEffect(() => {
    if (isSendBulkSentSuccess) {
      setOpenForm(false);
      Notify("success", "Message Sent Successfully!");
      form.reset();
    }
  }, [form, isSendBulkSentSuccess, setOpenForm]);

  const filteredCustomers = useMemo(() => {
    if (customersList) {
      let customers = customersList?.clients;
      if (filter.searchTerm) {
        customers = customers.filter(
          (customer) =>
            customer.clientName
              .toLowerCase()
              .includes(filter.searchTerm.toLowerCase()) ||
            customer.phoneNumbers?.includes(filter.searchTerm) ||
            customer.email
              .toLowerCase()
              .includes(filter.searchTerm.toLowerCase())
        );
      }
      if (filter.country) {
        customers = customers.filter((customer) =>
          customer.country.toLowerCase().includes(filter.country.toLowerCase())
        );
      }
      if (filter.sector) {
        customers = customers.filter((customer) =>
          customer.sectorIds?.includes(filter.sector.toLowerCase())
        );
      }
      if (filter.customerStatus !== CustomerStatus.Both) {
        customers = customers.filter(
          (customer) => customer.customerStatus === filter.customerStatus
        );
      }
      return customers;
    } else {
      return [];
    }
  }, [customersList, filter]);

  return (
    <FullScreenDialog
      title="Send Message"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          {/* Message Type Selection */}
          <FormField
            control={form.control}
            name="messageType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message Type</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={(value: MessageType) => {
                      field.onChange(value);
                      setMessageType(value);
                    }}
                    value={field.value}
                    className="flex space-x-4"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="sms" id="sms" />
                      <Label htmlFor="sms">SMS</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="email" id="email" />
                      <Label htmlFor="email">Email</Label>
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Recipient Selection */}
          <FormField
            control={form.control}
            name="selectedCustomerIds"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Select Customers</FormLabel>
                <FormDescription>
                  Choose one or more customers to send the message.
                </FormDescription>
                <FormControl>
                  <>
                    {/* Search Input */}
                    <SendSmsFilters filter={filter} setFilter={setFilter} />

                    {/* Select All Checkbox */}
                    <div className="flex items-center mb-2">
                      <Checkbox
                        id="select-all"
                        checked={
                          form.getValues("selectedCustomerIds").length > 0 &&
                          form.getValues("selectedCustomerIds").length ===
                            customersList?.clients.length
                        }
                        onCheckedChange={(checked: boolean) =>
                          handleSelectAll(checked)
                        }
                      />
                      <label htmlFor="select-all" className="ml-2 text-sm">
                        Select All
                      </label>
                    </div>

                    {/* Scrollable Checkbox List */}
                    <div className="max-h-60 overflow-y-auto border border-gray-200 rounded p-2">
                      {filteredCustomers.length > 0 ? (
                        filteredCustomers
                          .filter(
                            (cust) =>
                              cust.phoneNumbers && cust.phoneNumbers.length > 0
                          )
                          .map((customer) => (
                            <div
                              key={customer.id}
                              className="flex items-center space-x-2"
                            >
                              <Checkbox
                                id={`customer-${customer.id}`}
                                value={customer.id}
                                checked={field.value.includes(customer.id)}
                                onCheckedChange={(checked: boolean) => {
                                  if (checked) {
                                    field.onChange([
                                      ...field.value,
                                      customer.id,
                                    ]);
                                  } else {
                                    field.onChange(
                                      field.value.filter(
                                        (id) => id !== customer.id
                                      )
                                    );
                                  }
                                }}
                              />
                              <label
                                htmlFor={`customer-${customer.id}`}
                                className="text-sm"
                              >
                                {customer.clientName} (
                                {customer.phoneNumbers[0]}, {customer.email})
                              </label>
                            </div>
                          ))
                      ) : (
                        <div className="text-center text-gray-500">
                          No customers found.
                        </div>
                      )}
                    </div>
                  </>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Manual Recipient Entry */}
          <FormField
            control={form.control}
            name="manualRecipients"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Enter{" "}
                  {messageType === "sms" ? "Phone Numbers" : "Email Addresses"}
                </FormLabel>
                <FormControl>
                  <div className="flex flex-wrap gap-2">
                    {field.value?.map((recipient, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-gray-200 text-gray-800 px-3 py-1 rounded-full"
                      >
                        <span>{recipient}</span>
                        <button
                          type="button"
                          className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          onClick={() => removeManualRecipient(index)}
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    <Input
                      type={messageType === "sms" ? "tel" : "email"}
                      placeholder={
                        messageType === "sms"
                          ? "e.g., +25194567890"
                          : "e.g., user@example.com"
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === ",") {
                          e.preventDefault();
                          const value = (
                            e.currentTarget as HTMLInputElement
                          ).value.trim();
                          if (value) {
                            addManualRecipient(value);
                            (e.currentTarget as HTMLInputElement).value = "";
                          }
                        }
                      }}
                    />
                  </div>
                </FormControl>
                <FormDescription>
                  Alternatively, enter phone numbers or email addresses and
                  press Enter or comma to add.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Message Content */}
          <FormField
            control={form.control}
            name="messageContent"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message Content</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Type your message here..."
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  Compose the message you want to send.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Submit Button */}
          <CustomButton
            disabled={isSendBulkSentLoading}
            isLoading={isSendBulkSentLoading}
            type="submit"
            className="w-full"
          >
            Send {messageType === "sms" ? "SMS" : "Email"}
          </CustomButton>
        </form>
      </Form>
    </FullScreenDialog>
  );
};

export default SendSMS;
