import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api"
import { IOrganization } from "../model/Organization";

export const organizationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationById: builder.query<IOrganization, QueryRequest>({
      query: (params) => ({
        url: `/Companies/${params.url}`,
        method: "GET"
      })
    }),
    getOrganizationLogo: builder.query<Blob, QueryRequest>({
        query: (params) => ({
          url: `/Companies/Logo/${params.url}`,
          method: "GET",
          
        })
      }),
  }),
});

export const {
  useGetOrganizationByIdQuery,
  useGetOrganizationLogoQuery
} = organizationApi;
