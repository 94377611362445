import { appApi } from "@/store/app-api";
import { ITaskDetail, ITaskDetailCreate } from "../model/GenericTask";
import { CommandRequest } from "@/model/command-request";
import { ITaskNoteCreate } from "@/features/task/models/Task";
import { CancelSuspendData } from "@/features/task/api/task-command";

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    addGenericTaskActivity: builder.mutation<string, ITaskDetailCreate>({
      query: (data) => ({
        url: "/genericTaskDetails",
        method: "POST",
        data: data,
      }),
      invalidatesTags: ["GenericTask"],
    }),
    updateGenericTaskActivity: builder.mutation<string, Partial<ITaskDetail>>({
      query: (data) => ({
        url: `/genericTaskDetails/${data.id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: ["GenericTask"],
    }),
    saveGenericTaskNote: builder.mutation<
      string,
      CommandRequest<ITaskNoteCreate>
    >({
      query: (params) => ({
        method: "POST",
        url: `/genericTaskNotes`,
        data: { ...params.data },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),
    cancelGenericTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/genericTasks/CancelGenericTask`,
        data: { ...params.data },
        params: { taskId: params.data.taskId },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),

    closeGenericTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/genericTasks/CloseGenericTask`,
        data: { ...params.data },
        params: { taskId: params.data.taskId },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),
    suspendGenericTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/genericTasks/SuspendedGenericTask`,
        data: { ...params.data },
        params: { taskId: params.data.taskId },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),

    activateGenericTask: builder.mutation<
      string,
      CommandRequest<CancelSuspendData>
    >({
      query: (params) => ({
        method: "PUT",
        url: `/genericTasks/ActivateTask`,
        data: { ...params.data },
        params: { taskId: params.data.taskId },
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskNote", "GenericTask"],
    }),
  }),
});

export const {
  useAddGenericTaskActivityMutation,
  useSaveGenericTaskNoteMutation,
  useCancelGenericTaskMutation,
  useCloseGenericTaskMutation,
  useSuspendGenericTaskMutation,
  useActivateGenericTaskMutation,
  useUpdateGenericTaskActivityMutation,
} = taskApi;
