// Caption.tsx
import React from 'react';
import { cn } from "@/lib/utils";  // Import or create className utility

interface CaptionProps {
    text: string;
    className?: string;
}

const Caption: React.FC<CaptionProps> = ({ text, className }) => {
    return (
        <p className={cn("text-sm text-gray-500", className)}>
            {text}
        </p>
    );
};

export default Caption;
