import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Label } from "@/components/ui/label";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { Check, ChevronDown } from "lucide-react";

interface Option {
  label: string;
  value: string;
}

interface FormSelectProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
  options: Option[];
  placeholder?: string;
  rules?: RegisterOptions<T>;
  disabled?: boolean;
}

const FormAutoCompleteSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  //   placeholder,
  rules,
}: //   disabled = false,
FormSelectProps<T>) => {
  const [openSector, setOpenSector] = useState<boolean>(false);
  return (
    <div className="mt-4">
      {label && (
        <Label
          htmlFor={name}
          className="text-left block text-sm font-medium text-gray-700"
        >
          {label}
        </Label>
      )}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="">
              <Popover open={openSector} onOpenChange={setOpenSector}>
                <PopoverTrigger asChild className="">
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between w-full",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value
                      ? options?.find((option) => option.label === field.value)
                          ?.label
                      : `Select ${label}`}
                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0 z-9999 w-100">
                  <Command className="w-">
                    <CommandInput placeholder={`Search ${label}`} />
                    <CommandList>
                      <CommandEmpty>No result found.</CommandEmpty>
                      <CommandGroup>
                        {options.map((option) => (
                          <CommandItem
                            value={option.label}
                            key={option.value}
                            onSelect={() => {
                              field.onChange(option.label);
                              setOpenSector(false);
                              // caseTypeIdRef.current?.focus();
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                option.value === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {option.label}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              {error && (
                <p className="text-red-600 text-sm mt-1">{error.message}</p>
              )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default FormAutoCompleteSelect;
