import { DefaultPage } from "@/components/shared/DefaultPage";

import { Button } from "@/components/ui/button";
import { Check, ChevronDown, Pencil, Plus } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { useGetCasesQuery } from "../Case/api/case-api";
import { useEffect, useState } from "react";
import { useLazyGetWorkFlowByCaseTypeIdQuery } from "../api/work-flow-api";
import { Separator } from "@/components/ui/separator";
import { ICase } from "../Case/models/Case";
import { Skeleton } from "@/components/ui/skeleton";
import { AddWorkFlowDialog } from "./components/AddWorkFlowDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import WorkflowBody from "./components/WorkflowBody";
import { Box } from "@mui/material";
import { WorkflowDetailHeader } from "./components/WorkflowDetailHeader";
import { WorkflowList } from "./components/WorkflowList";

const Workflow = () => {
  const { data: caseList = [] } = useGetCasesQuery();
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);
  const [selectedCase, setSelectedCase] = useState<ICase | null>(null);
  const [openSector, setOpenSector] = useState<boolean>(false);
  const [
    getWorkFlowByCase,
    { data: workflows, isLoading: isFetchingWorkFlowLoading },
  ] = useLazyGetWorkFlowByCaseTypeIdQuery();

  useEffect(() => {
    if (selectedCaseId) {
      getWorkFlowByCase({ data: { caseTypeId: selectedCaseId } });
    }
  }, [getWorkFlowByCase, selectedCaseId]);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  return (
    <DefaultPage headerTitle="Workflow">
      <div className="h-screen">
        <div className="px-4 m-3  py-2">
          <Popover open={openSector} onOpenChange={setOpenSector}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="flex justify-between w-100"
              >
                {selectedCaseId
                  ? caseList.find((c) => c.id == selectedCaseId)?.type
                  : "Select Case"}
                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-100 p-0">
              <Command>
                <CommandInput placeholder="Search Case..." />
                <CommandList>
                  <CommandEmpty>No Sector found.</CommandEmpty>
                  <CommandGroup>
                    {caseList.map((c) => (
                      <CommandItem
                        key={c.id}
                        onSelect={() => {
                          setSelectedCaseId(c.id);
                          setSelectedCase(c);
                          setOpenSector(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedCaseId === c.id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {c.type}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        <Separator />
        <div className="px-4 my-2">
          {isFetchingWorkFlowLoading ? (
            <Skeleton className="h-[125px] w-full rounded-xl" />
          ) : (
            selectedCase && (
              <>
                {/* Add Workflow Dialog */}
                <div className="flex justify-end">
                  <Button onClick={() => setOpenDialog(true)}>
                    <Plus size={18} className="mr-2" />
                    Add Workflow
                  </Button>
                  {openDialog && (
                    <AddWorkFlowDialog
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      caseTypeId={selectedCase.id}
                      currentNumberOfWorkFlows={workflows?.length ?? 0}
                    />
                  )}
                </div>
                {selectedCaseId && (
                  <>
                    <h3>
                      <span className="font-bold">Description</span>
                      <br />
                      <span className="">{selectedCase.caseDescription}</span>
                    </h3>
                    <span className="font-bold">Workflows</span>
                    <WorkflowList selectedCaseId={selectedCaseId} />
                  </>
                )}
                {/* Workflows Section */}
                {workflows?.length === 0 ? (
                  // No workflows message
                  <div className="mt-3">
                    <p>You don't have any workflow for this case</p>
                  </div>
                ) : (
                  // Workflows list
                  <div className="mt-3 hidden">
                    <div className="mt-2 flex flex-col gap-3">
                      {workflows?.map((workflow) => (
                        <div
                          key={workflow.id}
                          className="border-[1px] rounded-sm"
                        >
                          <Accordion
                            type="single"
                            collapsible
                            className="w-full p-2"
                          >
                            <AccordionItem
                              value={workflow.id}
                              className="border-b-0"
                            >
                              <AccordionTrigger className="text-1xl accordion-trigger">
                                <Box
                                  display={"flex"}
                                  gap={1}
                                  justifyContent={"center"}
                                >
                                  {workflow.workFlowName}
                                </Box>
                              </AccordionTrigger>
                              <AccordionContent>
                                <div>
                                  <div className="flex gap-5 items-center my-2 justify-between w-90">
                                    <span className="font-bold">
                                      Workflow Details
                                    </span>
                                    <Button
                                      size={"sm"}
                                      variant={"outline"}
                                      className="mx-1"
                                      onClick={() => setOpenEditDialog(true)}
                                    >
                                      <Pencil size={14} className="mx-1" />
                                      Edit
                                    </Button>
                                  </div>
                                  <AddWorkFlowDialog
                                    workflow={workflow}
                                    caseTypeId={selectedCase.id}
                                    currentNumberOfWorkFlows={
                                      workflow.workFlowStep
                                    }
                                    openDialog={openEditDialog}
                                    setOpenDialog={setOpenEditDialog}
                                    isEditing
                                  />
                                  <WorkflowDetailHeader workflow={workflow} />
                                </div>

                                <WorkflowBody workflow={workflow} />
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </DefaultPage>
  );
};

export default Workflow;
