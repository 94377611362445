import { useState } from "react";
import Sidebar from "./sidebar";
import Header from "./topbar/Header";
import { Outlet } from "react-router-dom";
import { useGetOrganizationByIdQuery } from "@/features/organizations/api/organizationApi";
import { useAuth } from "@/lib/auth";
import { Separator } from "@radix-ui/react-dropdown-menu";

export const DefaultLayout = () => {
  const { session } = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {data: organization} = useGetOrganizationByIdQuery({url: session?.userInfo.organizationId})
  return (
    <div className="dark:bg-inherit bg-primary-foreground">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} organization={organization}/>
          <main>
            <div className="mx-auto max-w-screen-2xl p-2 md:p-2 2xl:p-6">
              <Outlet />
            </div>
          </main>
          <div className="w-full bg-[#FFFFFF]">
            {organization && (
              <div className="flex flex-col items-center mx-auto p-4 text-center">
                <div className="flex items-center gap-8">
                  <p>Address: {organization?.address}</p>
                  <p>Phone: {organization?.phoneNumber}</p>
                  <p>Email: {organization?.email}</p>
                </div>
                <Separator className="my-4 border w-full" />
                <p>&copy; {new Date().getFullYear()} Lifeplace Solutions. All rights reserved.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
