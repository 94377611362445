import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { ICustomer, ICustomerWithClient } from "../models/Customer";
import { IClientTask } from "@/features/task/models/Task";
import { ISector } from "@/features/workflows/Case/models/Case";
import { PaymentCollection } from "@/features/finance/paymentCollection/model/PaymentCollection";
import { PagedResponse } from "@/model/pagination-response";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<PagedResponse<ICustomer>, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params?.method ?? "GET",
        params: {...params.data},
      }),
      providesTags: ["Customers"], // Provides a tag for caching
    }),
    searchCustomers: builder.query<ICustomerWithClient, QueryRequest>({
      query: (params) => ({
        url: "/clients/SearchAllClients",
        method: "GET",
        params: {...params.data},
      }),
      providesTags: ["Customers"], // Provides a tag for caching
    }),
    getSectors: builder.query<ISector[], QueryRequest>({
      query: () => ({
        url: "/sectors",
        method: "GET",
      }),
      providesTags: ["Sectors"],
    }),
    getCustomersDetail: builder.query<ICustomer, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
      providesTags: ["Customer"],
    }),
    createCustomer: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Customers"],
    }),
    editCustomer: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Customer"],
    }),
    getCustomerTask: builder.query<IClientTask[], QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: "GET"
      }),
      providesTags: ["Task"],
    }),
    getClientFinance: builder.query<PaymentCollection[], QueryRequest>({
      query: (params) => ({
        url: "/clientFinance/GetAllFinancesByClient",
        method: "GET",
        params: params.data
      }),
      providesTags: ["PaymentCollection"],
    })
  }),
});

export const {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useCreateCustomerMutation,
  useGetCustomersDetailQuery,
  useEditCustomerMutation,
  useGetCustomerTaskQuery,
  useGetSectorsQuery,
  useSearchCustomersQuery,
  useGetClientFinanceQuery
} = customerApi;
