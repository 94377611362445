import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LoadingPage } from "@/features/alert/components/LoadingPage";
import { useGetTenderWorkFlowsQuery } from "../api/pipe-line-wf-query";
import { PipeLineWorkListByType } from "../pipeline-workflow/pages/PipeLineWorkListByType";

export const TenderWorkflowPage = () => {
  const [selectedTab, setSelectedTab] = useState("detail");

  const {
    data: workflows = [],
    isLoading,
    isSuccess,
  } = useGetTenderWorkFlowsQuery();

  useEffect(() => {
    if (isSuccess && workflows.length) {
      setSelectedTab(workflows[0].id);
    }
  }, [isSuccess, workflows]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <DefaultPageHeader
        headerTitle="Tender Workflow"
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: "Tender Workflow",
            url: "#",
          },
        ]}
      />
      {workflows.length ? (
        <Tabs className="" onValueChange={setSelectedTab} value={selectedTab}>
          <TabsList className="flex justify-start w-100">
            {workflows.map((workflow) => (
              <TabsTrigger value={workflow.id} key={workflow.id}>
                {workflow.workFlowName}
              </TabsTrigger>
            ))}
          </TabsList>

          {workflows.map((workflow) => (
            <TabsContent value={workflow.id} key={workflow.id}>
              <PipeLineWorkListByType workflowId={workflow.id} />
            </TabsContent>
          ))}
        </Tabs>
      ) : (
        <h1>There is no workflow available</h1>
      )}
    </>
  );
};
