import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { CancelledWorkflowBody } from "./CancelledWorkflowBody";

const WorkflowCancelStepper = () => {
  const workflows = ["Requested", "Canceled"];
  return (
    <>
      <Stepper activeStep={1}>
        {workflows.map((workflow) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          labelProps.error = true;
          return (
            <Step key={workflow} completed={true}>
              <StepLabel {...labelProps}>{workflow}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <CancelledWorkflowBody />
    </>
  );
};

export default WorkflowCancelStepper;
