import { LogOut, User } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../../avatar";
import { NavLink } from "react-router-dom";
import { useAuth } from "@/lib/auth";


export function DropDownUser() {
  const { logOut, session } = useAuth();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex justify-center gap-2">
        <Button
            variant="outline"
            size="icon"
            className="overflow-hidden rounded-full h-max"
          >
            <Avatar>
              <AvatarImage src="" alt="@shadcn" />
              <AvatarFallback>{session?.userInfo?.fullName?.[0]}</AvatarFallback>
            </Avatar>
          </Button>
          <div className="hidden lg:flex lg:flex-col cursor-pointer text-sm">
            <span className="font-semibold">
              {session?.userInfo.fullName}
            </span>
            <span className="font-normal">{session?.userInfo.username}</span>
          </div>
          
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 z-99999">
        <DropdownMenuGroup className="lg:hidden">
          <div className="p-2">
            <DropdownMenuItem className="flex flex-col items-start cursor-pointer">
              <p className="font-semibold">
                {session?.userInfo.fullName}
              </p>
              <p className="font-normal">{session?.userInfo.username}</p>
            </DropdownMenuItem>
          </div>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <NavLink to="/profile">
            <DropdownMenuItem className="cursor-pointer">
              <User className="mr-2 h-4 w-4" />
              <span>Profile</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => logOut()}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
