/* eslint-disable react-hooks/exhaustive-deps */
import { TaskCard } from "./TaskCard";
import { IAlert } from "../models/alert";
import EmptyState from "./EmptyState";
import LoadingIndicator from "./LoadingPage";
import { useGet7daysAlertsQuery } from "../api/alert-api";

const AlertCardList = (): JSX.Element => {
  const { data: alertList, isLoading: isAlertsFetchLoading, isFetching: isAlertsFetchFetching} = useGet7daysAlertsQuery()
  return (
    <>
      <div>
        {isAlertsFetchLoading ? (
          <LoadingIndicator />
        ) : alertList && alertList?.length > 0 ? (
          <div>
            <div
            className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4"
            >
              {isAlertsFetchFetching ? (
                <div className="mt-3">
                  <LoadingIndicator />
                </div>
              ) : (
                alertList?.map((taskItem: IAlert, index: number) => (
                  <>
                    <TaskCard
                      key={index}
                      task={taskItem}
                    />
                  </>
                ))
              )}
            </div>
          </div>
        ) : (
          <EmptyState description="No Task currently. Please check back later." />
        )}
      </div>
    </>
  );
};

export default AlertCardList;
