import { IWorkFlow } from "../../Case/models/workflow";
import { Badge } from "@/components/ui/badge";

type Props = {
  workflow: IWorkFlow;
};

export const WorkflowDetailHeader = ({ workflow }: Props) => {
  return (
    <table className=" w-90 text-left">
      <thead className="uppercase bg-[#6b7280] text-[#e5e7eb]"></thead>
      <tbody className="bg-white text-gray-500  ">
        <tr className="py-1">
          <td className="py-1 border text-start  p-4">Name</td>
          <td className="py-1 border text-start  p-4">
            {workflow.workFlowName}
          </td>
        </tr>
        <tr className="py-1">
          <td className="py-1 border   p-4">Final Status Name</td>
          <td className="py-1 border   p-4">{workflow.finalStatusName}</td>
        </tr>
        <tr className="py-1">
          <td className="py-1 border   p-4">Notify To Client</td>
          <td className="py-1 border   p-4">
            {workflow.notifyToClient ? "Yes" : "No"}
          </td>
        </tr>
        <tr className="py-1">
          <td className="py-1 border   p-4">Status</td>
          <td className="py-1 border   p-4">
            <Badge variant={workflow.isActive ? "default" : "destructive"}>
              {workflow.isActive ? "Active" : "Inactive"}
            </Badge>
          </td>
        </tr>
        {/* <tr className="py-1">
          <td className="py-1 border   p-4">Has Payment</td>
          <td className="py-1 border   p-4">
            <Badge variant={workflow.hasPayment ? "default" : "destructive"}>
              {workflow.hasPayment ? "Yes" : "No"}
            </Badge>
          </td>
        </tr>
        <tr className="py-1">
          <td className="py-1 border   p-4">Payment Amount</td>
          <td className="py-1 border   p-4">{workflow.paymentAmount}</td>
        </tr> */}
      </tbody>
    </table>
  );
};
