import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect } from "react";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ISalesWorkFlow } from "../../models/sales-wf-case";
import { useCopySalesWorkflowMutation } from "../../api/work-flow-command";
import { useGetActiveSalesWorkFlowQuery } from "../../api/work-flow-api";

const copyWorkflowFormSchema = z.object({
  sourceWorkflow: z.string({
    required_error: "Please select a source workflow!",
  }),
});

type CopyWorkflowFormValues = z.infer<typeof copyWorkflowFormSchema>;

type CopyCaseProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  targetWorkflow: ISalesWorkFlow
}

export const CopyWorkflowDialog = ({targetWorkflow, setOpenDialog, openDialog}: CopyCaseProps) => {
  const form = useForm<CopyWorkflowFormValues>({
    resolver: zodResolver(copyWorkflowFormSchema),
    mode: "onChange",
    // defaultValues,
  });

  const [copyWorkflow, { isLoading: isLoadingCopyCase, isSuccess: isSuccessCopyCase }] = useCopySalesWorkflowMutation()

  const { data: activeWorkflows = [] } = useGetActiveSalesWorkFlowQuery({});

  const onSubmit = async () => {
    await copyWorkflow({
      data: { 
        targetWorkFlowId: targetWorkflow.id,
        sourceWorkFlowId: form.getValues().sourceWorkflow
      }
    }
    )
  };

  useEffect(() => {
    if (isSuccessCopyCase) {
      setOpenDialog(false);
      form.reset();
      Notify("success", "Workflow Copied Successfully!")
    }
  }, [isSuccessCopyCase]);

  return (
    <DefaultDialog
      open={openDialog}
      title="Copy Workflow"
      onClose={() => setOpenDialog(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="sourceWorkflow"
          render={({ field }) => (
          <FormItem className='flex-1'>
              <FormLabel>Source Workflow</FormLabel>
              <FormControl>
              <Select
                  onValueChange={(value) => {
                  field.onChange(value);
                  }}
                  defaultValue={field.value ?? ""}
                  value={field.value ?? ""}
              >
                  <SelectTrigger className="">
                  <SelectValue placeholder="Select Source Workflow" />
                  </SelectTrigger>
                  <SelectContent className="z-[1400]">
                  {activeWorkflows.filter(i => i.id !== targetWorkflow.id).map((item) => (
                      <SelectItem key={item.id} value={item.id}>
                      {item.workFlowName}
                      </SelectItem>
                  ))}
                  </SelectContent>
              </Select>
              </FormControl>
              <FormMessage />
          </FormItem>
          )}
        />

          <div className="flex items-center">
            <CustomButton disabled={!form.formState.isValid} isLoading={isLoadingCopyCase} type="submit" className="w-full mx-auto">
              Submit
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
