import { configureStore } from "@reduxjs/toolkit";
import { appApi } from "./app-api";
import { GlobalErrorReducer } from "../lib/alert/store/error-slice";
import { authReducer } from "../lib/auth/store/auth.slice";

export const store = configureStore({
  reducer: {
    globalError: GlobalErrorReducer,
    auth: authReducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(appApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;