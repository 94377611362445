
import ErrorDialog from "./lib/alert/components/error-dialog";
import { Routing } from "./routes/Routing";
import { useAppSelector } from "./store/app-store-hooks";

function App() {
  const { error } = useAppSelector((state: any) => state.globalError);
  return (
    <>
      <Routing />
      {error && <ErrorDialog error={error} />}
    </>
  );
}

export default App
