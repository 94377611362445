import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useGetCustomerTaskQuery } from '../api/customer-api';
import { IClientTask } from '@/features/task/models/Task';
import { useNavigate } from 'react-router-dom';

const customerCaseListColumns: MRT_ColumnDef<IClientTask>[] = [
  {
    accessorKey: "taskReference",
    header: "Ref No.",
  },
  {
    accessorKey: "caseName",
    header: "Case",
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    Cell: ({ row }) => {
      return new Date(row.original.startDate).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "EndDate",
    Cell: ({ row }) => {
      if (!row.original.endDate) {
        return "N/A";
      }
      return new Date(row.original.endDate).toLocaleDateString();
    },
    enableGlobalFilter: true,
  },
  {
    accessorKey: "taskStatus",
    header: "Status",
  },
];

interface ClientPaymentProps {
    clientId: string
}
const ClientTask = ({clientId}: ClientPaymentProps) => {
    const navigate = useNavigate()
    const { data: customerTask, isLoading: isTaskLoading } = useGetCustomerTaskQuery({
      url: `/clients/${clientId}/Tasks`,
    });
      
  return (
    <MaterialReactTable
      columns={customerCaseListColumns}
      data={customerTask ?? []}
      enablePagination
      muiTableBodyRowProps={(row) => ({
        onClick: () => {
          navigate(`/task/operational-task/${row.row.original.id}`)
        },
        sx: {
          cursor: 'pointer',
        }
      })}
      state={{isLoading: isTaskLoading}}
    />
)
}

export default ClientTask
