import { Box } from "@mui/material";

export const CancelledWorkflowBody = () => {
  return (
    <div>
      <Box display={"flex"} flexDirection={"column"} gap={1} mt={2}>
        <div className="rounded-lg h-32  bg-red-500 text-[#ffffff]">
          <div className="flex flex-row  gap-5  items-center px-5 w-full h-full">
            <div className="my-auto text-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-x-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="m15 9-6 6"></path>
                <path d="m9 9 6 6"></path>
              </svg>
            </div>
            <div>
              <div className="font-bold text-2xl">Workflow is Cancelled</div>
              <div className=" text-lg">
                The current workflow is cancelled please create a new
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};
