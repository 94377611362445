import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { useParams } from "react-router-dom";
import {
  useActivateUserMutation,
  useChangePasswordMutation,
  useDeActivateUserMutation,
  useGetUserDetailQuery,
} from "../api/user-api";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PasswordInput } from "@/components/ui/passwordInput";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useEffect, useState } from "react";
import EditUser from "../componets/EditUser";
import { Notify } from "@/components/shared/Notification/notify";
import { UserHeader } from "../componets/UserHeader";
import EditUserRole from "../componets/EditUserRole";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import EditUserClient from "../componets/EditUserClient";

const userFormSchema = z.object({
  oldPassword: z.string().nonempty("Old password is required"),
  newPassword: z
    .string()
    .nonempty("New Password is required")
    .min(8, "New password must be at least 8 characters long"),
});

type UserFormValues = z.infer<typeof userFormSchema>;

const defaultValues: Partial<UserFormValues> = {
  oldPassword: "",
  newPassword: "",
};

const UserDetails = () => {
  const { id } = useParams();
  const { data: userDetail } = useGetUserDetailQuery({
    url: `/users/${id}`,
    method: "GET",
  });
  const [
    ChangePassword,
    { isLoading: isPasswordChangeLoading, isSuccess: isPasswordChangeSuccess },
  ] = useChangePasswordMutation();
  const [
    DeactivateUser,
    { isLoading: isDeActivateUserLoading, isSuccess: isDeActivateUserSuccess },
  ] = useDeActivateUserMutation();

  const [
    ActivateUser,
    { isLoading: isActivateUserLoading, isSuccess: isActivateUserSuccess },
  ] = useActivateUserMutation();

  const [openEditUser, setOpenEditUser] = useState<boolean>(false);
  const [openActivateDeActivateUser, setOpenActivateDeActivateUser] = useState<boolean>(false);
  const [openEditUserRole, setOpenEditUserRole] = useState<boolean>(false);
  const [openEditUserClient, setOpenEditUserClient] = useState<boolean>(false);

  const onSubmit = async (data: UserFormValues) => {
    await ChangePassword({
      url: "/users/ChangePassword",
      method: "POST",
      data: {
        userId: id,
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
    });
  };

  const onDeActivateUser = async (id: string) => {
    await DeactivateUser({
      data: {
        id: id
      },
    });
  };

  const onActivateUser = async (id: string) => {
    await ActivateUser({
      data: {
        id: id,
      },
    });
  };

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (isPasswordChangeSuccess) {
      Notify("success", "Password Changed Successfully!");
    }
  }, [isPasswordChangeSuccess]);

  useEffect(() => {
    if (isDeActivateUserSuccess) {
      setOpenActivateDeActivateUser(false)
      Notify("success", "User Deactivated successfully");
    }
  }, [isDeActivateUserSuccess]);


  useEffect(() => {
    if (isActivateUserSuccess) {
      setOpenActivateDeActivateUser(false)
      Notify("success", "User Activated successfully");
    }
  }, [isActivateUserSuccess]);

  return (
    <>
      <DefaultPageHeader headerTitle="User Detail" 
       breadcrumbsLinks={[
        {
          name: "Home",
          url: "/",
        },
        {
          name: "User List",
          url: "/settings/users/list",
        }]}
      />
      <div className="bg-white my-4 flex flex-col gap-8 rounded-lg">
        {userDetail && (
          <UserHeader
            user={userDetail}
            showEditButton={true}
            showEditRole={true}
            showEditClient={true}
            showActivateDeActivate={true}
            onEditRoleClick={() => {
              setOpenEditUserRole(true);
            }}
            onEditClientClick={() => {
              setOpenEditUserClient(true);
            }}
            onEditClick={() => {
              setOpenEditUser(true);
            }}
            onActivateDeActivate={() => {
              setOpenActivateDeActivateUser(true)
            }}
          />
        )}
      </div>
      <div className="bg-white my-4 flex flex-col gap-8 shadow rounded-lg">
        <div className="py-4 border-b-2">
          <p className="px-4">Change Password</p>
        </div>
        <div>
          <Form {...form}>
            <form
              className="mx-4 flex flex-col gap-4 lg:w-1/2 lg:mx-auto pb-4"
              onSubmit={form.handleSubmit((data) => onSubmit(data))}
            >
              <FormField
                control={form.control}
                name="oldPassword"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Old Password</FormLabel>
                    <FormControl>
                      <PasswordInput {...field} placeholder="Enter Password" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>New Password</FormLabel>
                    <FormControl>
                      <PasswordInput {...field} placeholder="Enter Password" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <CustomButton
                disabled={!form.formState.isValid}
                isLoading={isPasswordChangeLoading}
                className="self-center"
                type="submit"
              >
                Change Password
              </CustomButton>
            </form>
          </Form>
        </div>
      </div>
      {openEditUser && userDetail && (
        <EditUser
          openForm={openEditUser}
          setOpenForm={setOpenEditUser}
          user={userDetail}
        />
      )}
      {openEditUserRole && userDetail && (
        <EditUserRole
          openForm={openEditUserRole}
          setOpenForm={setOpenEditUserRole}
          user={userDetail}
        />
      )}
      {openEditUserClient && userDetail && (
        <EditUserClient
          openForm={openEditUserClient}
          setOpenForm={setOpenEditUserClient}
          user={userDetail}
        />
      )}
      {
      openActivateDeActivateUser && userDetail && (
        <ConfirmModal
        title={userDetail.isActive ? "Deactivate User" : "Activate User"}
        message={userDetail.isActive ? "Are you sure want to deactivate this user" : "Are you sure want to activate this user"}
        modalOpen={openActivateDeActivateUser}
        onCancel={() => setOpenActivateDeActivateUser(false)}
        onOk={() => userDetail.isActive ? onDeActivateUser(userDetail.id) : onActivateUser(userDetail.id)}
        loading={isDeActivateUserLoading || isActivateUserLoading}
        setModalOpen={() => setOpenActivateDeActivateUser(false)}
        type="error"
        />
      )}
    </>
  );
};

export default UserDetails;
