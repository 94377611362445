import { ISector } from "../models/Case";
import { DataTable } from "@/components/shared/DataTable/DataTable";

import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useUpdateSectorMutation } from "../api/case-api";
import CustomButton from "@/components/shared/Button/CustomButton";
import { IconButton } from "@mui/material";
import { useDeleteSectorMutation } from "../../api/work-flow-command";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { CopySectorDialog } from "./CopySector";

type Props = {
  sectors: ISector[];
};

export function SectorList({ sectors }: Props) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [sectorName, setSectorName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedSector, setSelectedSector] = useState<ISector | null>(null);

  const [updateSector, { isSuccess: isCreatingCaseSuccess, isLoading }] =
    useUpdateSectorMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedSector === null) return;
    await updateSector({
      data: {
        ...selectedSector,
        sectorName: sectorName,
        description: description,
      },
    });
  };
  useEffect(() => {
    if (isCreatingCaseSuccess) {
      setSectorName("");
      setDescription("");
      setOpenDialog(false);
      setSelectedSector(null);
    }
  }, [isCreatingCaseSuccess, setOpenDialog]);
  // useEffect(() => {
  //   if (isError) {
  //     setOpenDialog(false);
  //     // reload the page to get the updated data
  //     window.location.reload();
  //   }
  // }, [isError]);
  const sectorListColumns: ColumnDef<ISector>[] = [
    {
      accessorKey: "sectorName",
      header: "Name",
    },
    {
      header: "Description",
      accessorFn: (row) => row.description,
    },
    {
      accessorKey: "isActive",
      header: "Status",
      cell: ({ row }) => {
        return (
          <Badge variant={row.original.isActive ? "default" : "destructive"}>
            {row.original.isActive ? "Active" : "In Active"}
          </Badge>
        );
      },
    },

    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div className="flex gap-4 items-center">
          <Button 
            variant={"outline"}
            onClick={() => {
              setOpenCopyDialog(true);
              setSelectedSector(row.original);
            }}
            >
              <Copy size={14} className="mr-2" />Copy
            </Button>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setOpenDialog(true);
                setSelectedSector(row.original);
                setSectorName(row.original.sectorName);
                setDescription(row.original.description);
              }}
            >
              <Pencil size={14} className="mr-2" />
              edit
            </Button>
            <IconButton
              onClick={() => {
                setSelectedSector(row.original);
                setOpenConfirmModal(true);
              }}
            >
              <Trash2 color="red" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [
    deleteSector,
    { isLoading: isSectorDeletingLoading, isSuccess: isSectorDeletingSuccess },
  ] = useDeleteSectorMutation();

  const handleDeleteCase = async () => {
    if (selectedSector) {
      try {
        await deleteSector(selectedSector.id);
        setOpenConfirmModal(false);
      } catch (error) {
        console.error("Failed to delete parameter", error);
      }
    }
  };
  useEffect(() => {
    if (isSectorDeletingSuccess) {
      setOpenConfirmModal(false);
      setSelectedSector(null);
    }
  }, [isCreatingCaseSuccess, isSectorDeletingSuccess]);

  return (
    <div className="rounded-md border">
      <DataTable<ISector> columns={sectorListColumns} data={sectors} />
      {openDialog && (
        <DefaultDialog
          title="Add Sector"
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setSectorName("");
            setDescription("");
            setSelectedSector(null);
          }}
        >
          <div className="mt-4">
            <form onSubmit={onSubmit}>
              <div className="flex flex-col gap-2">
                <Input
                  placeholder="sector name"
                  required
                  value={sectorName}
                  onChange={(e) => setSectorName(e.target.value)}
                />
                <Textarea
                  placeholder="description"
                  className="resize-none"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <div className="flex items-center">
                  <CustomButton
                    type="submit"
                    className="w-50 mx-auto"
                    isLoading={isLoading}
                  >
                    Submit
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        </DefaultDialog>
      )}
      {
        openCopyDialog && selectedSector && (
          <CopySectorDialog
          openDialog={openCopyDialog} 
          setOpenDialog={(value: boolean) => setOpenCopyDialog(value)} 
          targetSector={selectedSector}
          />
        )
      }

      {openConfirmModal && (
        <ConfirmModal
          message="Are you Sure you want to delete?"
          title="Delete Sector"
          modalOpen={openConfirmModal}
          setModalOpen={() => {}}
          type="error"
          customOkText="Delete"
          onCancel={() => {
            setOpenConfirmModal(false);
            setSelectedSector(null);
          }}
          onOk={() => handleDeleteCase()}
          loading={isSectorDeletingLoading}
        />
      )}
    </div>
  );
}
