import { useEffect, useMemo, useState } from "react";
import { IWorkFlowParameter } from "../../Case/models/workflow";
// import { AddSubWorkFlowDialog } from "./AddSubWorkFlowDialog";
import {
  MRT_ColumnDef,
  MRT_TableContainer,
  useMaterialReactTable,
} from "material-react-table";
import { useLazyGetSalesWorkFlowParametersByWorkFlowIdQuery } from "../../api/work-flow-api";
import { AddParameterDialog } from "./AddParameterDialog";
import { EditIcon, Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditParameterDialog, { ParameterFormValues } from "./EditParamterDialog";
import { Badge } from "@/components/ui/badge";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import {
  useDeleteSalesWorkFlowParameterMutation,
  useUpdateSalesParameterOrderNoMutation,
} from "../../api/work-flow-command";
import { NotificationOptions } from "@/model/enums";
import { ISalesWorkFlow } from "../../models/sales-wf-case";
import { Notify } from "@/components/shared/Notification/notify";

type Props = {
  workflow: ISalesWorkFlow;
};

const WorkflowBody = ({ workflow }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fetchParameter, { data: ParameterList = [] }] =
    useLazyGetSalesWorkFlowParametersByWorkFlowIdQuery();
  const [initialData, setInitialData] = useState<IWorkFlowParameter[]>([]);

  useEffect(() => {
    fetchParameter({ data: { workFlowId: workflow.id } });
  }, [workflow.id, fetchParameter]);
  const columns = useMemo<MRT_ColumnDef<IWorkFlowParameter>[]>(
    () => [
      {
        accessorKey: "parameterName",
        header: "Name",

        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },
      {
        accessorKey: "parameterType",
        header: "Type",

        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },
      {
        accessorKey: "paramaterValues",
        header: "Values",
        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },

      {
        accessorKey: "isMultipleUserParticipated",
        header: "Multiple User Participated",
        Cell: ({ row }) => {
          return <>{row.original.isMultipleUserParticipated ? "Yes" : "No"}</>;
        },
        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },

      {
        accessorKey: "isRequired",
        header: "Is Required",
        Cell: ({ row }) => {
          return <>{row.original.isRequired ? "Yes" : "No"}</>;
        },
        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },
      {
        accessorKey: "notificationOption",
        header: "Notification Option",

        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ row }) => {
          return (
            <Badge variant={row.original.isActive ? "default" : "destructive"}>
              {row.original.isActive ? "Active" : "In Active"}
            </Badge>
          );
        },
        muiTableHeadCellProps: {
          sx: {
            fontWeight: "normal",
          },
        },
      },
    ],
    []
  );
  // State for editing
  const [editingParameter, setEditingParameter] =
    useState<ParameterFormValues | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [data, setData] = useState(ParameterList);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    getRowId: (row) => row.id,
    enableRowOrdering: true,
    enableSorting: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();

        const draggingIndex = draggingRow?.index;
        const hoveredIndex = hoveredRow?.index;

        if (
          typeof draggingIndex === "number" &&
          typeof hoveredIndex === "number"
        ) {
          setData((prevData) => {
            const newData = [...prevData];
            const [movedItem] = newData.splice(draggingIndex, 1);
            newData.splice(hoveredIndex, 0, movedItem);
            return newData;
          });
        }
      },
    }),
    enableRowActions: true,
    positionActionsColumn: "last",

    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              const paramaterValues = row.original.paramaterValues
                ? JSON.parse(row.original.paramaterValues)
                : [];
              setEditingParameter({
                parameterName: row.original.parameterName,
                parameterType: row.original.parameterType as
                  | "number"
                  | "boolean"
                  | "select"
                  | "text"
                  | "date"
                  | "multiselect",
                paramaterValues: paramaterValues.map((value: string) => ({
                  value,
                })),
                workFlowId: row.original.workFlowId,
                isMultipleUserParticipated:
                  row.original.isMultipleUserParticipated || false,

                isActive: row.original.isActive,
                id: row.original.id,
                orderNo: row.original.orderNo,
                // hasPayment: row.original.hasPayment,
                // paymentAmount: Number(row.original.paymentAmount),
                notificationOption: row.original
                  .notificationOption as unknown as NotificationOptions,
                notifyToClient: row.original.notifyToClient,
                isFixed: row.original.isFixed,
              });
              setIsEditDialogOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setSelectedParameter(row.original);
              setOpenConfirmModal(true);
            }}
          >
            <Trash2 color="red" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });
  
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [selectedParameter, setSelectedParameter] =
    useState<IWorkFlowParameter | null>(null);

  const [
    deleteParameter,
    {
      isLoading: isParameterDeletingLoading,
      isSuccess: isParameterDeletingSuccess,
    },
  ] = useDeleteSalesWorkFlowParameterMutation();

  const handleDeleteParameter = async () => {
    if (selectedParameter) {
        await deleteParameter(selectedParameter.id);
    }
  };

  useEffect(() => {
    if (isParameterDeletingSuccess) {
      setOpenConfirmModal(false);
      setSelectedParameter(null);
      Notify('success', "Parameter Deleted Successfully!")
    }
  }, [isParameterDeletingSuccess]);

  useEffect(() => {
    if (ParameterList) {
      const fetchedData = [...ParameterList];
      setData(fetchedData);
      setInitialData(fetchedData);
    }
  }, [ParameterList]);

  const [updateParameterOrderNo] = useUpdateSalesParameterOrderNoMutation();

  const hasOrderChanged = useMemo(() => {
    if (initialData.length !== data.length) return false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== initialData[i].id) {
        return true;
      }
    }
    return false;
  }, [data, initialData]);

  const handleSaveOrder = async () => {
    try {
      const orders: { id: string; orderNo: number }[] = [];
      data.forEach((item, index) => {
        orders.push({ id: item.id, orderNo: index });
      });
      await updateParameterOrderNo({ data: orders });
      setInitialData([...data]);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Failed to save order:", error);
    }
  };

  return (
    <>
      <div className="p-2">
        <div className="m-2">
          <Button onClick={() => setOpenDialog(true)}>
            <Plus size={18} className="mr-2" />
            Add Parameter
          </Button>
          <AddParameterDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            workflow={workflow}
            currentNumberOfWorkFlows={ParameterList.length}
          />
        </div>
        <MRT_TableContainer table={table} />

        {hasOrderChanged && (
          <Button
            variant="outline"
            size="sm"
            className="mt-3"
            onClick={handleSaveOrder}
          >
            Save Order
          </Button>
        )}
        <EditParameterDialog
          open={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setEditingParameter(null);
          }}
          parameter={editingParameter}
        />

        {openConfirmModal && (
          <ConfirmModal
            message="Are you Sure you want to delete this parameter"
            title="Delete Parameter"
            modalOpen
            setModalOpen={() => {}}
            type="error"
            customOkText="Delete"
            onCancel={() => {
              setOpenConfirmModal(false);
              setSelectedParameter(null);
            }}
            onOk={() => handleDeleteParameter()}
            loading={isParameterDeletingLoading}
          />
        )}
      </div>
    </>
  );
};

export default WorkflowBody;
