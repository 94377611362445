import { SetStateAction, useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/shared/PhoneInput/phone-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  useCreateCustomerMutation,
  useGetSectorsQuery,
} from "../api/customer-api";
import CustomButton from "@/components/shared/Button/CustomButton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Notify } from "@/components/shared/Notification/notify";
import { Button } from "@/components/ui/button";
import { PlusCircleIcon, MinusCircleIcon, CalendarIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { useAuth } from "@/lib/auth";
import { FullScreenDialog } from "@/components/shared/DefaultDailog/FullPageDialog";
import { COUNTRIES_LIST } from "@/lib/const/country-list";
import FormAutoCompleteSelect from "@/components/form/FormAutoCompleteSelect";
import ReactSelect from 'react-select'; // Importing react-select as an alias
import { useGetSourceQuery } from "@/features/sales/source/api/api";
import { ISource } from "@/features/sales/source/model/source";


// Define the Zod schema
const customerFormSchema = z
  .object({
    customerType: z.enum(["Person", "Organization", "Other"]),
    customerSector: z.array(z.string()).min(1, "At least one sector is required"),
    customerName: z.string().nonempty("Customer Name is required"),
    customerEmail: z
      .string()
      .email("Invalid email address")
      .nonempty("Email is required"),
    customerPhoneNumbers: z
      .array(
        z
          .string()
          .nonempty("Phone number is required")
          .refine(isValidPhoneNumber, {
            message: "Invalid phone number",
          })
      )
      .min(1, "At least one phone number is required"),
    customerCountry: z.string().nonempty("Country is required"),
    region: z.string().nonempty("Region is required"),
    customerAddress: z.string().nonempty("Address is required"),
    customerDOB: z.date().optional(), // Set as optional here
    customerVATNo: z.string().nonempty("VAT Number is required"),
    customerTIN: z.string().nonempty("TIN is required"),
    customerContactPerson: z.string().nonempty("Contact Person is required"),
    customerContactPersonPhoneNumebr: z
      .string()
      .nonempty("Contact Person's Phone Number is required")
      .refine(isValidPhoneNumber, {
        message: "Invalid phone number",
      }),
    creditLimit: z.number({ invalid_type_error: "Credit Limit is required" }),
    source: z.array(z.string()).min(1, "At least one source is required"),
  })
  .refine((data) => data.customerType !== "Person" || !!data.customerDOB, {
    path: ["customerDOB"],
    message: "Date of Birth is required for customers of type 'Person'",
  });

// Define the type for the form values
type CustomerFormValues = z.infer<typeof customerFormSchema>;

const defaultValues: Partial<CustomerFormValues> = {
  customerType: "Person",
  customerSector: [],
  customerName: "",
  customerEmail: "",
  customerPhoneNumbers: [""], // Initialize with one empty phone number
  customerCountry: "",
  customerAddress: "",
  customerVATNo: "",
  customerTIN: "",
  customerContactPerson: "",
  customerContactPersonPhoneNumebr: "",
  region: "",
  source: []
};

type AddCustomerProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
};

const customerTypes = [
  {
    label: "Person",
    value: "Person",
  },
  {
    label: "Organization",
    value: "Organization",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const AddCustomer = ({ openForm, setOpenForm }: AddCustomerProps) => {
  const { session } = useAuth();
  const [CreateCustomer, { isLoading, isSuccess }] =
    useCreateCustomerMutation();
  const { data: sectors = [] } = useGetSectorsQuery({});
  const { data: sourceList}= useGetSourceQuery({})

  // UseForm setup with Zod schema
  const form = useForm<CustomerFormValues>({
    resolver: zodResolver(customerFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const {
    formState: { errors },
    reset,
  } = form;

  const onSubmit = async (data: CustomerFormValues) => {
    const payload: any = {
      clientType: data.customerType,
      clientName: data.customerName,
      phoneNumbers: phoneNumbers, // Send multiple phone numbers
      email: data.customerEmail,
      address: data.customerAddress,
      sectorIds: data.customerSector,
      vatNo: data.customerVATNo,
      tinNo: data.customerTIN,
      contactPerson: data.customerContactPerson,
      contactNumber: data.customerContactPersonPhoneNumebr,
      country: data.customerCountry,
      creditLimit: data.creditLimit || 0,
      // creditUsedSoFar: data.creditUsedSoFar || 0,
      organizationId: session?.userInfo.organizationId,
      region: data.region,
      clientSources: data.source
    };

    // Conditionally add the dateOfBirth field if customerType is 'Person' (use the actual value for 'Person')
    if (data.customerType === "Person") {
      payload.dateOfBirth = data.customerDOB; // Use the actual date from the form
    }

    await CreateCustomer({
      url: "/clients",
      method: "POST",
      data: payload,
    });
  };

  // Add another phone number input field
  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };

  // Remove a phone number input field
  const removePhoneNumber = (index: number) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  // Update phone number value in state
  const updatePhoneNumber = (value: string, index: number) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  // Watch the customerType field to conditionally render the date of birth field
  const customerType = form.watch("customerType");

  // Sync `phoneNumbers` state with the form field values
  // Synchronize form's phone numbers array with local state
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    form.getValues("customerPhoneNumbers")
  );

  useEffect(() => {
    form.setValue("customerPhoneNumbers", phoneNumbers);
  }, [phoneNumbers, form]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpenForm(false);
      Notify("success", "Customer Created Successfully!");
    }
  }, [isSuccess]);

  return (
    <FullScreenDialog
      title="Add Customer"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          className="px-2 py-8 lg:px-8 space-y-8 flex flex-col gap-4"
          onSubmit={form.handleSubmit((data) => onSubmit(data))}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Customer Type */}
            <FormField
              control={form.control}
              name="customerType"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Customer Type</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Customer type" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {customerTypes.map((type, index) => (
                          <SelectItem key={index} value={type.value}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
           {/* Customer Sector (Multi-Select) */}
           <FormField
              control={form.control}
              name="customerSector"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Sector</FormLabel>
                  <FormControl>
                    <ReactSelect
                      isMulti
                      options={sectors.map((item) => ({
                        label: item.sectorName,
                        value: item.id,
                      }))}
                      value={field.value.map((id) => ({
                        label: sectors.find((item) => item.id === id)?.sectorName || id,
                        value: id,
                      }))}
                      onChange={(selected) => field.onChange(selected.map((s) => s.value))}
                      placeholder="Select Sectors"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Customer Name */}
            <FormField
              control={form.control}
              name="customerName"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Customer Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Customer Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Customer Email */}
            <FormField
              control={form.control}
              name="customerEmail"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Customer Email</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      {...field}
                      placeholder="Customer Email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Phone Numbers (Multiple) */}
            {phoneNumbers.map((phoneNumber, index) => (
              <div key={index} className="flex items-center gap-2">
                <FormItem className="flex-1">
                  <FormLabel>Phone Number {index + 1}</FormLabel>
                  <div className="flex gap-2 items-center w-full">
                    <div className="relative flex-1">
                      <PhoneInput
                        value={phoneNumber}
                        onChange={(value) =>
                          updatePhoneNumber(value || "", index)
                        }
                        className="flex-1"
                      />
                      {phoneNumbers.length > 1 && (
                        <Button
                          variant={"destructive"}
                          className="absolute -top-2 right-0 rounded-full w-min h-min p-0"
                        >
                          <MinusCircleIcon
                            className="h-5 w-5"
                            onClick={() => removePhoneNumber(index)}
                          />
                        </Button>
                      )}
                    </div>
                    {phoneNumbers.length - 1 === index && (
                      <Button className="rounded-full bg-primary w-min h-min p-0">
                        <PlusCircleIcon
                          className="h-5 w-5"
                          onClick={addPhoneNumber}
                        />
                      </Button>
                    )}
                  </div>
                  {errors.customerPhoneNumbers?.[index] && (
                    <p className="text-red-500">
                      {errors.customerPhoneNumbers[index]?.message}
                    </p>
                  )}
                </FormItem>
              </div>
            ))}
            {/* Customer Country */}
            <FormAutoCompleteSelect
              control={form.control}
              name="customerCountry"
              options={COUNTRIES_LIST}
              label="Country"
            />

            <FormField
              control={form.control}
              name="region"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Region</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Region" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Customer Address */}
            <FormField
              control={form.control}
              name="customerAddress"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Address" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Conditionally render Date of Birth for "Person" */}
            {customerType === "Person" && (
              <FormField
                control={form.control}
                name="customerDOB"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date of birth</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-auto p-0 z-9999"
                        align="start"
                      >
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date > new Date() || date < new Date("1900-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {/* Customer VAT Number */}
            <FormField
              control={form.control}
              name="customerVATNo"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>VAT No.</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="VAT No." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Customer TIN */}
            <FormField
              control={form.control}
              name="customerTIN"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>TIN</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="TIN" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Contact Person Name */}
            <FormField
              control={form.control}
              name="customerContactPerson"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Contact Person Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Contact Person Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Contact Person Phone Number */}
            <FormField
              control={form.control}
              name="customerContactPersonPhoneNumebr"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Contact Person Phone Number</FormLabel>
                  <FormControl>
                    <PhoneInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="creditLimit"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Credit Limit</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Credit Limit"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))} // Manually convert to number
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <FormField
              name="creditUsedSoFar"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Credit Used</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Credit Used"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))} // Manually convert to number
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
             {/* Customer Sector (Multi-Select) */}
           <FormField
              control={form.control}
              name="source"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Source</FormLabel>
                  <FormControl>
                    <ReactSelect
                      isMulti
                      options={sourceList?.map((item:ISource) => ({
                        label: item.sourceName,
                        value: item.id,
                      }))}
                      value={field.value.map((id) => ({
                        label: sourceList?.find((item:ISource) => item.id === id)?.sourceName || id,
                        value: id,
                      }))}
                      onChange={(selected) => field.onChange(selected.map((s) => s.value))}
                      placeholder="Select Source"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Submit button */}
          <CustomButton
            isLoading={isLoading}
            className="lg:self-end"
            type="submit"
          >
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </FullScreenDialog>
  );
};

export default AddCustomer;
