import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Switch } from "@/components/ui/switch"; // Adjust the import path as necessary
import { Label } from "@/components/ui/label"; // Adjust the import path as necessary

interface Props<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label: string;
  orientation?: "row" | "row-reverse" | "column";
  rules?: RegisterOptions;
  id?: string;
}

export const FormCheckboxWithSwitch = <T extends FieldValues>({
  name,
  control,
  label,
  orientation = "row",
  id,
}: Props<T>) => {
  return (
    <div
      className={`mt-4 flex items-center gap-3 ${
        orientation === "row-reverse"
          ? "flex-row-reverse"
          : orientation === "column"
          ? "flex-col"
          : "flex-row"
      }`}
    >
      <Label htmlFor={id || name} className="text-left">
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Switch
              checked={field.value}
              onCheckedChange={(checked) => field.onChange(checked)}
              id={id || name}
            />
            {error && (
              <p className="text-red-500 text-sm mt-1">{error.message}</p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default FormCheckboxWithSwitch;
