import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";
import { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PipeLineWorkListByType } from "./pages/PipeLineWorkListByType";
import { useGetPipelineWorkFlowsQuery } from "../api/pipe-line-wf-query";
import { LoadingPage } from "@/features/alert/components/LoadingPage";

export const PipeLineWorkflowPage = () => {
  const [selectedTab, setSelectedTab] = useState("detail");

  const {
    data: workflows = [],
    isLoading,
    isSuccess,
  } = useGetPipelineWorkFlowsQuery();

  useEffect(() => {
    if (isSuccess && workflows.length) {
      setSelectedTab(workflows[0].id);
    }
  }, [isSuccess, workflows]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <DefaultPageHeader
        headerTitle="Pipe Line Workflow"
        breadcrumbsLinks={[
          {
            name: "Home",
            url: "/",
          },
          {
            name: "Pipe Line Workflow",
            url: "#",
          },
        ]}
      />
      {workflows.length ? (
        <Tabs
          // defaultValue={workflows[0].id}
          className=""
          onValueChange={setSelectedTab}
          value={selectedTab}
        >
          <TabsList className="flex justify-start w-100">
            {workflows.map((workflow) => (
              <TabsTrigger value={workflow.id} key={workflow.id}>
                {workflow.workFlowName}
              </TabsTrigger>
            ))}
          </TabsList>

          {workflows.map((workflow) => (
            <TabsContent value={workflow.id} key={workflow.id}>
              <PipeLineWorkListByType workflowId={workflow.id} />
            </TabsContent>
          ))}
        </Tabs>
      ) : (
        <h1>There is no workflow available</h1>
      )}
    </>
  );
};
