import React, { SetStateAction, useEffect } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from '@/components/shared/Button/CustomButton';
import { Autocomplete, TextField } from '@mui/material';
import { useEditRoleMutation, useGetPermissionsQuery } from '../api/user-api';
import { IRole } from '../models/Role';
import { Notify } from '@/components/shared/Notification/notify';

const roleFormSchema = z.object({
  roleName: z.string().nonempty("Role Name is required"),
  description: z.string().nonempty("Description is required"),
  permissions: z.array(z.object({
    id: z.string(),
    permissionName: z.string(),
  })).nonempty("At least one permission is required"),
});

type RoleFormValues = {
  roleName: string;
  description: string;
  permissions: { id: string; permissionName: string }[];
};

type EditRoleProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  role: IRole
};

const EditRole = ({ openForm, setOpenForm, role }: EditRoleProps) => {
  const [editRole, { isLoading, isSuccess }] = useEditRoleMutation();
  const { data: permissionsList } = useGetPermissionsQuery({
    url: "/permissions",
    method: "GET",
  });

  const defaultValues: RoleFormValues = {
    roleName: role.roleName,
    description: role.description,
    permissions: role.permissions ?? [], // Assuming role.permissions contains an array of permissions
  };

  const form = useForm<RoleFormValues>({
    resolver: zodResolver(roleFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: RoleFormValues) => {
    await editRole({
      url: `/roles/updateRole`,
      method: "PUT",
      data: {
        role: {
          id: role.id,
          roleName: data.roleName,
          description: data.description,
        },
        permissionIds: data.permissions.map(p => p.id) // Send selected permission IDs
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "Role Edited Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Edit Role" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="p-2 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="roleName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Role Name</FormLabel>
                <FormControl>
                  <Input placeholder="Role Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input placeholder="Description" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="permissions"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Permissions</FormLabel>
                <FormControl>
                  <Autocomplete
                    multiple
                    options={permissionsList || []}
                    getOptionLabel={(option) => option.permissionName}
                    value={field.value} // Selected permissions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      field.onChange(newValue); // Update form value with selected permissions
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Permissions" variant="outlined" />
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <CustomButton disabled={isLoading || !form.formState.isValid} isLoading={isLoading} className="lg:self-center" type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditRole;
