import FormSelect from "@/components/form/FormSelect";
import FormTextarea from "@/components/form/FormTextarea";
import CustomButton from "@/components/shared/Button/CustomButton";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Form } from "@/components/ui/form";
// import { Label } from "@/components/ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateGenericTaskActivityMutation } from "../api/generic-task-command";
import { ITaskDetail } from "../model/GenericTask";
import { useEffect } from "react";
import FormNumberInput from "@/components/form/FormNumberInput";
import FormCheckboxWithSwitch from "@/components/form/FormCheckboxWithSwitch";

const parameterFormSchema = z.object({
  id: z.string().optional(),
  value: z.union([z.string(), z.number(), z.boolean()]).optional(),
});

export type ParameterFormValues = z.infer<typeof parameterFormSchema>;

type Props = {
  openDialog: boolean;
  onClose: (open: boolean) => void;
  taskId: string;
  isEditing?: boolean;
  taskDetail?: ITaskDetail | null;
};

export const UpdateGenericActivityValueDialog = ({
  openDialog,
  onClose,
  taskDetail,
}: Props) => {
  const form = useForm<ParameterFormValues>({
    resolver: zodResolver(parameterFormSchema),
    mode: "onChange",
    defaultValues: {
      value: taskDetail?.value ?? "",
      id: taskDetail?.id ?? "",
    },
  });
  const [updateActivity, { isSuccess, isLoading }] =
    useUpdateGenericTaskActivityMutation();

  const onSubmit = async () => {
    const payload: Partial<ITaskDetail> = {
      id: form.getValues("id") ?? "",
      value: String(form.getValues("value") ?? ""),
    };
    await updateActivity({ ...taskDetail, ...payload });
  };
  useEffect(() => {
    if (isSuccess) {
      onClose(false);
      form.reset();
    }
  }, [form, isSuccess, onClose]);

  return (
    <DefaultDialog
      open={openDialog}
      onClose={() => onClose(false)}
      title="Add Activity"
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-4">
          {taskDetail?.parameterType === "select" && (
            <FormSelect
              control={form.control}
              name="value"
              options={JSON.parse(taskDetail.paramaterValues).map(
                (item: string) => ({
                  label: item,
                  value: item,
                })
              )}
              label={taskDetail.activity}
            />
          )}
          {taskDetail?.parameterType === "text" && (
            <FormTextarea
              control={form.control}
              name="value"
              label={taskDetail.activity}
            />
          )}
          {taskDetail?.parameterType === "number" && (
            <FormNumberInput
              control={form.control}
              name="value"
              label={taskDetail.activity}
            />
          )}
          {taskDetail?.parameterType === "boolean" && (
            <FormCheckboxWithSwitch
              control={form.control}
              name="value"
              label={taskDetail.activity}
            />
          )}

          <div className="flex gap-3 justify-end mt-3">
            <CustomButton
              variant="danger"
              onClick={() => {
                onClose(false);
                form.reset();
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton variant="default" type="submit" isLoading={isLoading}>
              Save
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};
