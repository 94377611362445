import {
    DialogProps,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    IconButton,
    Box,
    Slide,
    Divider,
  } from "@mui/material";
  import React, { PropsWithChildren, ReactNode } from "react";
  import { Close } from "@mui/icons-material";
  import { TransitionProps } from "@mui/material/transitions";
  
  export type FullScreenDialogProps = Required<Pick<DialogProps, "open" | "onClose">> & {
    title: string;
    actions?: ReactNode;
    topAction?: ReactNode;
    hideClose?: boolean;
    enableConfirmation?: boolean;
    confirmMessage?: string;
  };
  
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  export const FullScreenDialog = ({
    title,
    topAction,
    children,
    open,
    onClose,
  }: PropsWithChildren<FullScreenDialogProps>): JSX.Element => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        maxWidth={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            minWidth: "100%",
            zIndex:0
          },
        }}
      >
        <DialogTitle display="flex" flexDirection="column" sx={{ pt: 5 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" fontWeight="bold" component="span">
              {title}
            </Typography>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              {topAction}
              <IconButton
                onClick={() =>
                onClose({}, "escapeKeyDown")
                }
              >
                <Close fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  };
  