import { DefaultPageHeader } from '@/components/shared/DefaultPage/DefaultPageHeader';
import LogoImage from '@/lib/LogoImage/LogoImage';
import { useAuth } from '@/lib/auth';
import { useGetCompanyProfileQuery } from '../api/company-api';
import { Button } from '@/components/ui/button';
import { Edit2 } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { useState } from 'react';
import EditCompanyProfile from '../components/EditCompany';

const CompanyProfilePage = () => {
  const [openEditCompany, setOpenEditCompany] = useState<boolean>(false)
  const { session } = useAuth()
  const {data: companyProfile} = useGetCompanyProfileQuery({data: {id: session?.userInfo.organizationId}})

  return (
    <>
      <DefaultPageHeader headerTitle="Company Profile" />
      <div className="bg-white p-4 shadow rounded-lg">
        <div className="flex flex-col justify-between gap-4">
          <div className='flex flex-row justify-between items-center gap-4'>
              <LogoImage
              id={session?.userInfo.organizationId ?? ""}
              alt=""
              fallbackSrc=""
              className="h-8 w-8 lg:mr-2 lg:h-12 lg:w-16 rounded"
              allowEdit={true}
              />
              <div className="flex-1 flex flex-col gap-2">
                  <p className="text-xl font-semibold">{companyProfile?.name}</p>
              </div>
              <div className='flex gap-2'>
                      <Button
                          onClick={() => {setOpenEditCompany(true)}}
                          variant={"outline"}
                          className="text-primary border-primary border-2"
                      >
                          EDIT <Edit2 />
                      </Button>
              </div>
          </div>
          <Separator className='w-full border'/>
          <div className='flex flex-col lg:flex-row gap-4'>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Email</p>
                  <p className="text-lg font-medium">{companyProfile?.email}</p>
              </div>
              <Separator className='w-full border lg:hidden'/>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Phone Number</p>
                  <p className="text-lg font-medium">{companyProfile?.phoneNumber}</p>
              </div>
              <Separator className='w-full border lg:hidden'/>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Address</p>
                  <p className="text-lg font-medium">{companyProfile?.address}</p>
              </div>
              <Separator className='w-full border lg:hidden'/>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Country</p>
                  <p className="text-lg font-medium">{companyProfile?.country}</p>
              </div>
              <Separator className='w-full border lg:hidden'/>
              <div className="flex flex-col">
                  <p className="text-sm font-light">City</p>
                  <p className="text-lg font-medium">{companyProfile?.city}</p>
              </div>
          </div>
          <Separator className='w-full border'/>
          <div className='flex flex-col lg:flex-row gap-4'>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Vision</p>
                  <p className="">{companyProfile?.vision}</p>
              </div>
          </div>
          <Separator className='w-full border'/>
          <div className='flex flex-col lg:flex-row gap-4'>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Mission</p>
                  <p className="">{companyProfile?.mission}</p>
              </div>
          </div>
          <Separator className='w-full border'/>
          <div className='flex flex-col lg:flex-row gap-4'>
              <div className="flex flex-col">
                  <p className="text-sm font-light">Values</p>
                  <p className="">{companyProfile?.values}</p>
              </div>
          </div>
        </div>
      </div>
      {
        openEditCompany && companyProfile && (
          <EditCompanyProfile openForm={openEditCompany} setOpenForm={setOpenEditCompany} company={companyProfile}/>
        )
      }
    </>
  );
};

export default CompanyProfilePage;
