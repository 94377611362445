import { Box, CircularProgress, Typography } from "@mui/material";

export const CircularPercentageIndicator2 = ({ value }: { value: number }) => {
  let color;
  if (value < 20) {
    color = "error.main";
  } else if (value < 50) {
    color = "warning.main";
  } else if (value < 80) {
    color = "#FFF9C4"; // very light yellow
  } else {
    color = "info.main";
  }
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={value} sx={{ color }} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
import { SxProps, Theme } from "@mui/system";

interface CircularPercentageIndicatorProps {
  value: number;
  sx?: SxProps<Theme>;
  size?: number;
}

export const CircularPercentageIndicator = ({
  value,
  sx,
  size = 40,
}: CircularPercentageIndicatorProps) => {
  let color;
  if (value < 20) {
    color = "error.main";
  } else if (value < 50) {
    color = "warning.main";
  } else if (value < 80) {
    color = "#FFF9C4"; // very light yellow
  } else {
    color = "info.main";
  }
  return (
    <Box sx={{ position: "relative", display: "inline-flex", ...sx }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        sx={{ color, ...sx }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary", ...sx }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
