import React, { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ICustomer } from "../models/Customer";
import { PhoneInput } from "@/components/shared/PhoneInput/phone-input";
import {
  useEditCustomerMutation,
  useGetSectorsQuery,
} from "../api/customer-api";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Notify } from "@/components/shared/Notification/notify";
import { FullScreenDialog } from "@/components/shared/DefaultDailog/FullPageDialog";
import { useAuth } from "@/lib/auth";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { CalendarIcon, MinusCircleIcon, PlusCircleIcon } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import ReactSelect from 'react-select'; // Importing react-select as an alias
import { useGetSourceQuery } from "@/features/sales/source/api/api";
import { ISource } from "@/features/sales/source/model/source";

const customerFormSchema = z
  .object({
    customerType: z.enum(["Person", "Organization", "Other"]),
    customerSector: z.array(z.string()).min(1, "At least one sector is required"),
    customerName: z.string().nonempty("Customer Name is required"),
    customerEmail: z
      .string()
      .email("Invalid email address")
      .nonempty("Email is required"),
    customerPhoneNumbers: z
      .array(
        z
          .string()
          .nonempty("Phone number is required")
          .refine(isValidPhoneNumber, {
            message: "Invalid phone number",
          })
      )
      .min(1, "At least one phone number is required"),
    customerCountry: z.string().nonempty("Country is required"),
    region: z.string().nonempty("Region is required"),
    customerAddress: z.string().nonempty("Address is required"),
    customerDOB: z.date().optional(), // Optional for now, required conditionally
    customerVATNo: z.string().nonempty("VAT Number is required"),
    customerTIN: z.string().nonempty("TIN is required"),
    customerContactPerson: z.string().nonempty("Contact Person is required"),
    customerContactPersonPhoneNumebr: z
      .string()
      .nonempty("Contact Person's Phone Number is required")
      .refine(isValidPhoneNumber, {
        message: "Invalid phone number",
      }),
    isActive: z.boolean(), // Add isActive field
    creditLimit: z.number({ invalid_type_error: "Credit Limit is required" }),
    // .min(0.01, { message: "Credit Limit must be greater than zero" }),
    creditUsedSoFar: z.number({
      invalid_type_error: "Credit Used is required",
    }),
    source: z.array(z.string()).min(1, "At least one source is required"),
    // .min(0.01, { message: "Credit Used must be greater than zero" }),
  })
  .refine(
    (data) => data.customerType !== "Person" || !!data.customerDOB, // Require DOB if customer type is 'Person'
    {
      path: ["customerDOB"],
      message: "Date of Birth is required for customers of type 'Person'",
    }
  );

type CustomerFormValues = z.infer<typeof customerFormSchema>;

type EditCustomerProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  customer?: ICustomer;
};

const customerTypes = [
  {
    label: "Person",
    value: "Person",
  },
  {
    label: "Organization",
    value: "Organization",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const EditCustomer = ({
  openForm,
  setOpenForm,
  customer,
}: EditCustomerProps) => {
  const { session } = useAuth();
  const [editCustomer, { isLoading, isSuccess }] = useEditCustomerMutation();
  const { data: sectors = [] } = useGetSectorsQuery({});
  const { data: sourceList } = useGetSourceQuery({})

  const defaultValues: Partial<CustomerFormValues> = {
    customerType:
      customer?.clientType === "Person" ||
      customer?.clientType === "Organization" ||
      customer?.clientType === "Other"
        ? customer.clientType
        : "Person", // Set a default type if customerType is not valid
    customerSector: customer?.sectorIds ?? [],
    customerName: customer?.clientName ?? "",
    customerEmail: customer?.email ?? "",
    customerPhoneNumbers: customer?.phoneNumbers ?? [""],
    customerCountry: customer?.country ?? "",
    customerAddress: customer?.address ?? "",
    customerDOB: customer?.dateOfBirth
      ? new Date(customer?.dateOfBirth)
      : undefined,
    customerVATNo: customer?.vatNo ?? "",
    customerTIN: customer?.tinNo ?? "",
    customerContactPerson: customer?.contactPerson ?? "",
    customerContactPersonPhoneNumebr: customer?.contactNumber ?? "",
    isActive: customer?.isActive ?? false, // Set default value for isActive
    creditLimit: customer?.creditLimit,
    creditUsedSoFar: customer?.creditUsedSoFar,
    region: customer?.region ?? "",
    source: customer?.clientSources ?? []
  };

  const form = useForm<CustomerFormValues>({
    resolver: zodResolver(customerFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const {
    formState: { errors },
  } = form;

  // Watch the customerType field to conditionally render the date of birth field
  const customerType = form.watch("customerType");

  const onSubmit = async (data: CustomerFormValues) => {
    const payload: any = {
      id: customer?.id,
      clientType: data.customerType,
      clientName: data.customerName,
      phoneNumbers: data.customerPhoneNumbers,
      email: data.customerEmail,
      sectorIds: data.customerSector,
      address: data.customerAddress,
      location: { x: 0, y: 0 },
      vatNo: data.customerVATNo,
      tinNo: data.customerTIN,
      contactPerson: data.customerContactPerson,
      contactNumber: data.customerContactPersonPhoneNumebr,
      country: data.customerCountry,
      isActive: data.isActive, // Include isActive in the payload,
      creditLimit: data.creditLimit,
      creditUsedSoFar: data.creditUsedSoFar,
      organizationId: session?.userInfo.organizationId,
      region: data.region,
      clientSources: data.source
    };

    // Conditionally include dateOfBirth only for "Person" customer type
    if (data.customerType === "Person") {
      payload.dateOfBirth = data.customerDOB;
    }

    await editCustomer({
      url: `/clients/update`,
      method: "PUT",
      data: payload,
    });
  };

  // Add another phone number input field
  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };

  // Remove a phone number input field
  const removePhoneNumber = (index: number) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  // Update phone number value in state
  const updatePhoneNumber = (value: string, index: number) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  // Sync `phoneNumbers` state with the form field values
  // Synchronize form's phone numbers array with local state
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    form.getValues("customerPhoneNumbers")
  );

  useEffect(() => {
    form.setValue("customerPhoneNumbers", phoneNumbers);
  }, [phoneNumbers, form]);

  useEffect(() => {
    if (isSuccess) {
      setOpenForm(false);
      Notify("success", "Customer Edited Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <FullScreenDialog
      open={openForm}
      title="Edit Customer"
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
            <FormField
              control={form.control}
              name="customerType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Customer Type</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                      defaultValue={field.value ?? "text"}
                      value={field.value ?? "text"}
                    >
                      <SelectTrigger className="">
                        <SelectValue placeholder="Select Customer type" />
                      </SelectTrigger>
                      <SelectContent className="z-[1400]">
                        {customerTypes.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Customer Sector (Multi-Select) */}
           <FormField
              control={form.control}
              name="customerSector"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Sector</FormLabel>
                  <FormControl>
                    <ReactSelect
                      isMulti
                      options={sectors.map((item) => ({
                        label: item.sectorName,
                        value: item.id,
                      }))}
                      value={field.value.map((id) => ({
                        label: sectors.find((item) => item.id === id)?.sectorName || id,
                        value: id,
                      }))}
                      onChange={(selected) => field.onChange(selected.map((s) => s.value))}
                      placeholder="Select Sectors"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Customer Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Customer Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Customer Email</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      {...field}
                      placeholder="Customer Email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Phone Numbers (Multiple) */}
            {phoneNumbers.map((phoneNumber, index) => (
              <div key={index} className="flex items-center gap-2">
                <FormItem className="flex-1">
                  <FormLabel>Phone Number {index + 1}</FormLabel>
                  <div className="flex gap-2 items-center w-full">
                    <div className="relative flex-1">
                      <PhoneInput
                        value={phoneNumber}
                        onChange={(value) =>
                          updatePhoneNumber(value || "", index)
                        }
                        className="flex-1"
                      />
                      {phoneNumbers.length > 1 && (
                        <Button
                          variant={"destructive"}
                          className="absolute -top-2 right-0 rounded-full w-min h-min p-0"
                        >
                          <MinusCircleIcon
                            className="h-5 w-5"
                            onClick={() => removePhoneNumber(index)}
                          />
                        </Button>
                      )}
                    </div>
                    {phoneNumbers.length - 1 === index && (
                      <Button className="rounded-full bg-primary w-min h-min p-0">
                        <PlusCircleIcon
                          className="h-5 w-5"
                          onClick={addPhoneNumber}
                        />
                      </Button>
                    )}
                  </div>
                  {errors.customerPhoneNumbers?.[index] && (
                    <p className="text-red-500">
                      {errors.customerPhoneNumbers[index]?.message}
                    </p>
                  )}
                </FormItem>
              </div>
            ))}
            <FormField
              control={form.control}
              name="customerCountry"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Country</FormLabel>
                  <FormControl>
                    <Input placeholder="Country" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="region"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Region</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Region" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input placeholder="Address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Conditionally render customerDOB only if customerType is "Person" */}
            {customerType === "Person" && (
              <FormField
                control={form.control}
                name="customerDOB"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date of birth</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-auto p-0 z-9999"
                        align="start"
                      >
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date > new Date() || date < new Date("1900-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="customerVATNo"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>VAT No.</FormLabel>
                  <FormControl>
                    <Input placeholder="VAT No." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerTIN"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>TIN</FormLabel>
                  <FormControl>
                    <Input placeholder="TIN" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerContactPerson"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contact Person Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Contact Person Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customerContactPersonPhoneNumebr"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contact Person Phone Number</FormLabel>
                  <FormControl>
                    <PhoneInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="creditLimit"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Credit Limit</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Credit Limit"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))} // Manually convert to number
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="creditUsedSoFar"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Credit Used</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      disabled
                      placeholder="Credit Used"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))} // Manually convert to number
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="source"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Source</FormLabel>
                  <FormControl>
                    <ReactSelect
                      isMulti
                      options={sourceList?.map((item:ISource) => ({
                        label: item.sourceName,
                        value: item.id,
                      }))}
                      value={field.value.map((id) => ({
                        label: sourceList?.find((item:ISource) => item.id === id)?.sourceName || id,
                        value: id,
                      }))}
                      onChange={(selected) => field.onChange(selected.map((s) => s.value))}
                      placeholder="Select Source"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isActive"
              render={({ field }) => (
                <FormItem className="">
                  <div className="flex items-center space-x-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Is Active</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <CustomButton
            className="lg:self-center"
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
          >
            Save Customer
          </CustomButton>
        </form>
      </Form>
    </FullScreenDialog>
  );
};

export default EditCustomer;
