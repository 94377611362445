import PageTitle from "@/components/ui/PageTitle";
import { Route } from "react-router-dom";
import { PayementCollection } from "./paymentCollection/pages/PaymentCollection.tsx";
import { ExpenseCollection } from "./expense/page/Expense.tsx";
export default (
  <Route path="finance">
    <Route path="payment-collection">
      <Route
        index
        element={
          <>
            <PageTitle title="Payment Collection" />
            <PayementCollection />
          </>
        }
      />
    </Route>
    <Route
        path="expense"
        element={
          <>
            <PageTitle title="Expense" />
            <ExpenseCollection />
          </>
        }
      />
  </Route>
);
