import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import {
  IAlertNotification,
  ITaskDetail,
  ITaskHistory,
  ITaskNote,
  ITaskPayment,
  ITaskWithClientCase,
} from "../models/Task";
import { PagedResponse } from "@/model/pagination-response";

interface ITask {
  taskId: string;
  workFlowId: string;
}

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    searchTasks: builder.query<
      PagedResponse<ITaskWithClientCase>,
      QueryRequest
    >({
      query: (params) => ({
        url: "/otasks/SearchTasks",
        method: "GET",
        params: { ...params.data },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getTask: builder.query<ITaskWithClientCase, string>({
      query: (id) => ({
        url: `/otasks/${id}`,
        method: "GET",
      }),
    }),
    getTaskDetailsByTaskId: builder.query<ITaskDetail, string>({
      query: (id) => ({
        url: `/taskDetails/GetTaskDetailsByTaskId`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getTaskHistoryByTaskId: builder.query<ITaskHistory[], string>({
      query: (id) => ({
        url: `/taskHistories/GetTaskHistoryByTaskId`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail"],
    }),
    getTaskNoteByTaskId: builder.query<ITaskNote[], string>({
      query: (id) => ({
        url: `/notes/GetTaskNotes`,
        method: "GET",
        params: { taskId: id },
      }),
      providesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    getTaskPayementByTaskId: builder.query<ITaskPayment[], string>({
      query: (id) => ({
        url: `/clientFinance/${id}`,
        method: "GET",
      }),
      providesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    addTaskPayement: builder.mutation<string, FormData>({
      query: (params) => ({
        url: `/clientFinance`,
        method: "POST",
        data: params,
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    updateTaskPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/${params.url}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),

    getAlertByTaskIdAndWorkflowId: builder.query<IAlertNotification[], ITask>({
      query: (params) => ({
        url: `/alert-notifications/GetAllAlertsByTask`,
        method: "GET",
        params: { ...params },
      }),
      providesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    getUserAlertNotifications: builder.query<IAlertNotification[], void>({
      query: () => ({
        url: `/alert-notifications/GetUserAlertNotifications`,
        method: "GET",
      }),
    }),
    getAllAlertsByTask: builder.query<IAlertNotification[], string>({
      query: (taskId) => ({
        url: `/alert-notifications/GetAllAlertsByTask`,
        method: "GET",
        params: { taskId: taskId },
      }),
      providesTags: ["Task", "TaskDetail", "TaskNote"],
    }),
    getIsWorkflowEditPermitted: builder.query<boolean, string>({
      query: (taskId) => ({
        url: `/otasks/IsWorkflowEditPermitted`,
        method: "GET",
        params: { taskId: taskId },
      }),
    }),
  }),
});

export const {
  useLazySearchTasksQuery,
  useSearchTasksQuery,
  useGetTaskQuery,
  useGetTaskDetailsByTaskIdQuery,
  useLazyGetTaskDetailsByTaskIdQuery,
  useGetTaskHistoryByTaskIdQuery,
  useGetTaskNoteByTaskIdQuery,
  useLazyGetTaskNoteByTaskIdQuery,
  useGetTaskPayementByTaskIdQuery,
  useAddTaskPayementMutation,
  useUpdateTaskPayementMutation,
  useLazyGetAlertByTaskIdAndWorkflowIdQuery,
  useGetAlertByTaskIdAndWorkflowIdQuery,
  useGetUserAlertNotificationsQuery,
  useGetAllAlertsByTaskQuery,
  useLazyGetIsWorkflowEditPermittedQuery,
} = taskApi;
