import { TaskStatusCount } from "@/features/dashboard/organization/models/taskStatusCount";
import { ITaskPayment } from "@/features/task/models/Task";
import { IUser } from "@/features/users/models/User";
import { ICase } from "@/features/workflows/Case/models/Case";

export enum CustomerStatus {
  Customer = "Customer",
  PotentialCustomer = "PotentialCustomer",
  Both = "Both",
}

export interface ICustomer {
  id: string;
  clientType: string;
  clientName: string;
  phoneNumbers: string[];
  email: string;
  sectorIds: string[] | null;
  isActive: boolean;
  address: string | null;
  location: { x: number; y: number } | null;
  dateOfBirth?: string;
  vatNo: string | null;
  tinNo: string | null;
  contactPerson?: string;
  contactNumber?: string;
  country: string;
  region: string;
  organizationId: string;
  clientFinances: ITaskPayment[] | null;
  creditLimit: number;
  creditUsedSoFar: number;
  customerStatus?: string; // Added based on JSON
  currentWorkFlowStepId?: string; // Added based on JSON
  currentWorkFlowStep?: {
    id: string;
    workFlowName: string;
    salesCaseTypeId: string;
    salesCaseType: {
      id: string;
      description: string;
      name: string;
      standardDays: number;
      isActive: boolean;
      organizationId: string;
      clients?: string[] | null;
      workFlows?: any[]; // Define more specifically if needed
      createTime: number;
      createUserId: string;
      updateTime: number;
    };
    permissionName: string;
    notifyToClient: boolean;
    workFlowStep: number;
    parentWorkFlowId: string | null;
    isActive: boolean;
    workFlowParameters: {
      id: string;
      workFlowId: string;
      parameterName: string;
      parameterType: string;
      paramaterValues: string;
      orderNo: number;
      isRequired: boolean;
      isActive: boolean;
      notificationOption: string;
      notifyToClient: boolean;
      hasPayment: boolean;
      paymentAmount: number;
      createTime: number;
      createUserId: string;
      updateTime: number;
    }[];
    taskAssignees?: any; // Define more specifically if needed
    notificationOption: string;
    hasPayment: boolean;
    paymentAmount: number;
    organizationId: string;
    createTime: number;
    createUserId: string;
    updateTime: number;
  };
  totalPaymentDue: number;
  totalPaidAmount: number;
  totalAmountDue: number;
  taskStatusCount: TaskStatusCount[] | null;
  salesPipelineStage: string;
  clientSources?: string[]; // Added based on JSON
  createTime?: number; // Added based on JSON
  createUserId?: string; // Added based on JSON
  updateTime?: number; // Added based on JSON
}

export interface ICustomerWithClient {
  clients: ICustomer[];
}

export interface IClientSales {
  salesTaskId: string;
  taskReference: string;
  clientName: string;
  address?: string;
  country: string;
  clientId: string;
  caseTypeId: string;
  phoneNumbers?: string[];
  email?: string;
  workFlowName: string;
  assignedUsers: IUser[];
  caseType: ICase;
}
