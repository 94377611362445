import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { IUser } from "../models/User";
import { useGetUsersQuery } from "../api/user-api";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";

// Define columns for MaterialReactTable
const userListColumns: MRT_ColumnDef<IUser>[] = [
  {
    accessorKey: "username",
    header: "User Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
  },
  {
    accessorKey: "userType",
    header: "User Type"
  },
  // {
  //   id: "actions",
  //   header: "Action",
  //   Cell: ({row}) => (
  //     <NavLink
  //       to={`/settings/users/${row.original.id}`}
  //       className="underline text-blue-700"
  //     >
  //       Detail
  //     </NavLink>
  //   ),
  // },
];

export function ClientUserList() {
  const { session } = useAuth()
  const navigate = useNavigate()

  const { data: usersList } = useGetUsersQuery({
    data: {
      organizationId: session?.userInfo.organizationId,
      userType: UserType.Client
    }
  });

  return (
    <MaterialReactTable
    columns={userListColumns}
    data={usersList ?? []}
    enablePagination
    muiTableBodyRowProps={(row) => ({
      onClick: () => {
        navigate(`/settings/users/${row.row.original.id}`)
      },
      sx: {
        cursor: 'pointer',
      }
    })}
  />
  );
}
