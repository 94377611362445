import { useState } from 'react'
import { DefaultPage } from '@/components/shared/DefaultPage'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SMSList from '../components/SMSList';
import { Button } from '@/components/ui/button';
import SendSMS from '../components/SendSMS';
import EmailList from '../components/EmailList';

const SMSEmailPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>("sms");
  const [openSendForm, setOpenSendForm] = useState<boolean>(false)

  return (
    <>
      <DefaultPage
      headerTitle='SMS & Email'
      headerActionButton= {
        <Button onClick={() => setOpenSendForm(true)}>
          Sent Message
        </Button>
      }
      >
        <Tabs
          defaultValue={selectedTab}
          className=""
          onValueChange={setSelectedTab}
        >
          <TabsList className="">
          <TabsTrigger value="sms">SMS</TabsTrigger>
          <TabsTrigger value="email">Email</TabsTrigger>
          </TabsList>
          <TabsContent value="sms">
            <SMSList/>
          </TabsContent>
          <TabsContent value="email">
            <EmailList/>
          </TabsContent>
        </Tabs>
      </DefaultPage>
      {
        openSendForm && (
          <SendSMS openForm={openSendForm} setOpenForm={setOpenSendForm}/>
        )
      }
    </>
  )
}

export default SMSEmailPage
