import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { NotificationModel } from "../model/NotificationModel";

export const notificationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationModel[], void>({
        query: () => ({
            url: '/users/GetUserNotifications',
            method: 'GET',
        }),
        providesTags: ["Notification"], // Provides a tag for caching
    }),
    markAsRead: builder.mutation<string, QueryRequest>({
        query: (params) => ({
            url: '/users/MarkNotificationsAsRead',
            method: 'POST',
            data: [...params.data]
        }),
        invalidatesTags: ["Notification"],
    }),
  }),
});

export const { useGetNotificationsQuery, useMarkAsReadMutation } = notificationApi;