import React from "react";
import { DefaultPageHeader, DefaultPageHeaderProps } from "./DefaultPageHeader";

type DefaultPageProps = {
  headerActionButton?: React.ReactNode;
  children: React.ReactNode;
} & DefaultPageHeaderProps;

export const DefaultPage: React.FC<DefaultPageProps> = ({
  headerTitle,
  headerActionButton,
  breadcrumbsLinks,
  children,
}) => {
  return (
    <div className="px-2 md:px-6 2xl:px-11">
      <DefaultPageHeader
        headerTitle={headerTitle}
        headerActionButton={headerActionButton}
        breadcrumbsLinks={breadcrumbsLinks}
      />

      <div className="mt-2 bg-white dark:bg-primary-foreground rounded-md">
        {children}
      </div>
    </div>
  );
};
