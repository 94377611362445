import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { SalesSummaryReport, WorkFlowReport } from "../model/sales";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesDashboardReport: builder.query<SalesSummaryReport, QueryRequest>({
        query: (params) => ({
          url: "/dashboard/GetSalesDashboardReport",
          method: "GET",
          params: {...params.data}
        }),
        providesTags: ["SalesReport"], // Provides a tag for caching
    }),
    getPotentialCustomerReportPerPipelineWorkflow: builder.query<WorkFlowReport[], QueryRequest>({
        query: (params) => ({
          url: "/dashboard/GetPotentialCustomerReportPerPipelineWorkflow",
          method: "GET",
          params: {...params.data}
        }),
        providesTags: ["SalesReport"], // Provides a tag for caching
    }),
    getPotentialCustomerReportTenderWorkflow: builder.query<WorkFlowReport[], QueryRequest>({
        query: (params) => ({
          url: "/dashboard/GetPotentialCustomerReportTenderWorkflow",
          method: "GET",
          params: {...params.data}
        }),
        providesTags: ["SalesReport"], // Provides a tag for caching
    })
  }),
});

export const {
  useGetPotentialCustomerReportPerPipelineWorkflowQuery,
  useGetPotentialCustomerReportTenderWorkflowQuery,
  useGetSalesDashboardReportQuery
} = customerApi;
