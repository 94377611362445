import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { PhoneInput } from "@/components/shared/PhoneInput/phone-input";
import { isValidPhoneNumber } from "react-phone-number-input";

import axios from "axios";
import logo from "./Shape.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomButton from "@/components/shared/Button/CustomButton";

// Define your Zod schema for validation
const signUpSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  phoneNumber: z
    .string()
    .nonempty("Phone Number is required")
    .refine(isValidPhoneNumber, {
      message: "Invalid phone number",
    }),
  companyName: z.string().min(2, { message: "Company name is required" }),
  address: z.string().min(5, { message: "Address is required" }),
  country: z.string().min(2, { message: "Country is required" }),
  city: z.string().min(2, { message: "City is required" }),
});

type SignUpFormData = z.infer<typeof signUpSchema>;

const SignUpPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const form = useForm<SignUpFormData>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      phoneNumber: "",
      companyName: "",
    },
  });

  const onSubmit = async (data: SignUpFormData) => {
    try {
      setIsLoading(true);
      
      const post_url = import.meta.env.VITE_API_BASE_URL + "/Companies";
      await axios.post(post_url, {
        name: data.companyName,
        address: data.address,
        email: data.email,
        phoneNumber: data.phoneNumber,
        country: data.country,
        city: data.city,
      });
      setIsSuccess(true);
    } catch (error) {
      console.error("Error during sign-up:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/confirmation");
    }
  }, [isSuccess, navigate]);

  return (
    <div className="flex justify-center items-center ">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-lg">
          <Card>
            <div className="flex items-center justify-center mt-8 gap-4">
              <img src={logo} alt="Logo" className="w-12 h-12" /> {/* Added alt and size */}
              <p className="text-5xl font-bold text-primary">UCM</p>
            </div>
            <CardHeader>
              <CardTitle className="text-2xl">Sign up</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="companyName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Your company name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="m@example.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <PhoneInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <Input
                        id="address"
                        type="text"
                        placeholder="22 Golagul, Addis Ababa"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input
                        id="country"
                        type="text"
                        placeholder="Ethiopia"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input
                        id="city"
                        type="text"
                        placeholder="Addis Ababa"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter>
              <CustomButton
                type="submit"
                disabled={!form.formState.isValid}
                isLoading={isLoading}
                className="w-full"
              >
                Sign Up
              </CustomButton>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
};

export default SignUpPage;
