import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Input } from "@/components/ui/input"; // Adjust the import path as necessary
import { Label } from "@/components/ui/label"; // Adjust the import path as necessary

interface FormNumberInputProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label: string;
  placeholder?: string;
  rules?: RegisterOptions<T>;
  disabled?: boolean;
  required?: boolean;
  id?: string;
  min?: number;
  max?: number;
  step?: number;
  //   register;
}

const FormNumberInput = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  disabled = false,
  required = false,
  id,
  min,
  max,
  step,
}: FormNumberInputProps<T>) => {
  return (
    <div className="mt-4">
      <Label
        htmlFor={id || name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            const parsedValue =
              inputValue === "" ? undefined : Number(inputValue);
            field.onChange(parsedValue);
          };

          return (
            <>
              <Input
                // {...register(name)}
                {...field}
                id={id || name}
                type="number"
                value={field.value ?? ""} // Use nullish coalescing to allow 0
                placeholder={placeholder}
                className="mt-1"
                onChange={handleChange}
                disabled={disabled}
                required={required}
                min={min}
                max={max}
                step={step}
              />
              {error && (
                <p className="text-red-500 text-sm mt-1">{error.message}</p>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default FormNumberInput;
