import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { PaymentCollection } from "../model/PaymentCollection";
import { IPaymentType } from "@/features/payment-types/models/payments";

export const taskApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    GetAllFinances: builder.query<PaymentCollection[], QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/GetAllFinances`,
        method: "GET",
        params: params.data,
      }),
      providesTags: ["PaymentCollection"],
    }),
    collectTaskPayement: builder.mutation<string, FormData>({
      query: (params) => ({
        url: `/clientFinance`,
        method: "POST",
        data: params,
      }),
      invalidatesTags: ["PaymentCollection"],
    }),
    updateTaskPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Task", "TaskDetail", "TaskPayment"],
    }),
    voidPayement: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/clientFinance/VoidPayment`,
        method: "PUT",
        params: { ...params.data },
      }),
      invalidatesTags: ["PaymentCollection"],
    }),
    getCollectionPaymentType: builder.query<IPaymentType[], void>({
      query: () => ({
        url: `/clientFinance/GetCollectionPaymentType`,
        method: "GET",
      }),
      providesTags: ["PaymentCollection"],
    }),
  }),
});

export const {
  useGetAllFinancesQuery,
  useCollectTaskPayementMutation,
  useUpdateTaskPayementMutation,
  useVoidPayementMutation,
  useGetCollectionPaymentTypeQuery,
} = taskApi;
