import { Box, Typography } from "@mui/material";

export type EmptyStateProps = {
  title?: string;
  description?: string;
};

const EmptyState = ({ title, description }: EmptyStateProps): JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: "center",
        p: 5,
      }}
    >
      <div className="flex flex-col items-center gap-4">
        <div>
          <img src="/assets/empty-box.svg" alt="Empty" />
        </div>
        <div>
          <Typography variant="h4" fontWeight="500">
            {title ?? "Empty"}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </div>
      </div>
    </Box>
  );
};

export default EmptyState;
