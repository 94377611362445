import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NumericFormat } from "react-number-format";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import CustomButton from "@/components/shared/Button/CustomButton";
import { Notify } from "@/components/shared/Notification/notify";
import {
  useCollectTaskPayementMutation,
  useGetCollectionPaymentTypeQuery,
} from "../api/payment-collection-api";
import { useLazyGetCustomersQuery } from "@/features/customers/api/customer-api";
import { useAuth } from "@/lib/auth";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronDown, Check } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { PaymentFrom, PaymentMode } from "../model/PaymentCollection";
import FormFileInput from "@/components/form/FormFileInput";

// Define Zod schema for form validation
// Define Zod schema for form validation
const paymentSchema = z
  .object({
    amount: z.string().nonempty("Payment amount is required"),
    clientId: z.string().optional(),
    payerName: z.string().optional(),
    paymentMode: z.nativeEnum(PaymentMode, {
      errorMap: () => ({ message: "Payment mode is required" }),
    }),
    description: z.string().optional(),
    paymentType: z.string(),
    file: z
      .array(z.instanceof(File))
      .optional()
      .refine((files) => !files || files.every((file) => file.size > 0), {
        message: "All files must be valid and have a non-zero size",
      }),
  })
  .superRefine((data, ctx) => {
    // If clientId is not selected, payerName is required
    if (!data.clientId && !data.payerName) {
      ctx.addIssue({
        path: ["payerName"],
        message: "Payer name is required if client is not selected",
        code: "custom",
      });
    }
  });

type PaymentFormValues = z.infer<typeof paymentSchema>;

interface CollectPaymentProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
}

const CollectPayment = ({ openForm, setOpenForm }: CollectPaymentProps) => {
  const { session } = useAuth();
  const [openSector, setOpenSector] = useState(false);
  const [collectPayment, { isLoading, isSuccess }] =
    useCollectTaskPayementMutation();
  const [getCustomers, { data: customerList }] = useLazyGetCustomersQuery();
  const { data: paymentTypes } = useGetCollectionPaymentTypeQuery();

  const form = useForm<PaymentFormValues>({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      paymentMode: PaymentMode.Cash,
    },
  });

  const fetchCustomers = useCallback(
    (query = "") => {
      getCustomers({
        url: "/clients/SearchClients",
        method: "GET",
        data: {
          organizationId: session?.userInfo.organizationId,
          pageNumber: 1,
          pageSize: 100000,
          clientName: query,
        },
      });
    },
    [getCustomers, session?.userInfo.organizationId]
  );

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      setOpenForm(false);
      Notify("success", "Task Payment added successfully!");
    }
  }, [form, isSuccess, setOpenForm]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers, session]);

  const onSubmit = async (data: PaymentFormValues) => {
    const formData = new FormData();
    formData.append("paidAmount", data.amount);
    formData.append("paymentMode", data.paymentMode);
    formData.append("paymentFrom", PaymentFrom.Direct);
    formData.append("paymentType", data.paymentType);
    if (data.clientId) formData.append("clientId", data.clientId);
    if (data.payerName) formData.append("payerName", data.payerName);
    if (data.description) formData.append("description", data.description);
    data.file?.forEach((file: string | Blob) => {
      formData.append(`file[]`, file);
    });
    console.log(formData);
    await collectPayment(formData);
  };

  return (
    <DefaultDialog
      title="Collect Payment"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          {/* Client Selection */}
          <Controller
            name="clientId"
            control={form.control}
            render={({ field }) => (
              <Popover open={openSector} onOpenChange={setOpenSector}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value
                      ? customerList?.list?.find((c) => c.id === field.value)
                          ?.clientName
                      : "Select Customer"}
                    <ChevronDown className="ml-2 h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0 z-99999">
                  <Command>
                    <CommandInput
                      placeholder="Search Customer"
                      onInput={(e) =>
                        fetchCustomers((e.target as HTMLInputElement).value)
                      }
                    />
                    <CommandList>
                      <CommandEmpty>No Customer found.</CommandEmpty>
                      <CommandGroup>
                        {customerList?.list?.map((customer) => (
                          <CommandItem
                            key={customer.id}
                            value={customer.id}
                            onSelect={() => {
                              field.onChange(customer.id);
                              setOpenSector(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                customer.id === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {customer.clientName}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            )}
          />

          {/* Payer Name Field (conditionally shown) */}
          {!form.watch("clientId") && (
            <FormField
              name="payerName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payer Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Enter Payer Name"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {/* Payment Mode Selection */}
          <FormField
            name="paymentMode"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Payment Mode</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Payment Mode" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {Object.values(PaymentMode).map((mode) => (
                        <SelectItem key={mode} value={mode}>
                          {mode}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="paymentType"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Payment Type</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="Payment Type" />
                    </SelectTrigger>
                    <SelectContent className="z-9999">
                      {paymentTypes?.map((type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Payment Amount Field */}
          <FormField
            name="amount"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Payment Amount</FormLabel>
                <FormControl>
                  <NumericFormat
                    {...field}
                    placeholder="Amount"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    onValueChange={(values) => field.onChange(values.value)}
                    customInput={Input}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Description Field */}
          <FormField
            name="description"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    placeholder="Payment Description"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormFileInput
            control={form.control}
            name="file"
            label="Upload Attachments"
            multiple
            accept="*"
          />

          {/* Submit Button */}
          <CustomButton
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            className="w-full"
          >
            Submit Payment
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default CollectPayment;
