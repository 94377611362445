import { QueryRequest } from "@/model/query-request";
import { appApi } from "@/store/app-api";
import { IUser } from "../models/User";
import { IPermission, IRole } from "../models/Role";

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<IRole[], QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
      providesTags: ["Roles"], // Provides a tag for caching
    }),
    getUsers: builder.query<IUser[], QueryRequest>({
      query: (params) => ({
        url: "/users/SearchUsers",
        method: "GET",
        params: { ...params.data }
      }),
      providesTags: ["Users"], // Provides a tag for caching
    }),
    getUserDetail: builder.query<IUser, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
      providesTags: ["User"], // Provides a tag for caching
    }),
    createUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Users"],
    }),
    editUserInfo: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: "PUT",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Users", "User"],
    }),
    createRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
      // Invalidates tags related to users, causing a refetch on next query
      invalidatesTags: ["Roles"],
    }),
    changePassword: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "POST",
        data: params.data,
      }),
    }),
    getPermissions: builder.query<IPermission[], QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "GET",
      }),
    }),
    deleteRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "DELETE",
      }),
      invalidatesTags: ["Roles"],
    }),
    editRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "PUT",
        data: params.data
      }),
      invalidatesTags: ["Roles"],
    }),
    editUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: params.method ?? "PUT",
        data: params.data
      }),
      invalidatesTags: ["User", "Users"],
    }),
    deActivateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/DeactivateUser/${params.data.id}`,
        method: "POST",
      }),
      invalidatesTags: ["User", "Users"],
    }),
    activateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/ActivateUser/${params.data.id}`,
        method: "POST",
      }),
      invalidatesTags: ["User", "Users"],
    }),
    updateUser: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/users/update`,
        method: "PUT",
        data: {...params.data}
      }),
      invalidatesTags: ["User", "Users"],
    }),
    updateUserRole: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: params.url ?? "",
        method: "PUT",
        data: params.data
      }),
      invalidatesTags: ["User"],
    }),
    updateUserClient: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/users/UpdateUserClients",
        method: "PUT",
        data: {...params.data}
      }),
      invalidatesTags: ["User"],
    })
  }),
});

export const {
  useGetUsersQuery,
  useGetRolesQuery,
  useGetUserDetailQuery,
  useCreateUserMutation,
  useGetPermissionsQuery,
  useCreateRoleMutation,
  useChangePasswordMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useUpdateUserRoleMutation,
  useEditUserInfoMutation,
  useDeActivateUserMutation,
  useUpdateUserMutation,
  useActivateUserMutation,
  useUpdateUserClientMutation
} = userApi;
