import { Separator } from "@/components/ui/separator";
import { ICustomer } from "../models/Customer";
import { SetStateAction } from "react";
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import Chip from "@/components/shared/customShadCN/Chip";

interface Props {
  client: ICustomer;
  openViewInfo: boolean;
  setOpenViewInfo: React.Dispatch<SetStateAction<boolean>>;
}

export const ClientInfo = ({ client, openViewInfo, setOpenViewInfo }: Props) => {
  return (
    <DefaultDialog
    title="Client Info"
    open={openViewInfo}
    onClose={() => setOpenViewInfo(false)}
    >
        <div className="bg-white my-2 py-4 flex flex-col gap-4 shadow-sm rounded-lg">
        {/* Responsive Info Section */}
        <div className="w-full flex flex-col gap-2">
            <div className="flex flex-col">
                <p className="text-sm font-light">Email</p>
                <p className="text-lg font-medium">{client.email}</p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col gap-2">
                <p className="text-sm font-light">Phone Number</p>
                <p className="text-lg font-medium">
                    {/* {client.phoneNumber} */}
                    <div className="flex gap-2">
                        {
                            client?.phoneNumbers?.map((phoneNumber: string, index: number) => (
                                <Chip key={index} label={phoneNumber} />
                            ))
                        }
                    </div>
                </p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">Country</p>
                <p className="text-lg font-medium">{client.country}</p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">Address</p>
                <p className="text-lg font-medium">{client.address}</p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">Vat No.</p>
                <p className="text-lg font-medium">{client?.vatNo}</p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">TIN</p>
                <p className="text-lg font-medium">{client?.tinNo}</p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">Contact Person</p>
                <p className="text-lg font-medium">
                    {client?.contactPerson}
                </p>
                <Separator className="my-2"/>
            </div>
            <div className="flex flex-col">
                <p className="text-sm font-light">Contact Number</p>
                <p className="text-lg font-medium">
                    {client?.contactNumber}
                </p>
            </div>
        </div>
    </div>
    </DefaultDialog>
  );
};
