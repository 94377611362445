import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ThemeProvider } from "./components/theme/theme-provider.tsx";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/app-store.ts";
import { AxiosWrapper } from "./lib/axios/axios-wrapper.tsx";

// import { ErrorBoundary } from "./lib/alert/components/ErrorBoundary.tsx";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <BrowserRouter>
      {/* <ErrorBoundary> */}
        <Provider store={store}>
            <ThemeProvider>
              <AxiosWrapper>
                <App />
              </AxiosWrapper>
            </ThemeProvider>
        </Provider>
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  </StrictMode>
);


// Register service worker for PWA
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/sw.js') // This is the default location where Vite places the service worker
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch((error) => {
//         console.log('Service Worker registration failed:', error);
//       });
//   });
// }