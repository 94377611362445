// FileInput.tsx
import React from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

interface FileInputProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  label: string;
  accept?: string;
  rules?: RegisterOptions<T>;
  disabled?: boolean;
  className?: string;
  multiple?: boolean; // Added multiple prop
}

const FormFileInput = <T extends FieldValues>({
  name,
  control,
  label,
  accept = "application/pdf,.docx,.doc",
  rules,
  disabled = false,
  className = "",
  multiple = false, // Default to false
}: FileInputProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, ref, value = [] },
        fieldState: { error },
      }) => {
        // Ensure value is always an array
        const filesArray = Array.isArray(value)
          ? value
          : [value].filter(Boolean);

        const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const newFiles = Array.from(e.dataTransfer.files);
            if (multiple) {
              // Append new files to existing ones
              const updatedFiles = [...filesArray, ...newFiles];
              onChange(updatedFiles);
            } else {
              // Replace existing file
              onChange([newFiles[0]]);
            }
            e.dataTransfer.clearData();
          }
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length > 0) {
            const newFiles = Array.from(e.target.files);
            if (multiple) {
              // Append new files to existing ones
              const updatedFiles = [...filesArray, ...newFiles];
              onChange(updatedFiles);
            } else {
              // Replace existing file
              onChange([newFiles[0]]);
            }
          } else {
            onChange([]);
          }
        };

        const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
          e.preventDefault();
        };

        return (
          <div className={`mt-4 ${className}`}>
            <Label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700"
            >
              {label}
            </Label>
            <div
              className="grid w-full items-center gap-1.5 border-2 border-dashed border-gray-300 p-4 rounded-md mt-2"
              onDrop={handleDrop}
              onDragOver={preventDefault}
            >
              <Input
                id={name}
                type="file"
                multiple={multiple} // Conditionally add multiple attribute
                className="hidden"
                onChange={handleChange}
                accept={accept}
                ref={ref}
                disabled={disabled}
              />
              <div
                className="flex justify-center items-center h-24 cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => document.getElementById(name)?.click()}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    document.getElementById(name)?.click();
                  }
                }}
              >
                Drag and drop {multiple ? "files" : "file"} here or click to
                select
              </div>
            </div>
            {filesArray && filesArray.length > 0 && (
              <ul className="mt-4 space-y-2">
                {filesArray.map((file: File, index: number) => (
                  <li
                    key={index}
                    className="flex items-center justify-between p-2 bg-gray-100 rounded-md"
                  >
                    <span className="text-sm text-gray-700">{file.name}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        const newFiles = filesArray.filter(
                          (_, i) => i !== index
                        );
                        onChange(newFiles);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X size={16} />
                    </Button>
                  </li>
                ))}
              </ul>
            )}

            {error && (
              <p className="text-red-500 text-sm mt-1">{error.message}</p>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormFileInput;
