import { useMemo } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IAlert } from '../models/alert';
import LoadingIndicator from './LoadingPage';
import EmptyState from './EmptyState';
import { useGetAlertsQuery } from '../api/alert-api';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const EventCalendar = () => {
  const navigate = useNavigate();

  const { data: alertList, isLoading: isAlertsFetchLoading, isFetching: isAlertsFetchFetching} = useGetAlertsQuery({})

  const events = useMemo(
    () =>
      alertList?.map((alert: IAlert) => ({
        id: alert.id,
        title: alert.taskReference ? `${alert.taskReference} -- ${alert.message}` : `${alert.message}`,
        start: new Date(alert.time),
        end: new Date(alert.dueDate),
        taskId: alert.taskId,
        remainingDays: alert.remainingDays,
        taskReference: alert.taskReference,
        alertFrom: alert.alertFrom
      })),
    [alertList]
  );

  // Handle event click to navigate to task detail page
  const handleSelectEvent = (event: any) => {
    if(event.taskId) {
      // Navigate to task detail page, using the taskId for navigation
      if(event.alertFrom === "GenericTask") {
        navigate(`/task/generic-task/${event.taskId}`);
      }
      else {
        navigate(`/task/operational-task/${event.taskId}`);
      }
    }
  };

  return (
    <div>
      {isAlertsFetchLoading ? (
        <LoadingIndicator />
      ) : alertList && alertList.length > 0 ? (
        <div>
          <div className="w-full">
            {isAlertsFetchFetching ? (
              <div className="mt-3">
                <LoadingIndicator />
              </div>
            ) : (
              <div className="p-6 bg-white rounded-md shadow-lg">
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  views={['month', 'week', 'day']}
                  defaultView={'month'}
                  style={{ height: 500 }}
                  className="bg-gray-50 text-gray-800 cursor-pointer"
                  onSelectEvent={handleSelectEvent} // Add this line to handle event clicks
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyState description="No Task currently. Please check back later." />
      )}
    </div>
  );
};

export default EventCalendar;
