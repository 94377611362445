import { DefaultDialog } from '@/components/shared/DefaultDailog/DefaultDialog';
import React, { SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/components/ui/input';
import { Select, SelectItem, SelectTrigger, SelectContent, SelectValue } from '@/components/ui/select';
import { Form, FormField, FormControl, FormLabel, FormItem, FormMessage } from '@/components/ui/form'; // Shadcn Form components
import CustomButton from '@/components/shared/Button/CustomButton';
import { Notify } from '@/components/shared/Notification/notify';
import { IGenericTaskDetail } from '../model/GenericTask';
import { useAddGenericTaskPayementMutation } from '../api/generic-task-api';
import FormFileInput from '@/components/form/FormFileInput';
import { NumericFormat } from 'react-number-format';

// Define Zod schema for form validation
const paymentSchema = z.object({
  amount: z.string().nonempty("Payment amount is required"),
  paymentMode: z.string().nonempty("Payment mode is required"),
  description: z.string().optional(),
  file: z
  .array(z.instanceof(File))
  .optional()
  .refine((files) => !files || files.every((file) => file.size > 0), {
    message: "All files must be valid and have a non-zero size",
  }),
});

type PaymentFormValues = z.infer<typeof paymentSchema>;

interface AddGenericTaskPaymentProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  taskDetail: IGenericTaskDetail;
}

const AddGenericTaskPayment = ({ openForm, setOpenForm,taskDetail }: AddGenericTaskPaymentProps) => {
  const [AddTaskPayment, { isLoading, isSuccess}] = useAddGenericTaskPayementMutation()
  const form = useForm<PaymentFormValues>({
    resolver: zodResolver(paymentSchema), // Use Zod schema for validation
    defaultValues: {
      paymentMode: '',
    },
  });

  const onSubmit = async (data: PaymentFormValues) => {
    const formData = new FormData();
    formData.append("taskId", taskDetail.id);
    formData.append("paymentMode", data.paymentMode);
    formData.append("paidAmount", data.amount);
    formData.append("clientId", taskDetail.client.id);
    if (data.description) formData.append("description", data.description);
    data.file?.forEach((file: string | Blob) => {
      formData.append(`file[]`, file);
    });
   
    await AddTaskPayment(formData);
  };

  useEffect(() => {
    if(isSuccess) {
      form.reset();
      setOpenForm(false);
      Notify("success", "Task Payment added successfully!")
    }
  }, [isSuccess])

  return (
    <DefaultDialog
      title="Add Payment"
      open={openForm}
      onClose={() => setOpenForm(false)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            {/* Amount Field */}
            <FormField
              name="amount"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Amount</FormLabel>
                  <FormControl>
                    <NumericFormat
                      placeholder="Amount"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={(values) => field.onChange(values.value)}
                      customInput={Input}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Payment Mode Field using Shadcn Select */}
            <FormField
              name="paymentMode"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Mode</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder="Payment Mode" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        <SelectItem value="Cash">Cash</SelectItem>
                        <SelectItem value="Cheque">Cheque</SelectItem>
                        <SelectItem value="BankDeposit">Bank Deposit</SelectItem>
                        <SelectItem value="CPO">CPO</SelectItem>
                        <SelectItem value="Credit">Credit</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

          <FormField
            name="description"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input {...field} type="text" placeholder="Payment Description" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

            {/* File Upload Field */}
  
            <FormFileInput
              control={form.control}
              name="file"
              label="Upload Attachments"
              multiple
              accept="*"
            />

            {/* Submit Button */}
            <CustomButton disabled={!form.formState.isValid || isLoading} isLoading={isLoading} type="submit" className="w-full">
              Submit Payment
            </CustomButton>
          </div>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default AddGenericTaskPayment;
