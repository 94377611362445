import { useMemo } from "react";
import { useSearchPotentialClientsByWorkFlowIdQuery } from "../../api/pipe-line-wf-query";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { IClientSales } from "@/features/customers/models/Customer";
import { useNavigate } from "react-router-dom";

type Props = {
  workflowId: string;
};
export const PipeLineWorkListByType = ({ workflowId }: Props) => {
  const columns = useMemo<MRT_ColumnDef<IClientSales>[]>(
    () => [
      {
        accessorKey: "taskReference",
        header: "Task Reference",
      },
      {
        accessorKey: "clientName",
        header: "Name",
      },
      {
        accessorKey: "phoneNumbers",
        header: "Phone Numbers",
      },

      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
    ],
    []
  );
  const navigate = useNavigate();

  const { data: clients, isFetching } =
    useSearchPotentialClientsByWorkFlowIdQuery(workflowId);

  const table = useMaterialReactTable({
    columns,
    data: clients?.list ?? [],
    initialState: {
      density: "compact",
    },
    state: { isLoading: isFetching },
    rowCount: 0,
    enableHiding: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    muiTableBodyRowProps: ({ row }) => {
      const isPipelineWf =
        row.original.caseType && row.original.caseType.name === "Pipeline";
      let link = `/sales/tender-workflow/${row.original.salesTaskId}`;
      if (isPipelineWf) {
        link = `/sales/pipeline-workflow/${row.original.salesTaskId}`;
      }
      return {
        onClick: () => {
          navigate(link);
        },
        sx: {
          cursor: "pointer",
        },
      };
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  );
};
