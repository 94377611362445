import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";import { MailIcon } from "lucide-react";

export function ConfirmationPage() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="flex justify-center h-screen items-center">
      <Card className="w-full max-w-md text-center shadow-0">
        <CardHeader>
          <MailIcon className="w-16 h-16 mx-auto mb-4 text-primary"/>
          <CardTitle className="text-2xl mb-2">Check your email</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-gray-600 mb-4">
            We've sent your login credentials to your email address. Please check your inbox
            and use the provided password to log in.
          </p>
          <Button variant={'outline'} className="w-full text-primary" onClick={handleLoginClick}>
            Go to Login
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default ConfirmationPage;
