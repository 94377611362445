import { appApi } from "@/store/app-api";
import { IAlert, IAlertType } from "../models/alert";
import { QueryRequest } from "@/model/query-request";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query<IAlert[], QueryRequest>({
      query: (params) => ({
        url: "/alert-notifications/GetUserAlertNotifications",
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Alerts"], // Provides a tag for caching
    }),
    get7daysAlerts: builder.query<IAlert[], void>({
      query: () => ({
        url: "/alert-notifications/GetUserAlertNotificationsDueIn7Days",
        method: "GET",
      }),
      providesTags: ["Alerts"], // Provides a tag for caching
    }),
    getAlertType: builder.query<IAlertType[], void>({
      query: () => ({
        url: "/alertTypes",
        method: "GET",
      }),
      providesTags: ["AlertType"],
    }),
    addAlertType: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/alertTypes",
        method: "POST",
        data: params.data,
      }),

      invalidatesTags: ["AlertType"],
    }),
    editAlertType: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/alertTypes/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),

      invalidatesTags: ["AlertType"],
    }),
    deleteAlertType: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/alertTypes/${params.data.id}`,
        method: "DELETE",
        data: params.data,
      }),
      invalidatesTags: ["AlertType"],
    }),
    deleteAlert: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/alert-notifications/${params.data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Alerts"],
    }),
    editAlert: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/alert-notifications/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),
      invalidatesTags: ["Alerts"],
    }),
    completeAlert: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/alert-notifications/CompleteAlert`,
        method: "PUT",
        params: params.data,
      }),
      invalidatesTags: ["Alerts", "Task", "TaskDetail"],
    }),
  }),
});

export const {
  useGetAlertsQuery,
  useGetAlertTypeQuery,
  useAddAlertTypeMutation,
  useEditAlertTypeMutation,
  useDeleteAlertTypeMutation,
  useGet7daysAlertsQuery,
  useDeleteAlertMutation,
  useEditAlertMutation,
  useCompleteAlertMutation,
} = customerApi;
