import React from "react";

interface LabelValueProps {
  label: string;
  value: React.ReactNode;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  children?: React.ReactNode;
  orientation?: "horizontal" | "vertical";
}

const LabelValue: React.FC<LabelValueProps> = ({
  label,
  value,
  className = "",
  labelClassName = "font-medium",
  valueClassName = "",
  children,
  orientation = "horizontal",
}) => {
  return (
    <div
      className={`flex ${
        orientation === "vertical" ? "flex-col" : "flex-row"
      } items-center gap-2 ${className}`}
    >
      <p className={`text-sm ${labelClassName}`}>{label}</p>
      <p className={`text-base ${valueClassName}`}>
        {value}
        {children}
      </p>
    </div>
  );
};

export default LabelValue;
