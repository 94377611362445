import { appApi } from "@/store/app-api";
import { QueryRequest } from "@/model/query-request";
import { ISource } from "../model/source";

export const customerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSource: builder.query<ISource[], QueryRequest>({
      query: (params) => ({
        url: "/ClientSources",
        method: "GET",
        params: params.data,
      }),
      providesTags: ["Source"], // Provides a tag for caching
    }),
    addSource: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: "/ClientSources",
        method: "POST",
        data: params.data,
      }),

      invalidatesTags: ["Source"],
    }),
    editSource: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/ClientSources/${params.data.id}`,
        method: "PUT",
        data: params.data,
      }),

      invalidatesTags: ["Source"],
    }),
    deleteSource: builder.mutation<string, QueryRequest>({
      query: (params) => ({
        url: `/ClientSources/${params.data.id}`,
        method: "DELETE",
        data: params.data,
      }),
      invalidatesTags: ["Source"],
    }),
  }),
});

export const {
  useGetSourceQuery,
  useAddSourceMutation,
  useEditSourceMutation,
  useDeleteSourceMutation,
} = customerApi;
