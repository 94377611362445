import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import CustomButton from "@/components/shared/Button/CustomButton";
import { useEffect } from "react";
import { useEditAlertMutation, useGetAlertTypeQuery } from "@/features/alert/api/alert-api";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { AlertFrom } from "@/features/task/models/Task";
import { Notify } from "@/components/shared/Notification/notify";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { IUser } from "@/features/users/models/User";
import { useGetUsersQuery } from "@/features/genericTask/api/generic-task-api";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { IAlert } from "../models/alert";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

type Props = {
  openAlertDialog: boolean;
  setOpenAlertDialog: (value: boolean) => void;
  alert: IAlert
};

const EditAlertSchema = z.object({
  alertTypeId: z.string().nonempty("Alert type is required"),
  dueDate: z.date(),
  daysBeforeAlert: z.number().nonnegative("Must be a non-negative number"),
  message: z.string().nonempty("Message is required"),
  users: z.array(z.object({
    id: z.string(),
    fullName: z.string(),
  })).optional(), // `users` field is now optional
});

type EditAlertFormInputs = z.infer<typeof EditAlertSchema>;

export const EditGenericAlertDialog = ({
  openAlertDialog,
  setOpenAlertDialog,
  alert
}: Props) => {
  const methods = useForm<EditAlertFormInputs>({
    resolver: zodResolver(EditAlertSchema),
    defaultValues: {
      alertTypeId: alert.alertTypeId,
      daysBeforeAlert: alert.daysBeforeAlert,
      dueDate: new Date(alert.dueDate),
      message: alert.message,
      users: alert.users ?? []
    }
  });
  const { control, handleSubmit, reset } = methods
  const { session } = useAuth()
  const [EditAlert, { isSuccess, isLoading }] = useEditAlertMutation();
  const { data: alertTypes } = useGetAlertTypeQuery();

  const { data: usersList, isLoading: isUserFetchingLoading } =
    useGetUsersQuery({
      data: {
        organizationId: session?.userInfo.organizationId,
        userType: UserType.Staff,
      },
    });

  const onSubmit = async (data: EditAlertFormInputs) => {
    await EditAlert({
      data: {
        id: alert.id,
        alertFrom: AlertFrom.Other,
        daysBeforeAlert: data.daysBeforeAlert,
        message: data.message,
        dueDate: data.dueDate,
        alertTypeId: data.alertTypeId,
        users: data?.users?.map((user) => user.id),
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenAlertDialog(false);
      Notify("success", "Alert Edited Successfully");
      reset();
    }
  }, [isSuccess, setOpenAlertDialog, reset]);

  const renderSelectUsers = (field: any) => (
    <Autocomplete
      multiple
      options={usersList || []}
      getOptionLabel={(option: IUser) => option.fullName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Users"
          placeholder="Users"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isUserFetchingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );

  return (
    <DefaultDialog
      title="Edit Alert"
      open={openAlertDialog}
      onClose={() => setOpenAlertDialog(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <FormField name="alertTypeId" control={control} render={({ field }) => (
            <FormItem>
              <FormLabel>Alert Type</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger><SelectValue placeholder="Alert Type" /></SelectTrigger>
                  <SelectContent className='z-9999'>
                    {alertTypes?.map((type) => (
                      <SelectItem key={type.id} value={type.id}>{type.alertName}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />
          <FormField
            control={control}
            name="dueDate"
            render={({ field }) => (
            <FormItem className="flex flex-col">
                <FormLabel>Due Date</FormLabel>
                <Popover>
                <PopoverTrigger asChild>
                    <FormControl>
                    <Button
                        variant={"outline"}
                        className={cn(
                        "pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground"
                        )}
                    >
                        {field.value ? (
                        format(field.value, "PPP")
                        ) : (
                        <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                    </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 z-9999" align="start">
                    <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                    />
                </PopoverContent>
                </Popover>
                <FormMessage />
            </FormItem>
            )}
          />
          <Label htmlFor="daysBeforeAlert">Days Before Alert</Label>
          <Controller
            name="daysBeforeAlert"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                placeholder="days before alert"
                {...field}
                onChange={(e) => field.onChange(Number(e.target.value))}
              />
            )}
          />
          <Label htmlFor="message">Your Message</Label>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Textarea
                placeholder="Type your message here."
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
           <FormField name="users" control={control} render={({ field }) => (
            <FormItem>
              <FormLabel>Assign User</FormLabel>
              <FormControl>
                {renderSelectUsers(field)}
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />
          <div className="flex gap-2 w-full mt-2">
            <CustomButton variant="primary" type="submit" disabled={isLoading} isLoading={isLoading} className="w-full">
              Submit
            </CustomButton>
          </div>
        </form>
      </FormProvider>
    </DefaultDialog>
  );
};
