// LogoImage.js
import { Notify } from '@/components/shared/Notification/notify';
import axios from 'axios';
import { Camera } from 'lucide-react';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
// import axios from 'axios';
// import { FaCamera } from 'react-icons/fa'; // Import camera icon

interface LogoImageProps {
    id: string,
    alt?: string,
    className?: string,
    fallbackSrc?: string,
    allowEdit?: boolean, // New prop to allow editing
}

const LogoImage = ({
  id,
  alt = 'Company Logo',
  className = '',
  fallbackSrc = '/default-logo.png',
  allowEdit = false, // Default is false to make editing optional
}: LogoImageProps) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [error, setError] = useState(false);

  const logoCache: any = useMemo(() => ({}), []);

  useEffect(() => {
    let isMounted = true;

    const fetchLogo = async () => {
      if (logoCache[id]) {
        setLogoUrl(logoCache[id]);
        return;
      }

      try {
        const token_url = import.meta.env.VITE_API_BASE_URL + `/Companies/Logo/${id}`;
        const response = await axios.get(token_url, {
          responseType: 'blob',
        });

        const objectUrl = URL.createObjectURL(response.data);
        logoCache[id] = objectUrl; // Cache the object URL

        if (isMounted) {
          setLogoUrl(objectUrl);
        }
      } catch (err) {
        if (isMounted) {
          setError(true);
        }
      }
    };

    fetchLogo();

    return () => {
      isMounted = false;
      // Do not revoke the URL here since it's cached
    };

  }, [id, logoCache]);

  // Handle file upload
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const uploadUrl = import.meta.env.VITE_API_BASE_URL + `/Companies/UploadLogo`

    if (file) {
      const formData = new FormData();
      formData.append('Logo', file); // Changed to 'Logo'
      formData.append('CompanyId', id); // Add CompanyId as field

      try {
        await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        Notify("success", "Company Logo Update Successfully")
        // Assuming the response contains the new logo URL
        setLogoUrl(URL.createObjectURL(file)); // Update the logo URL locally
      } catch (error) {
        console.error('Failed to upload image', error);
      }
    }
  };

  // Handle edit click
  const handleEditClick = () => {
    document.getElementById(`file-input-${id}`)?.click(); // Trigger file input click
  };

  return (
    <div className="logo-container" style={{ position: 'relative', display: 'inline-block' }}>
      {error ? (
        <img src={fallbackSrc} alt="Default Logo" className={className} />
      ) : logoUrl ? (
        <img src={logoUrl} alt={alt} className={className} />
      ) : (
        <div>Loading...</div>
      )}

      {/* Conditionally show the camera icon if editing is allowed */}
      {allowEdit && (
        <>
          <div
            className="edit-overlay rounded-full shadow absolute flex items-center justify-between bg-background cursor-pointer -bottom-2 -right-2"
            onClick={handleEditClick}
          >
            <Camera className='text-primary p-1 w-6 h-6' />
          </div>
          <input
            id={`file-input-${id}`}
            type="file"
            accept="image/*"
            style={{ display: 'none' }} // Hidden file input
            onChange={handleFileChange}
          />
        </>
      )}
    </div>
  );
};

export default LogoImage;
