import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  Form,
} from "@/components/ui/form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";

import { z } from "zod";
import CustomButton from "@/components/shared/Button/CustomButton";
import { NotificationOptions } from "@/model/enums";
import { useUpdateSalesWorkflowParameterMutation } from "../../api/work-flow-command";
import { Notify } from "@/components/shared/Notification/notify";

const parameterFormSchema = z
  .object({
    parameterName: z.string({ required_error: "Please enter a label." }).min(3),
    parameterType: z.enum([
      "text",
      "number",
      "date",
      "boolean",
      "select",
      "multiselect",
      "attachment",
    ]),
    isRequired: z.boolean().default(false).optional(),
    isMultiple: z.boolean().default(false).optional(),
    isActive: z.boolean().default(true).optional(),
    paramaterValues: z
      .array(
        z.object({
          value: z.string(),
        })
      )
      .optional(),
    workFlowId: z.string(),
    isMultipleUserParticipated: z.boolean().default(false).optional(),
    id: z.string(),
    orderNo: z.number(),
    notificationOption: z.nativeEnum(NotificationOptions).optional(),
    notifyToClient: z.boolean().default(false),
    isFixed: z.boolean().default(false),
  })

export type ParameterFormValues = z.infer<typeof parameterFormSchema>;

// Define the input data types
const inputDataTypes = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "Boolean", value: "boolean" },
  { label: "Select/Choice", value: "select" },
  { label: "Multi-Select", value: "multiselect" },
  { label: "Attachment", value: "attachment" },
];

// Define the props for the EditParameterDialog component
type EditParameterDialogProps = {
  open: boolean;
  onClose: () => void;
  parameter: ParameterFormValues | null;
};

const EditParameterDialog: React.FC<EditParameterDialogProps> = ({
  open,
  onClose,
  parameter,
}) => {
  // Initialize the form with React Hook Form and Zod
  const form = useForm<ParameterFormValues>({
    resolver: zodResolver(parameterFormSchema),
    defaultValues: parameter
      ? parameter
      : {
          parameterName: "",
          parameterType: "text",
          isRequired: false,
          isMultiple: false,
          paramaterValues: [{ value: "" }],
          workFlowId: "",
          isMultipleUserParticipated: false,
          isActive: true,
          id: "",
          orderNo: 0,
          notificationOption: NotificationOptions.None,
          notifyToClient: false,
          isFixed: false,
        },
  });

  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "paramaterValues",
  });
  const [updateParameter, { isSuccess, isLoading }] =
    useUpdateSalesWorkflowParameterMutation();
  const dataTypeWatch = watch("parameterType");

  // Reset form when parameter changes
  useEffect(() => {
    if (parameter) {
      reset({
        ...parameter,
        paramaterValues: parameter.paramaterValues?.length
          ? parameter.paramaterValues
          : [{ value: "" }, { value: "" }],
      });
    }
  }, [parameter, reset]);

  const onSubmit = async (data: ParameterFormValues) => {
      const parameterValues = form.getValues("paramaterValues") ?? [];
      const values = parameterValues?.length > 0 ? parameterValues?.map((item) => item.value) : [];
      let parameterType = form.getValues("parameterType");

      if (parameterType === "select" && form.getValues("isMultiple") === true) {
        parameterType = "multiselect";
      }

      await updateParameter({
        data: {
          ...data,
          parameterType: parameterType,
          paramaterValues: values.length > 0 ? JSON.stringify(values) : "",
          id: data.id,
          isActive: data.isActive ?? false,
          isMultipleUserParticipated: data.isMultipleUserParticipated ?? false,
          orderNo: data.orderNo,
          parameterName: data.parameterName,
          workFlowId: data.workFlowId,
          isRequired: data.isRequired ?? false,
          notificationOption: !data.notifyToClient
            ? NotificationOptions.None
            : data.notificationOption ?? NotificationOptions.None,
          notifyToClient: data.notifyToClient ?? false,
          isFixed: data.isFixed ?? false,
        },
      });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      onClose();
      Notify('success', 'Parameter updated successfully')
    }
  }, [isSuccess]);


  if (!parameter) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Parameter</DialogTitle>
      {/* <FormProvider {...form}> */}
      <Form {...form}>
        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-4">
          <DialogContent dividers>
            {/* Parameter Name */}
            <FormField
              control={control}
              name="parameterName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Label</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Parameter Label"
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormDescription>
                    Form label for the parameter to be displayed in the form
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Parameter Type */}
            <FormField
              control={control}
              name="parameterType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select data type" />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {inputDataTypes.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Parameter Values for Select/Multiselect */}
            {(dataTypeWatch === "select" ||
              dataTypeWatch === "multiselect") && (
              <div>
                {fields.map((fieldItem, index) => (
                  <FormField
                    control={control}
                    key={fieldItem.id}
                    name={`paramaterValues.${index}.value`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={cn(index !== 0 && "sr-only")}>
                          Option
                        </FormLabel>
                        <div className="flex gap-2">
                          <div className="w-full">
                            <FormControl>
                              <Input
                                {...field}
                                placeholder={`Option ${index + 1}`}
                              />
                            </FormControl>
                            <FormMessage />
                          </div>
                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            variant="outline"
                            size="icon"
                            className="rounded-full text-red-500 hover:border-1 border-0 hover:ring-red-500 hover:text-red-600"
                          >
                            <X size={14} />
                          </Button>
                        </div>
                      </FormItem>
                    )}
                  />
                ))}

                <div className="px-3">
                  {errors.paramaterValues?.root && (
                    <span className="text-red-500">
                      {errors.paramaterValues.root.message}
                    </span>
                  )}
                </div>

                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  className="mt-2"
                  onClick={() => append({ value: "" })}
                >
                  Add Option
                </Button>
              </div>
            )}
            {/* Is Required */}
            <FormField
              control={control}
              name="isRequired"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mt-3">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Required</FormLabel>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="isActive"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 my-3 mb-2">
                  <div className="space-y-1">
                    <FormLabel className="text-base">Is Active</FormLabel>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {
              <FormField
                control={form.control}
                name="notifyToClient"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 my-2">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">Notify User</FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            }
            {form.getValues("notifyToClient") && (
              <FormField
                control={form.control}
                name="notificationOption"
                render={({ field }) => (
                  <>
                    <Select
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      required
                    >
                      Selection Type
                      <SelectTrigger>
                        <SelectValue
                          placeholder={"Select an option"}
                          className="my-4"
                        />
                      </SelectTrigger>
                      <SelectContent className="z-9999">
                        {Object.values(NotificationOptions)
                          .filter(
                            (option) => option !== NotificationOptions.None
                          )
                          .map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </>
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <CustomButton isLoading={isLoading} type="submit">
              Save
            </CustomButton>
          </DialogActions>
        </form>
      </Form>
    </Dialog>
  );
};

export default EditParameterDialog;