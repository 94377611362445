import React, { SetStateAction, useEffect } from 'react';
import { DefaultDialog } from "@/components/shared/DefaultDailog/DefaultDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import CustomButton from '@/components/shared/Button/CustomButton';
import { Notify } from '@/components/shared/Notification/notify';
import { ISource } from '../model/source';
import { useEditSourceMutation } from '../api/api';

const sourceFormSchema = z.object({
  sourceName: z.string().nonempty("Source Name is required"),
  description: z.string().nonempty("Remark is required"),
});

type SourceFormValues = {
  sourceName: string;
  description: string;
};

type EditSourceProps = {
  openForm: boolean;
  setOpenForm: React.Dispatch<SetStateAction<boolean>>;
  source: ISource
};

const EditSource = ({ openForm, setOpenForm, source }: EditSourceProps) => {
  const [editSource, { isLoading, isSuccess }] = useEditSourceMutation();

  const defaultValues: SourceFormValues = {
    sourceName: source.sourceName,
    description: source.description,
  };
  
  const form = useForm<SourceFormValues>({
    resolver: zodResolver(sourceFormSchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: SourceFormValues) => {
    await editSource({
      data: {
        id: source.id,
        sourceName: data.sourceName,
        description: data.description,
        }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      form.reset()
      setOpenForm(false);
      Notify("success", "Source Edited Successfully!");
    }
  }, [isSuccess, setOpenForm]);

  return (
    <DefaultDialog open={openForm} title="Edit Alert Type" onClose={() => setOpenForm(false)}>
      <Form {...form}>
        <form className="p-2 flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="sourceName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Source Name</FormLabel>
                <FormControl>
                  <Input placeholder="Source Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input placeholder="Rescription" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <CustomButton disabled={!form.formState.isValid} isLoading={isLoading} type="submit">
            SUBMIT
          </CustomButton>
        </form>
      </Form>
    </DefaultDialog>
  );
};

export default EditSource;