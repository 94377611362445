import { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DefaultPage } from "@/components/shared/DefaultPage";
import { IPermission, IRole } from "../models/Role";
import { Button } from "@/components/ui/button";
import Chip from "@/components/shared/customShadCN/Chip";
import Caption from "@/components/shared/customShadCN/Caption";
import { useDeleteRoleMutation, useGetRolesQuery } from "../api/user-api";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import AddRole from "../componets/AddRole";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { DeleteIcon, Edit2 } from "lucide-react";
import EditRole from "../componets/EditRole";
import { Notify } from "@/components/shared/Notification/notify";




export function Role() {
  const [openAddRole, setOpenAddRole] = useState<boolean>(false)
  const [openEditRole, setOpenEditRole] = useState<boolean>(false)
  const [openDeleteRole, setOpenDeleteRole] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null)
  const [DeleteRow, {isLoading: isDeleteLoading, isSuccess: isDeleteSuccess}] = useDeleteRoleMutation()
  const { data: rolesList } = useGetRolesQuery({
    url: "/roles/GetAllRolePermissions",
    method: "GET",
  });


  const deleteRole = async() => {
    await DeleteRow({
      url: `/roles/${selectedRole?.id}`,
      method: "DELETE"
    })
  }

  const onCancel = () => {
    setSelectedRole(null)
  }

  const offOpendelete = (value: boolean) => {
    setOpenDeleteRole(value)
  }

  useEffect(() => {
    if(isDeleteSuccess) {
      setSelectedRole(null)
      Notify("success", "Role deleted successfully!")
    }
  }, [isDeleteSuccess])

  const roleListColumns: MRT_ColumnDef<IRole>[] = [
    {
      accessorKey: "roleName",
      header: "Role Name",
    },
    {
      accessorKey: "permissions",
      header: "Permissions",
      Cell({
        row: {
          original: { permissions },
        },
      }) {
        if (permissions && permissions?.length > 1) {
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex flex-row gap-1 items-center flex-nowrap">
                    <Chip label={permissions?.[0]?.permissionName ?? ""} />
                    <Caption className="w-8" text={`+ ${permissions?.length - 1}`} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {permissions?.map((permission: IPermission) => (
                    <div key={permission.id}>
                      {permission.permissionName}
                    </div>
                  ))}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        } else if (permissions && permissions?.length !== 0) {
          return (
            <div className="flex gap-1 items-center">
              {permissions?.map((permission: IPermission) => (
                <Chip
                  key={permission?.id}
                  label={permissions[0].permissionName}
                />
              ))}
            </div>
          );
        }
      },
    },
    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      Cell: ({row}) => {
        return (
          <div className="flex gap-4">
            <Button variant={"outline"} className="text-primary" onClick={() => {
              setSelectedRole(row.original)
              setOpenEditRole(true)
            }}><Edit2 className="w-4 mr-2"/>Edit</Button>
            <Button onClick={() => {
              setSelectedRole(row.original)
              setOpenDeleteRole(true)
            }} variant={"outline"} className="text-[#B00020]"><DeleteIcon className="w-8 mr-2"/> Delete</Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DefaultPage
        headerTitle="List of Roles"
        headerActionButton={<Button onClick={() => setOpenAddRole(true)}>Add Role</Button>}
      >
        <MaterialReactTable
            columns={roleListColumns}
            data={rolesList ?? []}
            enablePagination
            // initialState={{ pagination: { pageSize: 10 } }}
        />
      </DefaultPage>
      {
        openAddRole && (
          <AddRole openForm={openAddRole} setOpenForm={setOpenAddRole}/>
        )
      }
       {
        openEditRole && selectedRole && (
          <EditRole openForm={openEditRole} setOpenForm={setOpenEditRole} role={selectedRole}/>
        )
      }
       <ConfirmModal
          modalOpen={openDeleteRole}
          setModalOpen={offOpendelete}
          title="Delete Role"
          message="Are you sure want to delete this role?"
          type="error"
          loading={isDeleteLoading}
          onOk={() => {
            deleteRole()
          }}
          onCancel={onCancel}
          />
      {/* {
        openDeleteRole && selectedRole && (
         
        )
      } */}

    </>
  );
}
