import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { PaymentCollection, PaymentFrom } from "../model/PaymentCollection";
import CollectPayment from "../components/CollectPayment";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import dayjs from "dayjs";
import {
  useGetAllFinancesQuery,
  useGetCollectionPaymentTypeQuery,
  useVoidPayementMutation,
} from "../api/payment-collection-api";
import { ConfirmModal } from "@/components/shared/modals/ConfirmationDialog";
import { Notify } from "@/components/shared/Notification/notify";
import { useTheme } from "@mui/material/styles"; // Import useTheme from MUI
import PaymentDetail from "../components/PaymentDetail";
import { DefaultPageHeader } from "@/components/shared/DefaultPage/DefaultPageHeader";

const dateOptions = [
  { label: "This Week", value: "This Week" },
  { label: "Last Week", value: "Last Week" },
  { label: "Last 30 Days", value: "Last 30 Days" },
  { label: "Last 6 Months", value: "Last 6 Months" },
  { label: "All", value: "All" },
];

export const PayementCollection = () => {
  const theme = useTheme(); // Initialize MUI theme
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openVoidPayment, setOpenVoidPayment] = useState<boolean>(false);
  const [openPaymentDetail, setOpenPaymentDetail] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] =
    useState<PaymentCollection | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>("All");
  const [selectedPaymentFrom, setSelectPaymentFrom] = useState<string | null>(
    null
  );

  const [selectedPaymentType, setSelectPaymentType] = useState<string | null>(
    null
  );
  const [dateRange, setDateRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const {
    data: paymentList,
    isLoading: isPaymentsFetchLoading,
    isFetching: isPaymentFetching,
    refetch,
  } = useGetAllFinancesQuery({
    data: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      paymentFrom: selectedPaymentFrom,
      paymentType: selectedPaymentType,
    },
  });

  const [
    VoidPayment,
    { isLoading: isVoidPaymentLoading, isSuccess: isVoidPaymentSuccess },
  ] = useVoidPayementMutation();
  
  const paymentListColumns: MRT_ColumnDef<PaymentCollection>[] = [
    {
      accessorKey: "payerName",
      header: "Payer Name",
    },
    {
      accessorKey: "paymentType",
      header: "Payment Type",
    },
    {
      accessorKey: "paidAmount",
      header: "Amount",
      Cell: ({ row }) => {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "ETB",
        }).format(row.original.paidAmount);
      },
    },
    {
      accessorKey: "paymentMode",
      header: "Payment Mode",
    },
    {
      accessorKey: "paymentDeadline",
      header: "Payment Due Date",
      Cell: ({ row }) => {
        return new Date(row.original.paymentDeadline).toLocaleDateString();
      },
    },
    {
      accessorKey: "paymentDate",
      header: "Payment Date",
      Cell: ({ row }) => {
        return new Date(row.original.paymentDate).toLocaleDateString();
      },
    },
    {
      accessorKey: "taskReference",
      header: "Task Reference",
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      id: "actions",
      header: "Action",
      enableHiding: false,
      Cell: ({ row }) => {
        return (
          <Button
            variant={"outline"}
            disabled={row.original.status === "Void"}
            className="text-primary"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event
              setSelectedPayment(row.original);
              setOpenVoidPayment(true);
            }}
          >
            {/* <V className="w-4 mr-2" /> */}
            Void
          </Button>
        );
      },
    },
  ];

  const { data: paymentTypes } = useGetCollectionPaymentTypeQuery();

  const handleVoidPayment = async () => {
    await VoidPayment({
      data: {
        id: selectedPayment?.id,
      },
    });
  };

  useEffect(() => {
    let start = null;
    let end = null;

    const today = dayjs();

    switch (selectedDate) {
      case "This Week":
        start = today.startOf("week").add(1, "day").format("YYYY-MM-DD");
        end = today.endOf("week").add(1, "day").format("YYYY-MM-DD");
        break;
      case "Last Week":
        start = today.subtract(7, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 30 Days":
        start = today.subtract(30, "days").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "Last 6 Months":
        start = today.subtract(6, "months").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "All":
        start = null;
        end = null;
        break;
      default:
        break;
    }
    setDateRange({ startDate: start, endDate: end });
  }, [selectedDate]);

  useEffect(() => {
    refetch();
  }, [dateRange, refetch, selectedPaymentFrom]);

  useEffect(() => {
    if (isVoidPaymentSuccess) {
      Notify("success", "Payment voided successfully!");
    }
  }, [isVoidPaymentSuccess]);

  return (
    <>
      <DefaultPageHeader headerTitle="Payment Collection" />
      <div className="flex flex-col gap-2">
        <div className="flex items-center w-full justify-between">
          <div className="justify-self-end flex gap-4 w-[50%]">
            <Select onValueChange={setSelectedDate} value={selectedDate}>
              <SelectTrigger>
                <SelectValue placeholder="Select Date" />
              </SelectTrigger>
              <SelectContent className="z-9999">
                {dateOptions.map((type, index) => (
                  <SelectItem key={index} value={type.value}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              onValueChange={setSelectPaymentFrom}
              value={selectedPaymentFrom ?? ""}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select Payment From" />
              </SelectTrigger>
              <SelectContent className="z-9999 flex-1">
                {Object.values(PaymentFrom).map((mode) => (
                  <SelectItem key={mode} value={mode}>
                    {mode}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              onValueChange={setSelectPaymentType}
              value={selectedPaymentType ?? ""}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select Payment Type" />
              </SelectTrigger>
              <SelectContent className="z-9999 flex-1">
                {paymentTypes?.map((type) => (
                  <SelectItem key={type.id} value={type.id}>
                    {type.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button className="self-end" onClick={() => setOpenForm(true)}>
            Add Payment
          </Button>
        </div>
        <MaterialReactTable
          columns={paymentListColumns}
          data={paymentList ?? []}
          state={{ isLoading: isPaymentsFetchLoading || isPaymentFetching }}
          enablePagination
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setSelectedPayment(row.original);
              setOpenPaymentDetail(true);
            },
            sx: {
              backgroundColor:
                row.original.status === "Void"
                  ? theme.palette.error.light // Light red background
                  : "inherit", // Default background
              "&:hover": {
                backgroundColor:
                  row.original.status === "Void"
                    ? theme.palette.error.light
                    : theme.palette.action.hover,
              },
              cursor: "pointer",
            },
          })}
        />
      </div>
      {openForm && (
        <CollectPayment openForm={openForm} setOpenForm={setOpenForm} />
      )}
      {openPaymentDetail && selectedPayment && (
        <PaymentDetail
          openForm={openPaymentDetail}
          setOpenForm={setOpenPaymentDetail}
          payment={selectedPayment}
        />
      )}
      {openVoidPayment && selectedPayment && (
        <ConfirmModal
          title="Void Payment"
          message="Are sure you want void this payment?"
          modalOpen={openVoidPayment}
          setModalOpen={() => setOpenVoidPayment(false)}
          onCancel={() => setOpenVoidPayment(false)}
          onOk={async () => await handleVoidPayment()}
          loading={isVoidPaymentLoading}
          type="warning"
        />
      )}
    </>
  );
};
