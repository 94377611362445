import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Button } from '@/components/ui/button';
import { DefaultPage } from '@/components/shared/DefaultPage';
import { useEffect, useState } from 'react';
import NewLead from '../components/NewLead';
import { useGetLeadsQuery } from '../api/lead-api';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "@/components/ui/tooltip";
import Chip from '@/components/shared/customShadCN/Chip';
import Caption from '@/components/shared/customShadCN/Caption';
import { ILead } from '../model/lead';
import { IUser } from '@/features/users/models/User';
import { useNavigate } from 'react-router-dom';

const Lead = () => {
    const navigate = useNavigate();
    
    const [openCreateLead, setOpenCreatLead] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50, //customize the default page size
      });

    const leadListColumns: MRT_ColumnDef<ILead>[] = [
        {
            accessorKey: "taskReference",
            header: "Ref No.",
        },
        {
            accessorKey: "clientName",
            header: "Lead",
        },
        {
            accessorKey: "workFlowName",
            header: "Status",
        },
        {
            accessorKey: "email",
            header: "Email",
        },
        {
            accessorKey: "phoneNumbers",
            header: "Phone Number",
            Cell({
              row: {
                original: { phoneNumbers },
              },
            }) {
              if (phoneNumbers && phoneNumbers?.length > 1) {
                return (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex flex-row gap-1 items-center flex-nowrap">
                          <Chip label={phoneNumbers?.[0] ?? ""} />
                          <Caption className="w-8" text={`+ ${phoneNumbers?.length - 1}`} />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        {phoneNumbers?.map((phoneNumber: string, index: number) => (
                          <div key={index}>
                            {phoneNumber}
                          </div>
                        ))}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                );
              } else if (phoneNumbers && phoneNumbers?.length !== 0) {
                return (
                  <div className="flex gap-1 items-center">
                    {phoneNumbers?.map((_, index: number) => (
                      <Chip
                        key={index}
                        label={phoneNumbers[0]}
                      />
                    ))}
                  </div>
                );
              }
            },
          },
          {
            accessorKey: "assignedUsers",
            header: "Assigned users",
            Cell({
              row: {
                original: { assignedUsers },
              },
            }) {
              if (assignedUsers && assignedUsers?.length > 1) {
                return (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex flex-row gap-1 items-center flex-nowrap">
                          <Chip label={assignedUsers?.[0]?.fullName ?? ""} />
                          <Caption className="w-8" text={`+ ${assignedUsers?.length - 1}`} />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        {assignedUsers?.map((user: IUser) => (
                          <div key={user.id}>
                            {user.fullName}
                          </div>
                        ))}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                );
              } else if (assignedUsers && assignedUsers?.length !== 0) {
                return (
                  <div className="flex gap-1 items-center">
                    {assignedUsers?.map((user: IUser) => (
                      <Chip
                        key={user?.id}
                        label={assignedUsers[0].fullName}
                      />
                    ))}
                  </div>
                );
              }
            },
          },
    ];
    
    const { data: leadList, isLoading, refetch } = useGetLeadsQuery({
        data: {
            pageNumber: pagination.pageIndex + 1,
            pageSize: pagination.pageSize
        }
    });

    useEffect(() => {
        refetch(); // Refetch data whenever pageIndex or pageSize changes
    }, [pagination.pageIndex, pagination.pageSize, refetch]);

    return (
        <>
            <DefaultPage 
                headerTitle="Leads"
                headerActionButton={<Button onClick={() => {setOpenCreatLead(true)}}>New Lead</Button>}
                >
                    <MaterialReactTable
                    columns={leadListColumns}
                    data={leadList?.list ?? []}
                    enablePagination
                    manualPagination
                    rowCount={leadList?.count ?? 0} // Total number of records (if server returns it)
                    state={{ pagination,density: "compact", isLoading }}
                    onPaginationChange={setPagination}
                    initialState={{ pagination: { pageIndex: 1, pageSize: 50 } }} // Initial pagination state
                    muiTableBodyRowProps={({ row }) => {
                      return {
                        onClick: () => {
                          navigate(`/sales/pipeline-workflow/${row.original.salesTaskId}`);
                        },
                        sx: {
                          cursor: "pointer",
                        },
                      };
                    }}
                    />
            </DefaultPage>
             {
                openCreateLead && <NewLead openForm={openCreateLead} setOpenForm={setOpenCreatLead}/>
             }
        </>
    )
}

export default Lead