import { CalendarDays, CalendarIcon, CheckCheck, CircleDotDashed, StopCircle } from "lucide-react"
// import { TaskStatusCountPage } from "../components/TaskStatusCount";
// import { ClientPerTypePage } from "../components/ClientPerTypePage";
// import { SectorPerfomancePage } from "../components/SectorPerfomancePage";
// import { ClientSummaryPage } from "../components/ClientsSummaryPage";
import { useGetTaskCountQuery } from "../../client/api/dashboard-api";
import DashboardCard from "../../client/components/DashboarCard";
import { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { useGetClientsPerTypeQuery, useGetClientSummaryQuery, useGetSectorPerfomanceQuery, useGetTaskStatusCountQuery } from "../api/organizationDashboardApi";
import GenericChart from "../components/GenericChart";

const OrganizationDashboard = () => {
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const { data: taskCount, refetch } = useGetTaskCountQuery({
    data: {
      startDate: fromDate,
      endDate: toDate
    }
  });

  const { data: clientSummary, isLoading: isClientSummaryLoading, isError: isClientSummaryError, refetch: refetchSummary } = useGetClientSummaryQuery({
    data: {
      startDate: fromDate,
      endDate: toDate
    }
  });

  const { data: sectorPerformanceList, isLoading: isSectorPerfomanceLoading, isError: isSectorPerfomanceError, refetch:refetchSectorPerfomance } = useGetSectorPerfomanceQuery({
    data: {
      startDate: fromDate,
      endDate: toDate
    }
  });

  const { data: clientPerTypeList, isLoading: isclientPerTypeLoading, isError: isclientPerTypeError, refetch:refetchClientPerType } = useGetClientsPerTypeQuery({
    data: {
      startDate: fromDate,
      endDate: toDate
    }
  });

  const { data: taskCountList, isLoading: istaskCounLoading, isError: istaskCounError, refetch:refetchTaskCount } = useGetTaskStatusCountQuery({
    data: {
      startDate: fromDate,
      endDate: toDate
    }
  });

  useEffect(() => {
    if(toDate || fromDate) refetch()
  }, [fromDate, toDate])

  useEffect(() => {
    if(toDate || fromDate) refetchTaskCount()
  }, [fromDate, toDate])

  useEffect(() => {
    if(toDate || fromDate) refetchSummary()
  }, [fromDate, toDate])

  useEffect(() => {
    if(toDate || fromDate) refetchSectorPerfomance()
  }, [fromDate, toDate])

  useEffect(() => {
    if(toDate || fromDate) refetchClientPerType()
  }, [fromDate, toDate])


  return (
    <div className="py-4 px-2 md:px-6 2xl:px-11 flex flex-col gap-4">
       <div className="flex justify-end gap-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !fromDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {fromDate ? format(fromDate, "PPP") : <span>From date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={fromDate}
                onSelect={setFromDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !toDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {toDate ? format(toDate, "PPP") : <span>To date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={toDate}
                onSelect={setToDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
      {/* Responsive grid for dashboard cards */}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <DashboardCard
          title="Today New Task"
          count={taskCount?.newTasks ?? 0}
          icon={<CalendarDays />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/task-list"}
        />
        <DashboardCard
          title="Ongoing Task"
          count={taskCount?.ongoingTasks ?? 0}
          icon={<CircleDotDashed />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/task-list"}
        />
        <DashboardCard
          title="Completed Task"
          count={taskCount?.completedTasks ?? 0}
          icon={<CheckCheck />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/task-list"}
        />
        <DashboardCard
          title="Suspended Task"
          count={taskCount?.suspendedTasks ?? 0}
          icon={<StopCircle />}
          linkText="View Detail"
          bgColor="white"
          link={"/task/task-list"}
        />
      </div>

      <div className="bg-white grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
      {/* Task Status Count */}
      <div className="col-span-1">
        <GenericChart title="Task Count" data={taskCountList ?? []} isLoading={istaskCounLoading} isError={istaskCounError}/>
      </div>

      {/* Client Per Type */}
      <div className="col-span-1">
        <GenericChart title="Client Per Type" data={clientPerTypeList ?? []} isLoading={isclientPerTypeLoading} isError={isclientPerTypeError}/>
      </div>

      {/* Sector Performance */}
      <div className="col-span-1">
        <GenericChart title="Sector Performance" data={sectorPerformanceList ?? []} isLoading={isSectorPerfomanceLoading} isError={isSectorPerfomanceError}/>
      </div>

      {/* Client Summary */}
      <div className="col-span-1">
        <GenericChart title="Client Summary" data={clientSummary?.taskSummary ?? []} isLoading={isClientSummaryLoading} isError={isClientSummaryError}/>
      </div>
    </div>
    </div>
  )
}

export default OrganizationDashboard