import LabelValue from "@/components/shared/LabelValue";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ICustomer } from "@/features/customers/models/Customer";
import { ICase } from "@/features/workflows/Case/models/Case";
import { EyeIcon } from "lucide-react";
import { NavLink } from "react-router-dom";

interface Props {
  client: ICustomer;
  caseType: ICase;
  onEditClick?: () => void; // Optional prop for handling edit click
  showEditButton?: boolean; // New prop to toggle edit button visibility
}

export const TaskClientCard = ({ client, caseType }: Props) => {
  return (
    <div>
      <>
        <div className="bg-white my-2 py-4 px-2 flex md:px-4 flex-col gap-4 shadow-sm rounded-lg">
          <div className="flex justify-between items-center ">
            <div className="flex flex-col">
              <p className="text-xl font-semibold my-1">{client?.clientName}</p>
              <div className="flex gap-4">
                <LabelValue
                  labelClassName="font-bold"
                  label="Client Type"
                  value={client?.clientType}
                  orientation="vertical"
                />
                <LabelValue
                  labelClassName="font-bold"
                  label="Status"
                  value={client?.isActive ? "Active" : "InActive"}
                  orientation="vertical"
                />

                <LabelValue
                  label="Credit Limit"
                  labelClassName="font-bold"
                  value={client.creditLimit}
                  orientation="vertical"
                />

                <LabelValue
                  labelClassName="font-bold"
                  label="Credit Used"
                  value={client.creditUsedSoFar}
                  orientation="vertical"
                />
              </div>
            </div>
            <div className="flex gap-2">
              <NavLink to={`/customer/${client.id}`}>
                <Button variant={"outline"} className="border-2">
                  <EyeIcon /> View Detail
                </Button>
              </NavLink>
            </div>
          </div>
          {caseType && (
            <>
              <Separator />
              <LabelValue label="Case:" value={caseType?.caseDescription} />
            </>
          )}
        </div>
      </>
    </div>
  );
};
