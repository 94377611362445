import { Button } from "@/components/ui/button";
import { format, formatDistanceToNow, parseISO } from "date-fns";

import { useEffect, useRef, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/lib/auth";
import { useLazyGetGenericTaskNoteByTaskIdQuery } from "../api/generic-task-api";
import { useSaveGenericTaskNoteMutation } from "../api/generic-task-command";
import { IGenericTaskDetail } from "../model/GenericTask";

type Props = {
  taskId: string;
  taskDetail: IGenericTaskDetail;
};
// const formatTimestamp = (isoString: string): string => {
//   const date = parseISO(isoString);
//   return formatDistanceToNow(date, { addSuffix: true });
// };

const formatTimestamp = (isoString: string): string => {
  const date = parseISO(isoString);
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;
  const diffInDays = Math.floor(diffInSeconds / (60 * 60 * 24));

  // const locale = localeMap[localeStr] || enUS;

  if (diffInDays < 7) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else {
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
    const exactDate = format(date, "MMM dd, yyyy");
    return `${relativeTime} • ${exactDate}`;
  }
};

export const GenericTaskMessaging = ({ taskId, taskDetail }: Props) => {
  // const [messages, setMessages] = useState<Message[]>(initialMessages);
  const { session } = useAuth();
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [fetchNotes, { data: messages = [] }] =
    useLazyGetGenericTaskNoteByTaskIdQuery();
  const [saveNote, { data: note, isLoading, isSuccess }] =
    useSaveGenericTaskNoteMutation();

  useEffect(() => {
    // Function to fetch notes
    const getNotes = () => {
      fetchNotes(taskId);
    };
    // Fetch immediately when the component mounts
    getNotes();

    // Set up the interval to fetch every 5 seconds (5000 ms)
    const intervalId = setInterval(getNotes, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchNotes, taskId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    await saveNote({
      data: {
        content: newMessage,
        taskId: taskId,
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setNewMessage("");
    }
  }, [isSuccess]);
  console.log(note);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const wfClosedOrCanalled =
    taskDetail.taskStatus === "Closed" ||
    taskDetail.taskStatus === "Canceled" ||
    taskDetail.taskStatus == "Suspended";

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex flex-col px-10">
      <div className="h-[60vh]">
        <ScrollArea className="h-full flex-1 overflow-auto p-4">
          <div className="flex flex-col space-y-2">
            {messages?.length > 0 ? (
              messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex ${
                    message.userId === session?.userInfo.id
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  <div
                    className={`p-3 rounded-lg shadow-sm ${
                      message.userId === session?.userInfo.id
                        ? "bg-blue-700 text-white"
                        : "bg-gray-200 text-black"
                    } max-w-3xl`}
                  >
                    {message.userId !== session?.userInfo.id && (
                      <div className="flex items-center mb-1">
                        <p className="text-xs font-medium text-gray-700 mr-2">
                          {message.userName}
                        </p>
                        <p className="text-xs text-gray-500">
                          {formatTimestamp(message.timeStamp)}
                        </p>
                      </div>
                    )}
                    {message.userId === session?.userInfo.id && (
                      <div className="flex justify-end items-center mb-1">
                        <p className="text-xs text-gray-300">
                          {formatTimestamp(message.timeStamp)}
                        </p>
                      </div>
                    )}
                    <p className="text-sm">{message.content}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">
                No messages available
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
      </div>
      <div className="flex gap-2 p-4 border-t">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          className="flex-1"
          disabled={isLoading || wfClosedOrCanalled}
        />
        <Button
          onClick={handleSendMessage}
          type="button"
          disabled={isLoading || wfClosedOrCanalled}
        >
          Send
        </Button>
      </div>
    </div>
  );
};
