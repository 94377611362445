import { CancelTaskDialog } from "../components/CancelTaskDialog";
import { ITaskDetail } from "../models/Task";
import { IWorkFlow } from "@/features/workflows/Case/models/workflow";
import WorkflowCancelStepper from "./WorkflowCancelStepper";
import { SuspendTaskDialog } from "./SuspendTaskDialog";
import { BackTaskDialog } from "./BackTaskDialog";
import { ReactivateTaskDialog } from "./ReactivateTaskDialog";
import { useAuth } from "@/lib/auth";
import { UserType } from "@/model/SideBarMenu";
import { ReviseTaskDialog } from "./ReviseTaskDialog";
import { TaskClientCard } from "./TaskClientCard";
import WorkflowStepperAndBody from "./WorkflowStepperAndBody";

export type TaskDetailProps = {
  taskDetail: ITaskDetail;
  workflows: IWorkFlow[];
};
const TaskDetail = ({ taskDetail }: TaskDetailProps) => {
  const { session } = useAuth();

  return (
    <div>
      <div className="rounded-xl bg-red-400">
        {taskDetail?.client && (
          <TaskClientCard
            client={taskDetail?.client}
            caseType={taskDetail.caseType}
          />
        )}
      </div>

      <div className="p-5 bg-white dark:bg-primary shadow-xl">
        {taskDetail.task.taskStatus === "Canceled" ? (
          <WorkflowCancelStepper />
        ) : (
          <WorkflowStepperAndBody taskDetail={taskDetail} />
        )}
      </div>
      <>
        {taskDetail.task.taskStatus == "Suspended" && (
          <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
            <div className="flex flex-col p-8">
              <div className="text-2xl font-bold   text-[#374151] pb-6">
                Suspended
              </div>
              <div className=" text-lg   text-black">
                Current Workflow is suspended please reactivate or cancel to
                continue
              </div>
              <div className="flex justify-end pt-6">
                {/* <button class="bg-[#80840f] text-[#ffffff]  font-bold text-2xl  p-3 rounded-lg hover:bg-purple-800 active:scale-95 transition-transform transform">Reactivate</button> */}
              </div>
            </div>
          </div>
        )}
        {taskDetail.task.taskStatus == "BackToRevision" && (
          <div className="mt-3 flex flex-col rounded-2xl w-full bg-yellow-500 shadow-xl">
            <div className="flex flex-col p-8">
              <div className="text-2xl font-bold   text-[#374151] pb-6">
                Back To Revision
              </div>
              <div className=" text-lg   text-black">
                Current Workflow is Back To Revision please revise
              </div>
              <div className="flex justify-end pt-6">
                {/* <button class="bg-[#80840f] text-[#ffffff]  font-bold text-2xl  p-3 rounded-lg hover:bg-purple-800 active:scale-95 transition-transform transform">Reactivate</button> */}
              </div>
            </div>
          </div>
        )}
        {session?.userInfo.userType !== UserType.Client &&
          taskDetail.task.taskStatus !== "Finished" && (
            <div className="flex gap-3">
              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" &&
                taskDetail.task.taskStatus !== "BackToRevision" &&
                (taskDetail.task.taskStatus === "Suspended" ? (
                  <ReactivateTaskDialog taskId={taskDetail.task.id} />
                ) : (
                  <SuspendTaskDialog taskId={taskDetail.task.id} />
                ))}

              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" &&
                taskDetail.task.taskStatus !== "Suspended" &&
                (taskDetail.task.taskStatus === "BackToRevision" ? (
                  <ReviseTaskDialog taskId={taskDetail.task.id} />
                ) : (
                  <BackTaskDialog taskId={taskDetail.task.id} />
                ))}

              {taskDetail?.task.id &&
                taskDetail.task.taskStatus !== "Canceled" && (
                  <CancelTaskDialog taskId={taskDetail.task.id} />
                )}
            </div>
          )}
      </>
    </div>
  );
};

export default TaskDetail;
