import { IEmail } from '../models/sms';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useGetSentEmailQuery } from '../api/sms-email-api';

export const emailListColumns: MRT_ColumnDef<IEmail>[] = [
    {
      accessorKey: "phoneNumber",
      header: "Phone Number",
    },
    {
      accessorKey: "subject",
      header: "Subject",
    },
    {
      accessorKey: "message",
      header: "Message",
    },
    {
      accessorKey: "sentAt",
      header: "Sent At",
      enableGlobalFilter: true,
      // Format sentAt field to display readable date and time
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        return new Intl.DateTimeFormat('en-US', {
          dateStyle: 'medium', 
          timeStyle: 'short',
        }).format(date);
      },
    }
  ];

const EmailList = () => {
    const { data: sentSMS, isLoading} = useGetSentEmailQuery()
  return (
    <MaterialReactTable
        columns={emailListColumns}
        data={sentSMS ?? []}
        state={{isLoading}}
    />
  )
}

export default EmailList
