import AutoCompleteSelect from "@/components/shared/AutoCompleteSelect";
import { Input } from "@/components/ui/input";
import { useGetSectorsQuery } from "@/features/customers/api/customer-api";
import { CustomerStatus } from "@/features/customers/models/Customer";
import { COUNTRIES_LIST } from "@/lib/const/country-list";
import { titleCase } from "@/lib/utils";

export interface SMSFilterKeys {
  country: string;
  searchTerm: string;
  sector: string;
  customerStatus: CustomerStatus;
}

type Props = {
  filter: SMSFilterKeys;
  setFilter: (filter: SMSFilterKeys) => void;
};

const SendSmsFilters = ({ filter, setFilter }: Props) => {
  const { data: sectors = [] } = useGetSectorsQuery({
    url: "/sectors",
    method: "GET",
  });
  return (
    <div className="flex gap-2 items-center">
      <Input
        type="text"
        placeholder="Search by name, phone, or email..."
        value={filter.searchTerm}
        onChange={(e) => {
          setFilter({ ...filter, searchTerm: e.target.value });
        }}
        className="mb-2"
      />
      <AutoCompleteSelect
        onChange={(value) => {
          setFilter({ ...filter, customerStatus: value as CustomerStatus });
        }}
        options={Object.values(CustomerStatus).map((status) => ({
          label: titleCase(status),
          value: status,
        }))}
        value={filter.customerStatus}
        label="Customer Status"
      />
      <AutoCompleteSelect
        onChange={(value) => {
          setFilter({ ...filter, country: value });
        }}
        options={COUNTRIES_LIST}
        value={filter.country}
        label="Country"
      />

      <AutoCompleteSelect
        onChange={(value) => {
          console.log(value);
          setFilter({ ...filter, sector: value });
        }}
        options={sectors.map((sector) => ({
          label: sector.sectorName,
          value: sector.id,
        }))}
        value={filter.sector}
        label="Sector"
      />
    </div>
  );
};

export default SendSmsFilters;
